import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Icon, Message} from 'semantic-ui-react';
import {authentication} from "../../../../../authentication";
import {backend} from "../../../../../xconvert-backend";
import {ConfigContext} from "../../../context/ConfigContext";
import {AddDefaultsToConfigRequest} from "../../../../../generated";
import {IfBox} from "../../../../style/if";
import {ConverterSendButton} from "../ConverterSendButton";
import {v4 as uuid} from 'uuid';
import {
    FileProcessingState,
    FileUploadMultiple,
    FileWithState,
    uploadedFiles
} from "../../../../util/FileUploadMultiple";
import {importSimOutputs} from "./ImportSimTabMenu";
import {updateConfig, workingConfig} from "../../configChange/ConfigSignal";
import {DisplayFile} from "../../ftp-explorer/DisplayFile";


export interface ImportSimFileProps {
}

export function ImportSimFile(props: ImportSimFileProps) {
    const context = React.useContext(ConfigContext)


    const [isLoading, setIsLoading] = React.useState(false)
    const [isConverting, setIsConverting] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState(null)
    const [previewFile, setPreviewFile] = useState<FileWithState | null>(null);
    const [fileBuffer, setFileBuffer] = useState<ArrayBuffer | null>(null);

    useEffect(() => {
        context.setConfSimInputActive("FILE")
    }, []);

    useEffect(() => {
        setPreviewFile(uploadedFiles.value[0])
    }, [uploadedFiles.value]);

    useEffect(() => {
        if (previewFile) {
            fileToArrayBuffer(previewFile.file)
                .then((result) => {
                    if (result && result instanceof ArrayBuffer) {
                        setFileBuffer(result)
                    }
                })
                .catch(error => {
                    console.error("FAILED TO CONVERT FILE TO ARRAYBUFFER: ", error);
                    setFileBuffer(null)
                });
        } else {
            setFileBuffer(null)
        }
    }, [previewFile]);

    async function doConvert(file: any) {


        let fileBase64 = '';
        getBase64(file.file, (result) => {
            fileBase64 = result.split(";base64,")[1];
        })

        let auth = backend.withTokenAuthHeader(authentication.token)

        let request = {} as AddDefaultsToConfigRequest

        let configWithoutDefaults = workingConfig.value

        if (!configWithoutDefaults) {
            console.log("config in context is null. Loading config from backend")
            let currentCompanyConfig = (await backend.internalApi.fetchCompanyConfiguration(context.companyId, auth))
            configWithoutDefaults = JSON.stringify(currentCompanyConfig, null, 4)
            await updateConfig(configWithoutDefaults, false, false)
        }

        request.config = JSON.parse(configWithoutDefaults)

        let config = (await backend.internalApi.addDefaultsToConfig(request, auth)).config

        let url = '/converter/v3/convertExternalFormatToTransportOrder'
        if (context.customConverterBasePath) {
            url = context.customConverterBasePath + url
        }
        let xReqId = uuid()


        let response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                'x-request-id': xReqId
            },
            body: JSON.stringify({
                base64Data: fileBase64,
                config: config,
                companyId: context.companyId,
                inputFileName: file.fileName
            })
        })

        if (response.status == 200) {
            context.setConfSimToSaveMode('REPLACE')
        } else if (response.status == 206) {
            context.setConfSimToSaveMode('PATCH')
        } else {
            context.setConfSimToSaveMode(null)
        }

        if (!response.ok) {
            setErrorMessage(response.statusText)
        } else {
            setErrorMessage(null)
        }

        let responseText = await response.text()

        context.setConfSimInputLastType("FILE")
        let result = {
            xRequestId: xReqId,
            fileName: file.fileName,
            TOs: responseText
        }

        console.log("result: ", result)
        return result
    }

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    function updateFileState(file, newState) {
        console.log("updateFileState: ", file, newState)
        let newFiles = uploadedFiles.value.filter((f) => f.fileName != file.fileName)
        let newFile = {
            file: file.file,
            fileName: file.fileName,
            state: newState
        }
        newFiles.push(newFile)
        console.log("newFiles: ", newFiles)
        uploadedFiles.value = newFiles
    }

    async function convertAllFiles() {
        setIsConverting(true)
        let results = []

        uploadedFiles.value.forEach((file) => {
            console.log("converting file: ", file)
            updateFileState(file, "PROCESSING")
            doConvert(file).then((result) => {
                results.push(result)
                updateFileState(file, "PROCESSED")
            })
        })
        while (results.length < uploadedFiles.value.length) {
            await sleep(100)
        }
        console.log("[importSimOutputs:selection] results: ", results)
        let first = true
        importSimOutputs.value = results.map((result) => {

                let withSelected = {
                    result: result,
                    selected: first
                }
                first = false
                return withSelected
            }
        )
        setIsConverting(false)
    }

    function sleep(time: number) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    function getReadableFileSizeString(file: File) {

        if (file == null) {
            return 0
        }
        let fileSizeInBytes = file.size
        let i = -1;
        const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
            fileSizeInBytes = fileSizeInBytes / 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    }

    function drawState(state: FileProcessingState) {
        console.log("drawState: ", state)
        switch (state) {
            case "NOT_PROCESSED":
                return <Icon name={"question"} color={"red"}/>
            case "PROCESSING":
                return <Icon name={"spinner"} color={"yellow"}/>
            case "PROCESSED":
                return <Icon name={"check"} color={"green"}/>
        }
    }

    function drawFileList() {
        return <ul>
            {uploadedFiles.value.map((file, i) => (
                <li key={i}>
                    {file.fileName} - {getReadableFileSizeString(file.file)} {drawState(file.state)}
                    <Button
                        icon={"eye"}
                        color={viewFileButtonColor(file)}
                        onClick={() => {
                            setPreviewFile(file)
                            if (importSimOutputs.value.length > 0) {
                                let newOutputs = []
                                importSimOutputs.value.forEach((output: any) => {
                                    output.selected = output.result.fileName == file.fileName
                                    newOutputs.push(output)
                                })
                                importSimOutputs.value = newOutputs
                            }
                        }
                        }
                    />
                </li>
            ))}
        </ul>
    }

    function viewFileButtonColor(file: FileWithState) {
        if (previewFile == file) {
            return "blue"
        } else {
            return "grey"
        }
    }

    function fileToArrayBuffer(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = event => resolve(event.target.result);
            reader.onerror = error => reject(error);
            return reader.readAsArrayBuffer(file);
        });
    }

    function drawFilePreview() {
        if (previewFile == null) {
            return null
        }
        return <DisplayFile
            fileBuffer={fileBuffer}
            fileName={previewFile.fileName}
            displayName={true}
        />

        /*if (previewFile == null) {
            return null
        }
        console.log("previewFile: ", previewFile)
        if (previewFile.fileName?.toLowerCase().endsWith(".pdf") != false) {
            console.log("previewFile IS a pdf: ", URL.createObjectURL(previewFile.file))

            return <embed src={URL.createObjectURL(previewFile.file)} width="100%" height="100%"/>
        }
        console.log("previewFile NOT a pdf: ", previewFile)

        previewFile.file.stream().getReader().read().then((result) => {
            setFileContent(arrayBufferToBase64(result.value))
        })

        return <AceEditor
            theme="monokai"
            style={{flex: 1}}
            mode="json"
            value={fileContent}
            placeholder={'Nothing found'}
            width="100%"
            readOnly={true}
        />*/
    }

    function arrayBufferToBase64(buffer: any) {
        let result = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            result += String.fromCharCode(bytes[i]);
        }
        return result;
    }

    return <>
        <div>
            <ConverterSendButton
                onClick={(evt) => convertAllFiles()}
                loading={isConverting}
                disabled={isLoading}
            />

            <IfBox shouldShow={errorMessage != null}>
                <Message negative={true} compact={true}>
                    <Message.Header>
                        ERROR:
                    </Message.Header>
                    <Message.Content>
                        {errorMessage}
                    </Message.Content>
                </Message>
            </IfBox>

            <FileUploadMultiple/>
        </div>
        {drawFileList()}
        {drawFilePreview()}
    </>

}