import * as React from "react";
import {Button, Header, Icon, Label, List, Modal} from "semantic-ui-react";
import {ConfigContext} from "../../../context/ConfigContext";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {ftp} from "../../../../../xconvert-ftp";
import {IfBox} from "../../../../style/if";
import {v4 as uuid} from 'uuid';

export interface FtpExplorerMultiFileDeleteModalProps {
    isOpen: boolean
    onClose: (success: Boolean) => void
    files: string[]
    path: string
}

export class FtpExplorerMultiFileDeleteModal extends React.Component<FtpExplorerMultiFileDeleteModalProps, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            response: null
        }
    }

    handleClose = () => {
        this.props.onClose(false)
    }

    async handleDelete() {
        let allSuccessful = true
        this.props.files.map(async (file) => {
            let path = this.props.path + "/" + file

            let auth = (await backend.withTokenAuthHeader(authentication.token))

            let response
            try {
                response = (await ftp.ftpApi.deleteFile(path, this.context.companyId, auth))
            } catch (ex) {
                response = {}
                response.success = false
                response.errorMessage = "Exception occured while call: " + ex.statusText

            }

            if (response.success == true) {
                //this.props.onClose(true)

            } else {
                allSuccessful = false
                this.setState({response: response.errorMessage})
            }
        })

        if (allSuccessful) {
            this.props.onClose(true)
        }
    }

    renderListItems() {
        if (this.props.files == null || this.props.files.length == 0) {
            return <List.Item key={0}>
                <List.Content>
                    No Files selected
                </List.Content>
            </List.Item>
        }

        return this.props.files.map((file: string) => {

            return <List.Item key={uuid()}>
                {file}

            </List.Item>

        })

    }


    render() {

        const listItems = this.renderListItems()

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='small'>
            <Header icon='browser' content='Delete Files'/>
            <Modal.Content>
                <p>Are you sure you want to delete the following files?</p>
                <p>This action can't be reversed!</p>
                <List divided verticalAlign='middle' bulleted>
                    {listItems}
                </List>
                <Button onClick={() => this.handleDelete()}><Icon name='trash' color='red'/> delete</Button>
                <Button onClick={() => this.handleClose()}>cancel</Button>
                <IfBox shouldShow={this.state.response != null}>
                    <Label color='red'>{this.state.response}</Label>
                </IfBox>
            </Modal.Content>
        </Modal>
    }

}