import * as React from "react";
import {Tab} from "semantic-ui-react";
import {SingleScriptExecution} from "./singleScriptExecution/SingleScriptExecution";
import {MultiScriptExecution} from "./multiScriptExecution/MultiScriptExecution";
import {ScriptRunnerExecutionTab} from "./scriptRunner/ScriptRunnerExecutionTab";
import {TelematicMapping} from "./telematicMapping/TelematicMapping";
import {authentication} from "../../../../authentication";
import {MultiOrderScriptExecution} from "./multiOrderScriptExecution/MultiOrderScriptExecution";
import {ReportScriptPage} from "./reportScript/ReportScriptPage";

let panes = [
    {menuItem: 'SingleScript',
        render: props => <Tab.Pane><SingleScriptExecution dispatch={props.dispatch}
                                                          currentCompanyConfig={props.currentcompanyconfig}
                                                          auth={props.auth}/></Tab.Pane>
    },
    {menuItem: 'MultiOrder Script',
        render: props => <Tab.Pane><MultiOrderScriptExecution history={props.history}/></Tab.Pane>
    },
    {menuItem: 'onViewXxxScript',
        render: props => <Tab.Pane><MultiScriptExecution dispatch={props.dispatch}
                                                         currentCompanyConfig={props.currentcompanyconfig}
                                                         auth={props.auth}/></Tab.Pane>
    },
    {menuItem: 'ScriptRunner',
        render: props => <Tab.Pane><ScriptRunnerExecutionTab dispatch={props.dispatch}
                                                             currentCompanyConfig={props.currentcompanyconfig}
                                                             auth={props.auth}/></Tab.Pane>
    },
    {menuItem: 'ReportScript',
        render: props => <Tab.Pane><ReportScriptPage/></Tab.Pane>
    },
]

function getMenu() {
    if (authentication.isSuperUser && panes.filter(p => p.menuItem == 'TelematicMapping').length == 0) {
        panes.push({menuItem: 'TelematicMapping', render: props => <Tab.Pane><TelematicMapping/></Tab.Pane>})
    }

    return panes
}

const TabMenuScriptExecution = props => <Tab
    id={"TabMenuScriptExecution"}
    className={"flexTab"}
    {...props = props}
    panes={getMenu()}
    defaultActiveIndex={props.context.tabSession.scriptExecutionTab}
    onTabChange={(_, data) => {
        props.context.setSingleTabSession("scriptExecutionTab", data.activeIndex)
    }}
/>

export default TabMenuScriptExecution