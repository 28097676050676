import * as React from "react";
import {Tab} from 'semantic-ui-react'
import {GlobalGeofenceList} from "./globalGeofences/GlobalGeofenceList";
import {GeostatusWorkbench} from "./geostatusWorkbench/GeostatusWorkbench";


const panes = [
    {menuItem: 'GlobalGeofence', render: props => <Tab.Pane id='GlobalGeofenceList'><GlobalGeofenceList/></Tab.Pane>},
    {menuItem: 'Geostatus', render: props => <Tab.Pane id='Geostatus'><GeostatusWorkbench/></Tab.Pane>},
]

const GeostatusTabMenu = props => <Tab
    {...props = props}
    panes={panes}
    defaultActiveIndex={props.context.tabSession.geostatus}
    onTabChange={(_, data) => {
        props.context.setSingleTabSession("GlobalGeofences", data.activeIndex)
    }}
/>

export default GeostatusTabMenu