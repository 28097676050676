import * as React from "react";
import {DiscoveryServiceRequest, TransportOrder} from "../../../../../generated";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {Button, Checkbox, Dropdown, DropdownItemProps, Label} from "semantic-ui-react";
import ReactTable from "react-table";
import Notifications, {notify} from 'react-notify-toast';
import {useEffect} from "react";
import {ConfigContext} from "../../../context/ConfigContext";
import {signal} from "@preact/signals-react";
import {
    ImportSimTransportOrderOutputTableColumnSelectorModal
} from "./ImportSimTransportOrderOutputTableColumnSelectorModal";
import {FaCodeCompare} from "react-icons/fa6";
import {ImportSimCompareOrderChangeModal} from "./ImportSimCompareOrderChangeModal";

let jp = require('jsonpath');

export const importSimOutputTableSelectedColumns = signal([])
export const importSimOutputTableAvailableColumns = signal([])

export interface ImportSimTransportOrderOutputTableProps {
    outputTO: string
}

export function ImportSimTransportOrderOutputTable(props: React.PropsWithChildren<ImportSimTransportOrderOutputTableProps>) {

    const context = React.useContext(ConfigContext)

    const [auth, setAuth] = React.useState<any>(backend.withTokenAuthHeader(authentication.token))
    const [availableActiveConverterOptions, setAvailableActiveConverterOptions] = React.useState<string[]>(null)
    const [selectedActiveConverterIndex, setSelectedActiveConverterIndex] = React.useState<number>(0)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [selectedTOs, setSelectedTOs] = React.useState<TransportOrder[]>([])
    const [pageSize, setPageSize] = React.useState<number>(20)
    const [outputTOs, setOutputTOs] = React.useState<TransportOrder[]>([])
    const [columnSelectorModalOpen, setColumnSelectorModalOpen] = React.useState<boolean>(false)

    const [compareOrder, setCompareOrder] = React.useState<TransportOrder>(null)
    const [comparing, setComparing] = React.useState<boolean>(false)

    const defaultColumns = [
        {
            id: 'select',
            Header: 'Select',
            width: 70,
            accessor: (d: any) => <Checkbox
                    checked={selectedTOs.some(to => getIdentifier(to) === getIdentifier(d))}
                    onChange={() => switchSelectionStateOfItem(d)}
                />
        },
        {
            id: 'compare',
            Header: 'Compare',
            width: 80,
            accessor: (d: any) => <Button
                onClick={() => {
                    console.log("compare: ", d)

                    setComparing(true)
                    setCompareOrder(d)
                }}
                loading={comparing}
            ><FaCodeCompare /></Button>
        },

    ]

    useEffect(() => {
        if(props.outputTO) {
            let data: TransportOrder[] = JSON.parse(props.outputTO)[0].transportOrders ?? []

            setOutputTOs(data)

            console.log("after useEffect ImportSimulator: ", data)
        } else {
            console.log("no outputTO in props")
            setOutputTOs([])
        }
    }, [props.outputTO]);

    useEffect(() => {
        console.log("loading ImportSimulator: ", outputTOs)

        dicoveryServiceExecuteCall()

        let localStorageColumns = JSON.parse(localStorage.getItem("importSimOutputTableSelectedColumns"))
        if (localStorageColumns) {
            importSimOutputTableSelectedColumns.value = localStorageColumns
        }
    }, []);

    function buildColumns() {

        importSimOutputTableSelectedColumns.value.forEach((column) => {
            defaultColumns.push({
                id: column.name,
                Header: column.name,
                width: 200,
                accessor: (d: any) => {
                    let path = column.name.replaceAll("*", "0")
                    return jp.value(d, path)?.toString()
                }
            })
        })
        return defaultColumns
    }

    function getIdentifier(to: TransportOrder) {
        return to._id + "_" + to.externalTransportOrderId
    }

    function switchSelectionStateOfItem(to: TransportOrder) {
        setIsLoading(true)

        const array = [...selectedTOs]; // make a separate copy of the array
        const index = array.findIndex(item => getIdentifier(item) === getIdentifier(to));
        if (index == -1) {
            // TO not found, adding id

            let newArray = array.concat(to)
            setSelectedTOs(newArray)
            console.log("TO added")


        } else {
            // TO found, removing it

            array.splice(index, 1);
            setSelectedTOs(array)
            console.log("TO removed")

        }
        setIsLoading(false)
    }

    async function dicoveryServiceExecuteCall() {
        let request = {collection: "TransportOrders"} as DiscoveryServiceRequest

        let response = await backend.queryApi.runDiscoveryService(request, auth)

        importSimOutputTableAvailableColumns.value = Object.values(response.filtrableProperties)
    }

    function dropDownPropertyNameOptions() {
        let opt: Array<DropdownItemProps> = [];
        if (availableActiveConverterOptions != null) {
            let i = 0
            availableActiveConverterOptions.forEach(value => {
                opt.push({key: value, value: i, text: value});
                i++
            })
        }
        return opt
    }

    async function onSave() {
        setIsLoading(true)

        let allSuccessful = true

        selectedTOs.map(async to => {
            try {
                await backend.transportOrderApi.createOrModifyTransportOrder(
                    to,
                    undefined,
                    undefined,
                    context.confSimToSaveMode,
                    undefined,
                    auth
                )
            } catch (e) {
                console.log("ERROR: ", e)
                allSuccessful = false
            }
        })
        setIsLoading(false)
        if (allSuccessful) {
            notify.show('all selected TransportOrders have been saved', 'success', 3000, '#28f751');
        } else {
            notify.show("ERROR: not all selected TransportOrders could be saved!", 'error', 5000, '#fc0303')
        }
    }

    function allSelected() {
        if (outputTOs) {
            return selectedTOs.length == outputTOs.length
        } else {
            return false
        }

    }

    function drawTable() {

        console.log("drawTable: ", outputTOs)

        return <ReactTable
            data={outputTOs}
            pages={Math.ceil(outputTOs.length / pageSize)}
            columns={buildColumns()}
            defaultPageSize={pageSize}
            className="-striped -highlight"
            loading={isLoading}
            style={{cursor: "pointer"}}
            onPageSizeChange={(ps, pageIndex) => setPageSize(ps)}
        />
    }


    return <div
        style={{
            display: "flex",
            flexDirection: "column",
        }}>

            <div style={{
                float: 'left',
                flexDirection: "column",
                display: "flex"
            }}>
                <div>
                    <Label style={{width: "140px"}}>
                        Active Converter
                    </Label>

                    <Dropdown
                        selectOnBlur={false}
                        style={{width: "240px"}}
                        placeholder={"Active Converter"}
                        options={dropDownPropertyNameOptions()}
                        value={selectedActiveConverterIndex}
                        onChange={(evt, data) => {
                            setSelectedActiveConverterIndex(0)
                            setSelectedTOs([])
                        }
                        }/>
                </div>
            </div>

            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            }}>

            <div>
                <Checkbox
                    disabled={outputTOs?.length == 0}
                    checked={allSelected()}
                    onChange={() => {
                        if (allSelected()) {
                            setSelectedTOs([])
                        } else {
                            setSelectedTOs(outputTOs)
                        }
                    }}
                /> - select all
                <Button
                    onClick={() => {
                        setColumnSelectorModalOpen(true)
                    }}>Select Columns
                </Button>
            </div>

            <div style={{float: 'right'}}>
                <Button
                    loading={isLoading}
                    disabled={selectedTOs.length == 0}
                    onClick={() => onSave()}
                >
                    Save selected TOs in DB
                </Button>
            </div>
        </div>
        {outputTOs && drawTable()}


        <Notifications/>

        <ImportSimTransportOrderOutputTableColumnSelectorModal
            isOpen={columnSelectorModalOpen}
            onClose={() => setColumnSelectorModalOpen(false)}
        />
        {compareOrder && <ImportSimCompareOrderChangeModal
            isOpen={compareOrder != null}
            onClose={() => {
                setCompareOrder(null)
                setComparing(false)
            }}
            order={compareOrder}
        />}
    </div>
}