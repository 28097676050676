import * as React from "react";
import {Grid, Icon} from "semantic-ui-react";
import AceEditor from "react-ace";
import {LogView} from "../../logView/LogView";
import ReactTable from "react-table";
import {useContext} from "react";
import {ConfigContext} from "../../../context/ConfigContext";


export interface CustomerReportSftpStatusProps {
    columns: any[]
    data: any[]
}

export function CustomerReportDynamicSection(props: CustomerReportSftpStatusProps) {

    const context = useContext(ConfigContext)

    const [json, setJson] = React.useState(null)
    const [index, setIndex] = React.useState(null)


    console.log("CustomerReportDynamicSection - ", props.data)
    if(!props.data) {
        return <div>no data</div>
    }
    return <Grid padded="horizontally" stackable columns='equal' centered>
        <Grid.Column>
            <ReactTable
                data={props.data}
                columns={props.columns}
                defaultPageSize={25}
                className="-striped -highlight"
                getTdProps={(state, rowInfo, column, instance) => {
                    return {
                        onClick: () => {
                            setJson(JSON.stringify(rowInfo.row._original, null, 4))
                            setIndex(rowInfo.index)
                        },
                        style: {
                            background: rowInfo?.index === index ? '#00afec' : 'white',
                            color: rowInfo?.index === index ? 'white' : 'black'
                        }
                    };
                }
                }
                style={{cursor: "pointer"}}
            />
        </Grid.Column>
        <Grid.Column>
            <AceEditor
                theme="monokai"
                mode='groovy'
                value={json}
                width="100%"
                height="800px"
                readOnly={true}
            />
            <LogView query={context.customerReportXReqId} origin={"customerReport"}/>
        </Grid.Column>
    </Grid>
}

export function healthyHighlighter(healthy: boolean) {
    console.log("healthyHighlighter - ", healthy)
    if (healthy) {
        return <Icon name={'heartbeat'} color={'green'}/>
    } else {
        return <Icon name={'ambulance'} color={'red'}/>
    }
}

export function statusHighlighter(status) {
    if (status == "SUCCESS" || status == "OK") {
        return <Icon name={'heartbeat'} color={'green'}/>
    } else {
        if (status == "WARNING") {
            return <Icon name={'ambulance'} color={'yellow'}/>
        } else {
            return <Icon name={'ambulance'} color={'red'}/>
        }
    }
}