import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import {Accordion, Icon, Label} from "semantic-ui-react";
import {fetchCustomerReport} from "../../../../style/page_with_sidebar";
import {ConfigContext} from "../../../context/ConfigContext";
import {CustomerReportApiGatewayStatus} from "./sections/CustomerReportApiGatewayStatus";
import {CustomerReportSftpStatus} from "./sections/CustomerReportSftpStatus";
import {CustomerReportFileHealthStatus} from "./sections/CustomerReportFileHealthStatus";
import {CustomerReportQueueStatus} from "./sections/CustomerReportQueueStatus";
import {CustomerReportStatusJsonReport} from "./sections/CustomerReportStatusJsonReport";
import {
    CustomerReportNeedsVerificationFilesCountStatus
} from "./sections/CustomerReportNeedsVerificationFileCountStatus";
import {CustomerReportEtaCalculatorStatus} from "./sections/CustomerReportEtaCalculatorStatus";
import {CustomerReportErrorFilesCountStatus} from "./sections/CustomerReportErrorFileCountStatus";
import {CustomerReportMessageLogReport} from "./sections/CustomerReportMessageLogReport";

export interface CustomerReportPageProps {

}

export function CustomerReportPage(props: CustomerReportPageProps) {
    const context = useContext(ConfigContext);
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState([]);

    useEffect(() => {
        // when the url has a report specified, we want to open the corresponding accordion
        const report = getUrlReport();
        console.log("[CustomerReportPage] report from url", report)
        if (report) {
            let list = report.split(",").map(Number);
            setActiveIndex(list);
        }
    }, []);

    useEffect(() => {
        if (context.customerReport == null) {
            fetchCustomerReport(context).then(() => {
                setIsLoading(false);
            })
        } else {
            setIsLoading(false);
        }
    }, [context.customerReport]);

    // URL handling
    function setUrlReport(reportIndex: string | number) {
        history.push({
            pathname: history.location.pathname,
            search: `?tab=2&report=${reportIndex}`
        });
    }

    function getUrlReport() {
        const url = new URL(window.location.href);
        return url.searchParams.get("report");
    }

    function getColorForStatusJsonReportSummary(amount: number | undefined) {
        if (amount === undefined) {
            return "grey"
        }
        if (amount > 0) {
            if (amount > 5) {
                return "red"
            } else {
                return "orange"
            }
        } else {
            return "green"
        }

    }

    function drawStatusJsonReportSummary() {
        let report = context.customerReport?.statusJsonReport
        console.log("statusJsonReport", report)

        return <>
            <Label
                color={getColorForStatusJsonReportSummary(report?.unhealthyCompanies)}>unhealthyCompanies: {report?.unhealthyCompanies ?? "n/a"}</Label>
            <Label>totalCompaniesChecked: {report?.totalCompaniesChecked}</Label>
            <Label
                color={getColorForStatusJsonReportSummary(report?.companiesMissingStatusJsonFile)}>companiesMissingStatusJsonFile: {report?.companiesMissingStatusJsonFile ?? "n/a"}</Label>
            <Label
                color={getColorForStatusJsonReportSummary(report?.companiesWithLastRunOld)}>companiesWithLastRunOld: {report?.companiesWithLastRunOld ?? "n/a"}</Label>
            <Label
                color={getColorForStatusJsonReportSummary(report?.companiesWithoutRecentFileUpdate)}>companiesWithoutRecentFileUpdate: {report?.companiesWithoutRecentFileUpdate ?? "n/a"}</Label>
        </>
    }

    function drawNeedVerificationFileCountReportSummary() {
        let report = context.customerReport?.needsVerificationFilesCount
        const healthStatus = report?.healthy.toString()
        console.log("needsVerificationFilesCount", report)

        return <>
            <Label color={getColorForStatusJsonReportSummary(report?.filesOlderThanFiveMinutes)}>
                Healthy: {healthStatus?.charAt(0).toUpperCase() + healthStatus?.slice(1)}</Label>
            <Label>totalCompaniesChecked: {report?.companies.length}</Label>
            <Label
                color={getColorForStatusJsonReportSummary(report?.filesOlderThanFiveMinutes)}>filesOlderThanFiveMinutes: {report?.filesOlderThanFiveMinutes ?? "n/a"}</Label>
        </>
    }

    function drawErrorFileCountReportSummary() {
        let report = context.customerReport?.errorFilesCount
        const healthStatus = report?.healthy.toString()
        console.log("errorFilesCount", report)

        return <>
            <Label color={getColorForStatusJsonReportSummary(report?.filesOlderThanFiveMinutes)}>
                Healthy: {healthStatus?.charAt(0).toUpperCase() + healthStatus?.slice(1)}</Label>
            <Label>totalCompaniesChecked: {report?.companies.length}</Label>
            <Label
                color={getColorForStatusJsonReportSummary(report?.filesOlderThanFiveMinutes)}>filesOlderThanFiveMinutes: {report?.filesOlderThanFiveMinutes ?? "n/a"}</Label>
        </>
    }

    function drawSftpStatusReportSummary() {
        let report = context.customerReport?.fileSystemWorkerStatus?.sftpSyncResults ?? []
        console.log("sftpSyncResults", report)

        const totalCompanies = report.length;
        const icingaNotSuccess = report.filter(company => company.icingaState !== 'SUCCESS').length;
        const statusNotSuccess = report.filter(company => company.status !== 'SUCCESS').length;
        return <>
            <Label color={getColorForStatusJsonReportSummary(icingaNotSuccess + statusNotSuccess)}>
                Healthy: {(icingaNotSuccess == 0 && statusNotSuccess == 0) ? "True" : "False"}</Label>
            <Label>totalCompaniesChecked: {totalCompanies}</Label>
            <Label
                color={getColorForStatusJsonReportSummary(icingaNotSuccess)}>Failed Icinga
                instances: {icingaNotSuccess ?? "n/a"}</Label>
            <Label
                color={getColorForStatusJsonReportSummary(statusNotSuccess)}>Failed Status
                instances: {statusNotSuccess ?? "n/a"}</Label>
        </>
    }

    function drawFileStatusReportSummary() {
        let report = context.customerReport?.fileSystemWorkerStatus?.customerOldFiles
        console.log("fileHealthReport", report)

        const totalCompanies = report?.length;
        const oldFileAmount = report?.filter(company => company?.oldFileAmount > 0).length;
        const healthyStatus = report?.filter(company => company?.healthy !== true).length;
        return <>
            <Label
                color={getColorForStatusJsonReportSummary(healthyStatus)}>Healthy: {(healthyStatus > 0) ? "False" : "True"}</Label>
            <Label>totalCompaniesChecked: {totalCompanies}</Label>
            <Label
                color={getColorForStatusJsonReportSummary(oldFileAmount)}>Old File
                Amount: {oldFileAmount ?? "n/a"}</Label>
        </>
    }

    function deepCopy(obj: any): any {
        return JSON.parse(JSON.stringify(obj));
    }

    function handleAccordionClick(e: any, titleProps: { index: any; }) {
        console.log("handleAccordionClick", titleProps)
        const {index} = titleProps
        let newIndex = deepCopy(activeIndex)
        if (newIndex.includes(index)) {
            newIndex = activeIndex.filter(i => i != index)
        } else {
            newIndex.push(index)
        }
        setUrlReport(newIndex)
        setActiveIndex(newIndex)
    }

    function addAccordionSection(title: string, index: number, content: any) {
        let shortReport: JSX.Element;
        switch (index) {
            case 1:
                shortReport = drawSftpStatusReportSummary()
                break;
            case 2:
                shortReport = drawFileStatusReportSummary()
                break;
            case 4:
                shortReport = drawStatusJsonReportSummary()
                break;
            case 5:
                shortReport = drawNeedVerificationFileCountReportSummary()
                break;
            case 6:
                shortReport = drawErrorFileCountReportSummary()
                break;
        }
        return (
            <>
                <Accordion.Title
                    active={activeIndex.includes(index)}
                    index={index}
                    onClick={handleAccordionClick}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }} // Add flex layout to space items
                >
                <span>
                    <Icon name='dropdown'/>
                    {title}
                </span>
                    {shortReport && (
                        <div style={{marginLeft: 'auto'}}> {/* Add margin to the left to push content to the right */}
                            {shortReport}
                        </div>
                    )}
                </Accordion.Title>
                <Accordion.Content active={activeIndex.includes(index)}>
                    {content}
                </Accordion.Content>
            </>
        );
    }

    if (isLoading) {
        return <div>Loading customer report...</div>
    } else {
        console.log("CustomerReportPage", context.customerReport)
        return <Accordion styled fluid>
            {addAccordionSection("ApiGateway Status", 0, <CustomerReportApiGatewayStatus/>)}
            {addAccordionSection("Sftp Status", 1, <CustomerReportSftpStatus/>)}
            {addAccordionSection("File Status", 2, <CustomerReportFileHealthStatus/>)}
            {addAccordionSection("Queue Status", 3, <CustomerReportQueueStatus/>)}
            {addAccordionSection("Status.json Report", 4, <CustomerReportStatusJsonReport/>)}
            {addAccordionSection("Needs Verification Files Count", 5,
                <CustomerReportNeedsVerificationFilesCountStatus/>)}
            {addAccordionSection("Error Files Count", 6, <CustomerReportErrorFilesCountStatus/>)}
            {addAccordionSection("Eta Calculator", 7, <CustomerReportEtaCalculatorStatus/>)}
            {addAccordionSection("MessageLog Report", 8, <CustomerReportMessageLogReport/>)}
        </Accordion>
    }


}