import * as React from "react";
import {useEffect, useState} from "react";
import AceEditor from "react-ace";
import {EncodingSelector} from "../docViewer/EncodingSelector";
import 'brace/mode/groovy'
import 'brace/mode/xml'
import 'brace/mode/json'
import 'brace/mode/yaml'

import 'brace/ext/searchbox'

export interface DisplayFileProps {
    fileBuffer: ArrayBuffer;
    fileName: string;
    displayName: boolean;
}

export function DisplayFile(props: DisplayFileProps) {

    const [displayedElement, setDisplayedElement] = useState<JSX.Element>(<>Loading...</>)
    const [encoding, setEncoding] = useState('utf-8')
    const [fileText, setFileText] = useState('')

    useEffect(() => {
        drawFilePreview().then(element => {
            setDisplayedElement(element)
        })

        if(fileIsText() && props.fileBuffer) {
            console.log("FileBuffer : ", props.fileBuffer)
            setFileText(readFileContent())
        }
    }, [])

    useEffect(() => {
        console.log('Encoding changed', encoding)
        if(fileIsText() && props.fileBuffer) {
            setFileText(readFileContent())
        }
    }, [encoding])

    useEffect(() => {
        if(fileIsText() && props.fileBuffer) {
            setFileText(readFileContent())
        }
        drawFilePreview().then(element => {
            setDisplayedElement(element)
        })
    }, [props.fileBuffer, fileText])

    function fileIsText() {
        switch (props.fileName.toLowerCase().split('.').pop()) {
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'pdf': {
                return false
            }
            default: {
                return true
            }
        }
    }
    function readFileContent() {
        console.log('Reading file content with encoding', encoding)
        let decoder = new TextDecoder(encoding);
        return beautify(decoder.decode(props.fileBuffer))
    }

    function beautify(uglyText: string) {
        let fileEnding = props.fileName.toLowerCase().split('.').pop()
        if (fileEnding === 'json') {
            return JSON.stringify(JSON.parse(uglyText), null, 2)
        }
        if (fileEnding === 'xml' || fileEnding === 'xsl' || fileEnding === 'exp') {
            console.log("Beautifying XML")
            try {
                const xmlFormatter = require('xml-formatter');
                return xmlFormatter(uglyText)
            } catch (e) {
                console.error('Error beautifying XML', e)
            }
        }
        return uglyText

    }

    async function drawFilePreview() {

        let fileType = props.fileName.toLowerCase().split('.').pop()
        if(fileType === 'exp' || fileType === 'xsl') {
            fileType = 'xml'
        }
        switch (fileType) {
            case 'pdf':
                return <div>
                    <iframe

                        id={"attachmentPreviewIFrame"}
                        key={0}
                        src={URL.createObjectURL(new Blob([props.fileBuffer], {type: 'application/pdf'}))}
                        title={props.fileName}
                        height={window.innerHeight - 200 + "px"}
                        width={'100%'}
                    />
                </div>
            case 'png':
            case 'jpg':
            case 'jpeg': {
                console.log("Drawing image")
                return <div>
                    <ImageDisplay arrayBuffer={props.fileBuffer}/>
                </div>
            }
            default: {
                return <div
                    //    style={{display: "flex", flex: 1, flexDirection: "column"}}
                >
                    <AceEditor
                        theme="monokai"
                        style={{flex: 1}}
                        mode={fileType}
                        value={fileText}
                        placeholder={'Nothing found'}
                        width="100%"
                        height={window.innerHeight - 200 + "px"}
                        readOnly={true}
                    />
                    <EncodingSelector
                        encoding={encoding}
                        onChange={(encoding) => {
                            setEncoding(encoding)
                        }}
                    />
                </div>
            }
        }
    }

    return <>
        {props.displayName && <h2>{props.fileName}</h2>}
        {displayedElement}
    </>

}

function ImageDisplay({arrayBuffer}) {
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        const blob = new Blob([arrayBuffer], {type: 'image/jpeg'}); // Adjust the MIME type as needed
        const url = URL.createObjectURL(blob);
        setImageUrl(url);

        // Clean up function to revoke the object URL
        return () => {
            URL.revokeObjectURL(url);
        };
    }, [arrayBuffer]);

    if (!imageUrl) {
        return <div>Loading...</div>;
    }

    return <img src={imageUrl} alt="From ArrayBuffer"/>;
}