import * as React from "react";

import Notifications from 'react-notify-toast';
import {ConfigContext} from "../../context/ConfigContext";
import {PageWithSidebar} from "../../../style/page_with_sidebar";
import GeostatusTabMenu from "./GeostatusTabView";


export class GeostatusPage extends React.Component<any, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return <PageWithSidebar activeMenu="Geostatus">
            <GeostatusTabMenu context={this.context}/>

            <Notifications/>
        </PageWithSidebar>;
    }


}