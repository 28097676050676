import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import {Button, Dropdown, Icon, Image, Label, Menu, Popup, Segment, SemanticCOLORS, SemanticICONS, Sidebar} from 'semantic-ui-react';
import {authentication} from "../../authentication";
import {ChangeCompanyModal} from '../ui/admin/change_company_modal';
import './box.css';
import {IfBox} from './if';
import Hotkeys from 'react-hot-keys';
import {backend} from '../../xconvert-backend';
import {User} from '@sentry/browser';
import {Company, ConfigFieldDescription, SaveConfigAsDraftRequest} from '../../generated';
import {ConfigContext} from "../main/context/ConfigContext";
import Notifications, {notify} from 'react-notify-toast';
import {now} from "moment";
import {CompanyIdSearchModal} from "../ui/admin/CompanyIdSearchModal";
import {configStorage} from "../../ConfigStorage";
import {CreateCompanyModal} from "../ui/admin/createNewCompany/create_company_modal";
import {VersionReportFunction} from "../main/pages/systemStatus/healthAndVersion/VersionReportFunction";
import {createCustomerReport} from "../main/context/CustomerReport";
import {CustomerReportPopup} from "../main/pages/systemStatus/CustomerReport/CustomerReportPopup";
import {v4 as uuid} from 'uuid';
import {RefreshTokenModal} from "../main/pages/configChange/configEdit/modals/RefreshTokenModal";
import {ConfigMenuButtons} from "../util/ConfigMenuButtons";
import {signal, Signal} from "@preact/signals-react";
import {loadFieldDescriptions} from "../main/pages/configChange/configEdit/rightSide/FieldDescriptionView";
import {companyIdSignal, loadWorkingConfig, workingConfig} from "../main/pages/configChange/ConfigSignal";

var version = require('../../version.js');

export type ActiveMenuEntry = "Config"
    | "Converter"
    | "Script Execution"
    | "FTP Explorer"
    | "DocViewer"
    | "System status"
    | "Queues"
    | "Contracts"
    | "TransportOrderView"
    | "Scheduler"
    | "DBqueryPage"
    | "TableSplitter"
    | "Geostatus"

export interface PageWithSidebarProps {
    activeMenu: ActiveMenuEntry
}

export const fieldDescriptions: Signal<ConfigFieldDescription[]> = signal([])


function PageWithSidebarI(props: React.PropsWithChildren<PageWithSidebarProps & RouteComponentProps<any>>) {

    const context = React.useContext(ConfigContext)

    const [menuVisible, setMenuVisible] = React.useState(true)
    const [intervalId, setIntervalId] = React.useState(null)
    const [companyId, setCompanyId] = React.useState("")
    const [auth, setAuth] = React.useState(null)
    const [configOutOfSync, setConfigOutOfSync] = React.useState(false)
    const [color, setColor] = React.useState<SemanticCOLORS>("black")
    const [authExpiresSoon, setAuthExpiresSoon] = React.useState(false)
    const [shouldShowCopyCompanyModal, setShouldShowCopyCompanyModal] = React.useState(false)
    const [shouldShowChangeCompanyModal, setShouldShowChangeCompanyModal] = React.useState(false)
    const [showAuthRefreshModal, setShowAuthRefreshModal] = React.useState(false)
    const [showCreateCompanyModal, setShowCreateCompanyModal] = React.useState(false)

    React.useEffect(() => {
        let companyId = props.match.params.companyId

        if(companyId == "company_id_by_user") {
            // Extract the current URL
            const currentUrl = location.pathname;

            // Replace the desired part of the URL
            const newUrl = currentUrl.replace("company_id_by_user", authentication.company._id);

            // Update the URL
            window.history.replaceState(null, null, newUrl);
            companyId = authentication.company._id
        }
        companyIdSignal.value = companyId
        context.setCompanyId(companyId)

        let auth = backend.withTokenAuthHeader(authentication.token)
        let intervalId = setInterval(timer, 30000);
        setIntervalId(intervalId)
        setCompanyId("/" + companyId)
        setAuth(auth)

        async function loadAsyncStuff() {
            let company = await backend.companyApi.fetchCompanyById(companyId, auth)
            context.setCompanyName(company.name)
            context.setCompanyTag(company.tag)

            let config = await loadWorkingConfig(companyId) // HERE we load the working config
            console.log("[PageWithSidebarI] loaded working config: ", config)
            context.setResponsibleManager(config?.responsibleLogeniosManager)

            document.title = "CM - " + company.name

            loadFieldDescriptions()
            await loadScriptInfo()
            await innerCheckIfConfigChanged()
        }

        loadAsyncStuff()

        return () => {
            clearInterval(intervalId)
        }
    }, [])

    React.useEffect(() => {
        return () => {
            clearInterval(intervalId)
        }
    }, [props.match.params.companyId]); // Run useEffect only when props.match.params.companyId changes

    async function loadScriptInfo() {
        let auth = backend.withTokenAuthHeader(authentication.token)
        let scriptInfo = await backend.internalApi.getScriptInfo(auth)
        context.setScriptInfo(scriptInfo.scripts)
    }

    const timer = () => {
        checkForAutoSave()
        checkIfAuthExpired()
        innerCheckIfConfigChanged()
        fetchCustomerReport(context)
    }

    async function innerCheckIfConfigChanged() {

        if (await checkIfConfigChanged(props.match.params.companyId)) {
            setConfigOutOfSync(true)
            setColor("red")
        } else if (configOutOfSync) {
            setConfigOutOfSync(false)
            if (!authExpiresSoon) {
                setColor("black")
            }
        }
    }

    async function checkIfAuthExpired() {
        if (authentication.validUntil.getTime() < now()) {
            console.log("auth expired")
            gotoLogout()
        } else {
            let ageLine = (now() + (1000 * 60 * 60 * 24))
            if (authentication.validUntil.getTime() < ageLine) {
                console.log("auth expires in less than a day")
                setAuthExpiresSoon(true)
                setColor("red")
            } else if (authExpiresSoon) {
                setAuthExpiresSoon(false)
                if (!configOutOfSync) {
                    setColor("black")
                }
            }
        }
    }

    async function checkForAutoSave() {
        // console.log("checking if CompanyConfig has changed and needs to be saved as draft")

        // 1.   get latest draft (by user & company) (cache it in the context but don't forget to delete it when company changes)
        // 2.   get current config
        // 3.   compare them
        // 4.   if they differ, save current config as draft

        // if the current config didn't change, it will be in the latest draft soon,
        // on the second roundtrip, it will not be saved again.

        let user = authentication.user
        let company = authentication.company

        //1.
        //2. 3.
        if (context.latestDraft != workingConfig.value) {
            //4.
            if (context.latestDraft == null) {
                // first run, save current config as draft
                context.setLatestDraft(workingConfig.value)
            } else {
                console.log("the latest Draft differs from the current config. Save the current config as draft.")
                autoSaveCurrentConfigAsDraft(user, company)
            }
        } else {
            console.log("config doesn't differ from latest autoSave draft")
        }

    }

    async function autoSaveCurrentConfigAsDraft(user: User, company: Company) {
        let auth = backend.withTokenAuthHeader(authentication.token)

        // save as draft with autoSave prefix in the name
        // check if too much drafts of this user for this company exists
        // if so, delete the oldest

        let prefix = "autoSave_" + user.email + "_" + company.name
        let request = {} as SaveConfigAsDraftRequest
        request.config = JSON.parse(workingConfig.value)
        request.companyId = company._id
        request.draftName = prefix + "_" + (new Date().getTime())

        request.autoSaved = true

        request.oldConfig = await backend.internalApi.fetchCompanyConfiguration(company._id, auth)

        await backend.internalApi.saveConfigAsDraft(request, auth)
        context.setLatestDraft(workingConfig.value)

        await backend.internalApi.deleteOldestAutoSavedDraftOfConfig(auth)
        notify.show('autoSaved as draft', 'success', 3000, '#28f751');


    }

    const handleSidebarHide = () => setMenuVisible(false)

    function goto(url) {
        console.log("GOTO: ", companyId + url)
        props.history.push(companyId + url)
    }

    function isActive(entry: ActiveMenuEntry) {
        return props.activeMenu == entry
    }

    function gotoLogout() {
        props.history.push("/account/logout")
    }

    function renderAdminFeatures() {
        return [<ChangeCompanyModal
            key="change_company"
            open={shouldShowChangeCompanyModal}
            onClose={() => {
                setShouldShowChangeCompanyModal(false)
            }}/>,
            <CompanyIdSearchModal
                key="copy_company"
                open={shouldShowCopyCompanyModal}
                onClose={() => {
                    setShouldShowCopyCompanyModal(false)
                }}/>,
            <CreateCompanyModal
                key="create_company"
                onClose={() => setShowCreateCompanyModal(false)}
                open={showCreateCompanyModal}
            />
        ]
    }

    function triggerSuperuserChangeCompanyMenu() {
        if (authentication.canSwitchCompany) {
            return <Hotkeys
                keyName="ctrl+m"
                key="ctrlmhotkey"
                onKeyDown={(keyName, e) => {
                    setShouldShowChangeCompanyModal(true);
                    e.preventDefault()
                }}
            ><Dropdown.Item onClick={() => {
                setShouldShowChangeCompanyModal(true)
            }} key="changeCompanyItem" description='STRG+m' text="Firma wechseln"/></Hotkeys>
        }
        return null
    }

    function triggerSuperuserFindCompanyMenu() {
        if (authentication.isSuperUser) {
            return <Hotkeys
                keyName="ctrl+b"
                key="ctrlbhotkey"
                onKeyDown={(keyName, e) => {
                    setShouldShowCopyCompanyModal(true)
                    e.preventDefault()
                }}
            >
                <Dropdown.Item
                    onClick={() => {
                        setShouldShowCopyCompanyModal(true)
                    }}
                    key="findCompanyItem"
                    description='STRG+b'
                    text="Firma suchen"
                />
            </Hotkeys>
        }
        return null
    }

    function drawState() {
        if (workingConfig.value == null) return null

        let state = JSON.parse(workingConfig.value)?.state
        let style = {
            marginTop: -10,
            marginRight: 10,
        }
        switch (state) {
            case "LIVE":
                return null
            case "UNDER_CONSTRUCTION":
                return <Menu.Item>
                    <h2>
                        <Label style={style} size={"large"} color="yellow">under construction</Label>
                    </h2>
                </Menu.Item>

            case "TEST_COMPANY":
                return <Menu.Item>
                    <h2>
                        <Label style={style} size={"large"} color="red">Test Company</Label>
                    </h2>
                </Menu.Item>

        }
        return null
    }

    function MenuEntry(
        name: ActiveMenuEntry,
        icon: SemanticICONS,
        path: string,
        superUserOnly: boolean = false
    ) {
        let elem = <Menu.Item
            active={isActive(name)}
            onClick={(evt) => goto(path)}
        >
            <Icon name={icon}/>{name}
        </Menu.Item>

        if (superUserOnly) {
            return <IfBox shouldShow={authentication.isSuperUser}>
                {elem}
            </IfBox>
        }
        return elem
    }

    function render() {
        console.log("%cLOCAL FORAGE PageWithSidebarI render ", 'color: teal')

        const pusherStyle = menuVisible ? "calc(100% - 150px)" : "100%"
        const user = authentication.user;

        let responsibleManager = "N/A"
        if (context.responsibleManager != null) {
            responsibleManager = context.responsibleManager
        }
        return (
            <div style={{overflow: "hidden"}}>
                <Menu style={{height: "50px"}} attached="top" color={color} inverted>
                    <Menu.Item>
                        <Image src="/assets/icon_only.png" size="mini" spaced="right"/>
                        Logenios
                    </Menu.Item>
                    <Menu.Item onClick={() => {
                        setMenuVisible(!menuVisible)
                    }}>
                        <Icon name="sidebar"/>
                    </Menu.Item>

                    {drawState()}

                    <Menu.Item position='right' fitted='vertically'>
                        <Menu className="compactMenuItems" secondary>
                            <IfBox shouldShow={authExpiresSoon}>
                                <Menu.Item fitted>
                                    <Popup
                                        content={'Your session will expire in less than 24h at ' + authentication.validUntil}
                                        trigger={
                                            <Button color={"yellow"} icon
                                                    onClick={() => setShowAuthRefreshModal(true)}>
                                                refresh Token <Icon name={'lock'} color={'red'} size={'large'}/>
                                            </Button>
                                        }/>
                                </Menu.Item>
                            </IfBox>
                            <Menu.Item fitted>
                                <ConfigMenuButtons configOutOfSync={configOutOfSync}/>
                            </Menu.Item>
                            <IfBox shouldShow={authentication.isSuperUser || authentication.hasCmUsePermission}>
                                <Menu.Item fitted>
                                    <Label
                                        style={{"userSelect": "all"}}>{"responsibility: " + responsibleManager}</Label>
                                </Menu.Item>
                            </IfBox>
                            <IfBox shouldShow={authentication.isSuperUser || authentication.hasCmUsePermission}>
                                <Menu.Item fitted>
                                    <Popup style={{width: "800px"}}
                                           position={"bottom right"}
                                           content={<VersionReportFunction lightView={true} displayDetails={() => {
                                           }}/>}
                                           trigger={<Label style={{"userSelect": "all"}}>
                                               {version.version}
                                           </Label>
                                           }/>
                                </Menu.Item>
                            </IfBox>
                            <IfBox shouldShow={authentication.isSuperUser || authentication.hasCmUsePermission}>
                                <Menu.Item fitted>
                                    <Popup style={{width: "800px"}}
                                           position={"bottom right"}
                                           content={<CustomerReportPopup/>}
                                           trigger={<Label
                                               style={{"userSelect": "all"}}>{context.companyName}</Label>
                                           }/>
                                </Menu.Item>
                            </IfBox>
                            <IfBox shouldShow={authentication.isSuperUser || authentication.hasCmUsePermission}>
                                <Menu.Item fitted>
                                    <Label style={{"userSelect": "all"}}>{props.match.params.companyId}</Label>
                                </Menu.Item>
                            </IfBox>
                            <Menu.Item fitted>
                                <Dropdown item text={user.firstName + " " + user.lastName} pointing="top right" fluid>
                                    <Dropdown.Menu style={{minWidth: "200px"}}>
                                        {triggerSuperuserChangeCompanyMenu()}
                                        {triggerSuperuserFindCompanyMenu()}
                                        <Dropdown.Item onClick={() => setShowCreateCompanyModal(true)}>Firma
                                            erstellen</Dropdown.Item>
                                        <Dropdown.Item onClick={() => gotoLogout()}>Abmelden</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Item>
                        </Menu>
                    </Menu.Item>
                </Menu>
                <Sidebar.Pushable as={Segment} attached="bottom" style={{borderLeft: "0px"}}>
                    <Sidebar
                        as={Menu}
                        animation="uncover"
                        visible={menuVisible}
                        icon="labeled"
                        vertical
                        inverted width="thin"
                    >

                        <IfBox shouldShow={authentication.isSuperUser || authentication.hasCmUsePermission}>
                            {MenuEntry("Config", "setting", "/config")}
                            {MenuEntry("Converter", "sign-in", "/convPage")}
                            {MenuEntry("Script Execution", "code", "/scriptExecution")}
                            {MenuEntry("DocViewer", "eye", "/docViewer")}
                            {MenuEntry("FTP Explorer", "file", "/explorer")}
                            {MenuEntry("System status", "heartbeat", "/systemStatus", true)}
                            {MenuEntry("Queues", "sort amount down", "/queues")}
                            {MenuEntry("Contracts", "euro", "/contracts", true)}
                            {MenuEntry("TransportOrderView", "fork", "/transportOrderView")}
                            {MenuEntry("Scheduler", "time", "/schedulerTaskPage", true)}
                            {MenuEntry("TableSplitter", "table", "/TableSplitter", true)}
                            {MenuEntry("DBqueryPage", "database", "/DBqueryPage", true)}
                            {MenuEntry("Geostatus", "map marker alternate", "/Geostatus", true)}
                            {/*MenuEntry("Ide", "code", "/ide", true)*/}
                        </IfBox>
                    </Sidebar>

                    <Sidebar.Pusher style={{
                        width: pusherStyle, display: "flex", flexDirection: "column",
                        height: (window.innerHeight - 50) + "px"
                    }}>
                        {props.children}
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
                {renderAdminFeatures()}
                {showAuthRefreshModal && <RefreshTokenModal
                    isOpen={showAuthRefreshModal}
                    onClose={() => setShowAuthRefreshModal(false)}
                />
                }
                <Notifications/>
            </div>
        )
    }

    return render()
}

export async function fetchCustomerReport(context: any) {
    let auth = backend.withTokenAuthHeader(authentication.token)
    let xReqId = uuid()
    context.setCustomerReportXReqId(xReqId)
    let response = await fetch('/internalMonitoring/customerReport', {
        method: 'GET',
        headers: {
            "Authorization": auth.headers.Authorization.toString(),
            "Content-Type": "application/json; charset=utf-8",
            'X-Request-ID': xReqId,
        }
    })

    if (response.status == 200) {

        let resp = await response.json()
        context.setCustomerReport(createCustomerReport(resp))
        return true
    } else {
        console.log("error fetching customer report")
        return false
    }
}

export async function checkIfConfigChanged(companyId): Promise<boolean> {

    let auth = backend.withTokenAuthHeader(authentication.token)

    let configFromBackend = await backend.internalApi.fetchCompanyConfiguration(companyId, auth)

    let unchangedConfig = (await configStorage.getElement(companyId)).unchangedConfig

    let configFromBackendJson = JSON.stringify(configFromBackend, null, 4)
    let unchangedConfigJson = JSON.stringify(unchangedConfig, null, 4)

    return configFromBackendJson != unchangedConfigJson
}

export const PageWithSidebar = withRouter(PageWithSidebarI)
