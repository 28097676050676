import * as React from "react";
import {ConfigContext} from "../../../context/ConfigContext";
import {Button, Checkbox, Grid, Header, Icon, Input, Label, List, ListItem, Loader, Modal} from "semantic-ui-react";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {
    BillingRequest,
    BillWorkLogBookEntriesRequest, FetchInvoicesRequest, Invoice,
    ProjectWorkLogBookEntry,
    UploadInvoiceToZohoRequest, ZohoResponse,
} from "../../../../../generated";
import DownloadLink from "../../../../util/react-download-link";
import {IfBox} from "../../../../style/if";
import ReactTable from "react-table";
import Notifications, {notify} from 'react-notify-toast';
import {formatDate, formatDateFromStringNoTime} from "../../../../../format";


export interface InvoiceListProps {

}

export interface InvoiceListState {
    isLoading: boolean

    invoices: Invoice[]
    allSelected: boolean
    selected: Invoice[]


    count: number
    page: number
    take: number
    sortBy: 'CREATED_DATE' | 'COMPANY_NAME' | 'SHORT_DESCRIPTION' | 'TIME_SPEND_ON_TASK' | 'BILLING_COST'
    sortDirection: 'ASC' | 'DESC'
}

export class InvoiceList extends React.Component<InvoiceListProps, InvoiceListState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,

            invoices: [],
            allSelected: false,
            selected: [],

            count: 0,
            page: 1,
            take: 25,
            sortBy: 'CREATED_DATE',
            sortDirection: 'ASC'
        }
    }

    async componentDidMount() {
        try {
            this.loadLInvoiceEntries()

        } catch (e) {
            this.setState({isLoading: false})
        }
    }


    async loadLInvoiceEntries() {
        this.setState({isLoading: true})
        try {
            let auth = (await backend.withTokenAuthHeader(authentication.token))

            let req = {} as FetchInvoicesRequest
            let resp = await backend.internalApi.fetchInvoices(req, auth)

            this.setState({invoices: resp.invoices, isLoading: false})
        } catch (e) {
            this.setState({isLoading: false})
        }
    }

    selectOrUnselectAll() {
        if (this.state.allSelected) {
            this.setState({
                allSelected: false,
                selected: []
            })
        } else {
            this.setState({
                allSelected: true,
                selected: this.state.invoices
            })
        }


    }

    render() {
        const buttonStyle = {} as React.CSSProperties
        buttonStyle.backgroundColor = '#4CAF50'
        buttonStyle.border = 'none'
        buttonStyle.color = 'white'
        buttonStyle.padding = '9px 20px'
        buttonStyle.textAlign = 'center'
        buttonStyle.textDecoration = 'none'
        buttonStyle.display = 'inline-block'
        buttonStyle.fontSize = '15px'
        buttonStyle.borderRadius = '5px'

        return <span>


            {this.drawTable()}

            <Notifications/>

        </span>
    }

    switchSelectionStateOfItem(entry: any) {
        this.setState({isLoading: true})

        let array = [...this.state.selected]; // make a separate copy of the array
        let index = array.indexOf(entry)
        if (index == -1) {
            // entry not found, adding Id

            this.setState({selected: array.concat(entry)})
            console.log("entry added")


        } else {
            // entry found, removing it

            array.splice(index, 1);
            this.setState({selected: array})
            console.log("entry removed")

        }
        this.setState({isLoading: false})
    }

    drawTable() {
        const columns = [{
            id: 'createdDate',
            Header: 'createdDate',
            width: 120,
            accessor: (d: Invoice) => formatDate(d.created),
        }, {
            id: 'companyName',
            Header: 'companyName',
            width: 300,
            accessor: (d: Invoice) => d.company.name,
        }, {
            id: 'from',
            Header: 'from',
            width: 130,
            accessor: (d: Invoice) => d.from,
        }, {
            id: 'to',
            Header: 'to',
            width: 130,
            accessor: (d: Invoice) => d.to,
        }, {
            id: 'zohoReferenceNumber',
            Header: 'zohoReferenceNumber',
            width: 200,
            accessor: (d: Invoice) => d.zohoReferenceNumber,
        }, {
            id: 'uploadedTime',
            Header: 'uploadedTime',
            width: 200,
            accessor: (d: Invoice) => d.uploadedToZoho,
        }
        ]
        return <span>

            <h3>Shows all Invoices which were created but not uploaded to zoho</h3><Button icon loading={this.state.isLoading} onClick={() => this.loadLInvoiceEntries()}><Icon name='sync'/> Reload </Button>

            <br/>
            <span style={{paddingRight: 20}}>
                <Checkbox
                    checked={this.state.allSelected}
                    onChange={() => {
                        this.selectOrUnselectAll()
                    }}/> - select all
            </span>

            <ReactTable
                data={this.state.invoices}
                pages={Math.ceil(this.state.count / this.state.take)}
                columns={columns}
                sorted={[
                    {
                        id: 'TIMESTAMP',
                        desc: false
                    }
                ]}
                onSortedChange={(newSorted, column) => {
                    this.changeSort(column.sortField)
                }}

                defaultPageSize={this.state.take}
                className="-striped -highlight"
                loading={this.state.isLoading}
                style={{cursor: "pointer"}}
                onPageChange={(pageIndex) => this.changePage(pageIndex)}
                onPageSizeChange={(pageSize) => this.changePageSize(pageSize)}

            />

        </span>
    }

    changeSort(newSortField: 'CREATED_DATE' | 'COMPANY_NAME' | 'SHORT_DESCRIPTION' | 'TIME_SPEND_ON_TASK' | 'BILLING_COST') {
        let sortDirection = this.state.sortDirection
        if (this.state.sortBy == newSortField) {
            if (sortDirection == 'ASC') {
                sortDirection = 'DESC'
            } else {
                sortDirection = 'ASC'
            }
        }
        this.setState({isLoading: true, sortBy: newSortField, sortDirection: sortDirection})
        this.loadLInvoiceEntries()
    }

    changePage(newPageIndex: number) {
        this.setState({isLoading: true, page: newPageIndex})
        this.loadLInvoiceEntries()
    }

    changePageSize(newPageSize: number) {
        this.setState({isLoading: true, take: newPageSize})
        this.loadLInvoiceEntries()
    }
}