import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {ConfigContext} from "../../../context/ConfigContext";
import {Label, Loader, SemanticCOLORS} from "semantic-ui-react";
import {fetchCustomerReport} from "../../../../style/page_with_sidebar";

export function CustomerReportPopup() {

    const context = useContext(ConfigContext)

    const [customerReportPresent, setCustomerReportPresent] = useState(null)

    useEffect(() => {
        if (context.customerReport == null) {
            fetchCustomerReport(context).then((result) => {
                setCustomerReportPresent(result)
            })
        } else {
            setCustomerReportPresent(true)
        }
    }, [])

    function drawCompanyApiStatus() {
        let color: SemanticCOLORS = 'green'
        let message = "Company API Status"
        let atLeastWarning = context.customerReport?.apiGatewayStatus?.companyApiStatus.filter((item) => {
            return item.status != "OK"
        })
        if (atLeastWarning.length > 0) {
            color = 'yellow'
            message = message + " - (" + atLeastWarning.length + " WARNINGS)"
        }
        let atLeastError = atLeastWarning.filter((item) => {
            return item.status != "WARNING"
        })
        if (atLeastError.length > 0) {
            color = 'red'
            message = message + " - (" + atLeastError.length + " ERRORS)"
        }

        return <Label color={color}>{message}</Label>
    }

    function drawSftpSyncResult() {
        let color: SemanticCOLORS = 'green'
        let message = "SFTP Sync Result"
        let errors = context.customerReport?.fileSystemWorkerStatus?.sftpSyncResults.filter((item) => {
            return item.status != "SUCCESS"
        })
        if (errors.length > 0) {
            color = 'red'
            message = message + " - (" + errors.length + " ERRORS)"
        }
        return <Label color={color}>{message}</Label>
    }

    function drawFileHealthReport() {
        let color: SemanticCOLORS = 'green'
        let message = "File Health Report"
        let errors = context.customerReport.fileSystemWorkerStatus.customerOldFiles?.filter((item) => {
            return !item.healthy
        })
        if (errors.length > 0) {
            let totalFiles = 0
            errors.forEach((item) => {
                totalFiles = totalFiles + item.oldFileAmount
            })
            color = 'red'
            message = message + " - (" + errors.length + " unhealthy customers) (" + totalFiles + " old files total)"
        }
        return <Label color={color}>{message}</Label>
    }

    function drawQueueStatus() {
        let color: SemanticCOLORS = 'green'
        let message = "Queues Status"
        let unprocessedEvents = 0
        let errors = context.customerReport.queueStatus.companies.filter((item) => {
            unprocessedEvents = unprocessedEvents + item.unprocessedEventsOlderThanFiveMinutes
            return item.unprocessedEventsOlderThanFiveMinutes > 0
        })
        if (errors.length > 0) {
            color = 'red'
            message = message + " - (" + errors.length + " queues with " + unprocessedEvents + " unprocessed events older than 5min.)"
        }
        return <Label color={color}>{message}</Label>
    }

    if (customerReportPresent == null) return <div>
        <Loader active inline='centered'/>
        Customer Report is loading
    </div>

    if (!customerReportPresent) return <div style={{backgroundColor: "red"}}>
        unable to load Customer Report! please check the logs!
    </div>

    return <div style={{display: "flex", flexDirection: "column", width: 400}}>
        {drawCompanyApiStatus()}
        {drawSftpSyncResult()}
        {drawFileHealthReport()}
        {drawQueueStatus()}
    </div>
}