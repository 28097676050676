import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Button, Checkbox, Divider, Icon, Label, Modal} from "semantic-ui-react";
import {ConfigContext} from "../../../../context/ConfigContext";
import {formatDate} from "../../../../../../format";
import {CustomerReportDynamicSection, healthyHighlighter} from "../CustomerReportDynamicSection";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {MessageLog} from "../../../../../../generated";
import ReactTable from "react-table";

export function CustomerReportMessageLogReport() {

    const context = useContext(ConfigContext)
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [messageLogs, setMessageLogs] = useState([]);
    const [index, setIndex] = useState(null);
    const [selectedLogs, setSelectedLogs] = useState<string[]>([]);
    const [allSelected, setAllSelected] = useState(false);

    function drawSimpleBoolean(b: boolean) {
        if (b) {
            return <Icon name={'check'} color={'green'}/>
        } else {
            return <Icon name={'x'} color={'red'}/>
        }
    }
    async function fetchMessageLogs(companyId: string) {
        try {
            let auth = backend.withTokenAuthHeader(authentication.token);
            const result = await backend.internalApi.fetchFailedMessageLogsOfSpecificCompany(companyId, auth);
            setMessageLogs(result.entries);
        } catch (error) {
            console.error("Error fetching message logs:", error);
        }
    }
    useEffect(() => {
        if (modalOpen && selectedData) {
            fetchMessageLogs(selectedData.companyId);
        }
    }, [modalOpen, selectedData]);

    const handleSelectAll = () => {
        if (selectedLogs.length === messageLogs.length) {
            setSelectedLogs([]);
        } else {
            setSelectedLogs(messageLogs.map(log => log._id));
        }
    };

    const handleSelectLog = (id) => {
        if (selectedLogs.includes(id)) {
            setSelectedLogs(selectedLogs.filter(logId => logId !== id));
        } else {
            setSelectedLogs([...selectedLogs, id]);
        }
    };

    useEffect(() => {
        setAllSelected(selectedLogs.length === messageLogs.length);
    }, [selectedLogs, messageLogs]);

    const handleAcknowledge = async () => {
        try {
            let auth = backend.withTokenAuthHeader(authentication.token);

            const companyDetails = {
                messageLogId: selectedLogs,
                companyId: selectedData.companyId
            }

            const response = await backend.internalApi.updateMessageLogToAcknowledgeStatus(companyDetails, auth);

            if (response) {
                console.log("Acknowledged logs: ", selectedLogs);
                setModalOpen(false);
                setSelectedLogs([]);
                setAllSelected(false);
                await fetchMessageLogs(selectedData.companyId);
            } else {
                console.error("Failed to acknowledge message logs: No logs returned");
            }
        } catch (error) {
            console.error("Error acknowledging message logs:", error);
        }
    };

    const columns = [{
        id: 'companyId',
        Header: 'companyId',
        width: 300,
        accessor: (d: any) => d.companyId,
    }, {
        id: 'companyName',
        Header: 'companyName',
        width: 300,
        accessor: (d: any) => d.companyName,
    }, {
        id: 'icon',
        Header: 'healthy',
        width: 70,
        accessor: (d: any) =>
            <div>{healthyHighlighter(d.totalSendErrors == 0)}</div>,
    }, {
        id: 'totalSendErrors',
        Header: 'totalSendErrors',
        width: 70,
        accessor: (d: any) => d.totalSendErrors,
    }, {
        id: 'viewDetails',
        Header: 'viewDetails',
        width: 70,
        accessor: (d: any) => {
            return (
                <Button icon onClick={() => {
                    setSelectedData(d);
                    setModalOpen(true);
                }}>
                    <Icon name='eye'/>
                </Button>
            );
        },
    }];

    const messageLogColumns = [{
        id: 'select',
        Header: <Checkbox
            checked={selectedLogs.length === messageLogs.length}
            indeterminate={selectedLogs.length > 0 && selectedLogs.length < messageLogs.length}
            onChange={handleSelectAll} />,
        accessor: '',
        Cell: ({ original }) => (
            <Checkbox
                checked={selectedLogs.includes(original._id)}
                onChange={() => handleSelectLog(original._id)}
            />
        ),
        sortable: false,
        width: 45,
    }, {
        id: 'id',
        Header: 'ID',
        accessor: (d: MessageLog) => d._id,
    }, {
        id: 'errorMessage',
        Header: 'Error Message',
        accessor: (d: MessageLog) => d.errorMessage ?? "No error message found",
    }, {
        id: 'retries',
        Header: 'Retries',
        accessor: (d: MessageLog) => d.retries ?? 0,
    }, {
        id: 'transportOrderId',
        Header: 'Transport Order ID',
        accessor: (d: MessageLog) => d.sendCause.transportOrderId ?? "",
    }, {
        id: 'receiver',
        Header: 'Receiver',
        accessor: (d: MessageLog) => d.sms ? d.sms.phoneNr : d.mail?.receiverAddresses?.join(', '),
    }];

    return (
        <>
            <CustomerReportDynamicSection columns={columns} data={context.customerReport?.messageLogHealth} />
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                    setSelectedLogs([]);
                }}
                size='large'
            >
                <Modal.Header>Failed message logs</Modal.Header>
                <Modal.Content>
                    <ReactTable
                        data={messageLogs}
                        showPagination={false}
                        columns={messageLogColumns}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        getTdProps={(state, rowInfo, column, instance) => {
                            return {
                                onClick: () => {
                                    setIndex(rowInfo.index);
                                },
                                style: {
                                    background: rowInfo?.index === index ? '#00afec' : 'white',
                                    color: rowInfo?.index === index ? 'white' : 'black'
                                }
                            };
                        }}
                        style={{ cursor: "pointer" }}
                    />
                </Modal.Content>
                <Modal.Actions>
                    {selectedLogs.length > 0 && (
                        <Button color='green' onClick={handleAcknowledge}>
                            Acknowledge
                        </Button>
                    )}
                    <Button onClick={() => {
                        setModalOpen(false);
                        setSelectedLogs([]);
                    }}>Close</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
}