import * as React from "react";
import {Accordion, Button, Checkbox, Grid, Header, Icon, Label, List, Modal, Popup, TextArea} from "semantic-ui-react";
import {parseDiff} from 'react-diff-view';
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {UpdateCompanyConfigRequestWrapper} from "../../../../../../generated";
import {diffLines, formatLines} from 'unidiff';
import {DiffWrapper} from "../diff/DiffWrapper";
import {IfBox} from "../../../../../style/if";
import {FailedTestTabView} from "./FailedTestTabView"
import {ConfigContext} from "../../../../context/ConfigContext";
import {configStorage} from "../../../../../../ConfigStorage";
import {updateConfig} from "../../ConfigSignal";
const YAML = require('yaml');

export interface ResponseModalProps {
    isOpen: boolean
    onClose: () => void
    oldConfig: string
    newConfig: string
    dispatch: (any) => void
    failedTests: any[]
    validationWarnings: any[] | null
}

export interface ResponseModalState {
    isSubmitting: boolean
    comment: string
    response: any | null
    hunks: any | null
    type: any | null
    testsFailed: boolean
    activeIndex: number
    yamlView: boolean
}

export class ChangeConfigModal extends React.Component<ResponseModalProps, ResponseModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            isSubmitting: false,
            comment: "",
            response: null,
            hunks: null,
            type: null,
            testsFailed: false,
            activeIndex: -1,
            yamlView: false
        }
    }

    handleClose = () => {
        if (this.state.response != null) {
            this.props.dispatch({
                validation: this.state.response
            });
        }
        this.props.onClose()
    }

    async save() {
        this.setState({isSubmitting: true})
        try {
            console.log("updating config in backend")
            let auth = backend.withTokenAuthHeader(authentication.token)
            let request = {} as UpdateCompanyConfigRequestWrapper
            request.configAsJson = this.props.newConfig
            let response = await backend.internalApi.updateCompanyConfiguration(this.context.companyId, request, this.context.saveConfigComment, auth)
            console.log("backend responded with: ", response)
            this.setState({response: response})
            if (response.isValid) {
                this.context.setSaveConfigComment("")
            }
            this.handleClose()
        } catch (ex) {
            console.log(ex);
        }
        await updateConfig(null, true, true)
        await configStorage.deleteCompany(this.context.companyId)
        this.setState({isSubmitting: false})
    }

    findChangedPathsLoop(oldPart, newPart, key, path) {
        let changes = []
        let o = oldPart[key]
        let n = newPart[key]
        if((n == null || o == null ) && n != o) {
            // something has been set to "or from" null
            if(path != "") {
                path = path + "."
            }
            let fullPath = path + "" + key
            changes.push(fullPath)
        } else {
            let oldJson = JSON.stringify(o)
            let newJson = JSON.stringify(n)
            if (oldJson != newJson) {
                // something has changed
                if(oldJson.startsWith("{") || oldJson.startsWith("[") ) {
                    // object or list, must go deeper
                    if(path != "") {
                        path = path + "."
                    }
                    let fullPath = path + "" + key
                    this.findChangedPaths(o, n, fullPath).map(change => {
                        changes.push(change)
                    })
                } else {
                    // simple value has changed
                    if(path != "") {
                        path = path + "."
                    }
                    let fullPath = path + "" + key
                    changes.push(fullPath)
                }
            }
        }
        return changes
    }
    findChangedPaths(oldPart, newPart, path) {

        let changes = []

        let oldKeys = Object.keys(oldPart)
        let newKeys = Object.keys(newPart)

        if(oldKeys.length === newKeys.length || oldKeys.length > newKeys.length) {
            // something has been changed or removed
            oldKeys.map(oldKey => {
                this.findChangedPathsLoop(oldPart, newPart, oldKey, path).map(change => {
                    changes.push(change)
                })
            })
        } else {
            // something has been added
            newKeys.map(newKey => {
                this.findChangedPathsLoop(oldPart, newPart, newKey, path).map(change => {
                    changes.push(change)
                })
            })
        }



        return changes
    }

    async componentDidMount() {
        if (this.state.hunks == null) {
            let oldConfig = this.props.oldConfig
            let newConfig = this.props.newConfig

            const diffText = formatLines(diffLines(oldConfig, newConfig), {context: 3});
            const [diff] = parseDiff(diffText, {nearbySequences: 'zip'});

            let commentPreset = this.context.saveConfigComment
            if (commentPreset == null || commentPreset == "") {
                let changedPaths = ""

                let changedValues = this.findChangedPaths(JSON.parse(oldConfig), JSON.parse(newConfig), "")

                changedValues.forEach(v => {
                    changedPaths = changedPaths + "\n -" + v
                })
                commentPreset = "\n\n-changed fields:\n " + changedPaths
            }

            // tests
            if (this.props.failedTests != null) {
                this.setState({testsFailed: this.props.failedTests.length != 0})
            }

            this.setState({hunks: diff.hunks, comment: commentPreset});
        }
    }

    listFailedTests() {
        return <List bulleted>
            {Object.values(this.props.failedTests).map(test => {
                console.log("building list of failed tests. Item: " + test.test?.name)
                return <List.Item key={test.test?.name as string}>{test.test?.name}</List.Item>
            })}
        </List>
    }

    handleClick = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndex} = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({activeIndex: newIndex})
    }

    toYaml(obj: any) {
        if (obj) {
            const doc = new YAML.Document();
            doc.contents = obj;
            return doc.toString();
        } else {
            return '';
        }
    }

    drawDiff() {
        let a =this.cleanBackslashR(this.props.oldConfig)
        let b =this.cleanBackslashR(this.props.newConfig)
        let format = "json"
        if(this.state.yamlView) {
            a = this.toYaml(JSON.parse(this.props.oldConfig))
            b = this.toYaml(JSON.parse(this.props.newConfig))
            format = "yaml"
        }

        console.log("set format to ", format)
        return <DiffWrapper
            id={"diffWrapper"}
            oldText={a}
            newText={b}
            format={format}
        />
    }

    render() {
        const {activeIndex} = this.state
        console.log("this.props.oldConfig",this.props.oldConfig)
        console.log("this.props.newConfig",this.props.newConfig)
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            size='fullscreen'>
            <Header icon='browser' content='Config change'/>
            <Modal.Content>

                <IfBox shouldShow={this.state.testsFailed}>
                    <Grid padded="horizontally" stackable columns='5'>
                        <Grid.Row>
                            <Grid.Column>
                                <Icon name='warning sign' size='massive' color='yellow'/>
                            </Grid.Column>
                            <Grid.Column>
                                <Label color='red'>Tests have failed!</Label>
                                {this.listFailedTests()}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Accordion>
                                <Accordion.Title
                                    active={activeIndex === 0}
                                    index={0}
                                    onClick={this.handleClick}
                                >
                                    <Icon name='dropdown'/>
                                    show failed test-results
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === 0}>
                                    <FailedTestTabView source={this.props.failedTests}/>
                                </Accordion.Content>
                            </Accordion>

                        </Grid.Row>
                    </Grid>
                </IfBox>

                {this.renderValidationWarnings()}

                <p>JSON - <Checkbox
                    type='checkbox'
                    toggle
                    checked={this.state.yamlView}
                    onChange={() => {
                        this.setState({yamlView: !this.state.yamlView})
                    }}
                /> - YAML </p>

                {this.drawDiff()}

                <h3>leave a comment</h3>
                <TextArea
                    id='CommentTextArea'
                    value={this.state.comment}
                    onChange={(event) => {
                        this.context.setSaveConfigComment(event.currentTarget.value)
                        this.setState({comment: event.currentTarget.value})
                    }}
                    cols={200}
                    rows={10}
                />
                <br/>
                <Button id='ChangeConfigModalSaveButton' primary loading={this.state.isSubmitting} onClick={() => {
                    this.save();
                    this.handleClose
                }}>Save</Button>
                <Button id='ChangeConfigModalCancelButton' onClick={() => this.handleClose()}>Cancel</Button>

            </Modal.Content>
        </Modal>

    }

    renderValidationWarnings() {
        if (this.props.validationWarnings?.length > 0) {
            return <List bulleted>
                {this.props.validationWarnings.map((warning) => {
                    return <List.Item key={warning.ruleName}>
                        <Popup content={
                            <div>
                                <p><a style={{fontWeight: "bold"}}> RuleName: </a>{warning.ruleName}</p>
                                <p><a style={{fontWeight: "bold"}}> Description: </a>{warning.description}</p>
                            </div>
                        } trigger={
                            <div>
                                <Icon name="exclamation triangle" color="yellow" size={"large"}/> {" - "}
                                {warning.warning}
                            </div>
                        }
                               on={'hover'}
                               wide={"very"}
                        />


                    </List.Item>
                })}
            </List>
        } else {
            return <p color={'green'}>No Warnings!</p>
        }

    }

    cleanBackslashR(str: string): string {
        return str.split("\\r").join("")
    }

}

