import * as React from "react";
import {
    Button,
    ButtonGroup,
    ButtonOr,
    Checkbox,
    Divider,
    Dropdown,
    Form,
    Icon,
    Input,
    Label,
    Popup,
    Segment
} from "semantic-ui-react";
import ReactTable from "react-table";
import {ConfigContext} from "../../context/ConfigContext";
import {backend} from "../../../../xconvert-backend";
import {authentication} from "../../../../authentication";
import {ftp} from "../../../../xconvert-ftp";
import {formatDate} from "../../../../format";
import {FtpExplorerFileModal} from "./modals/FtpExplorerFileModal";
import {IfBox} from "../../../style/if";
import {FtpExplorerFileDeleteModal} from "./modals/FtpExplorerFileDeleteModal";
import {FtpExplorerFileMoveModal} from "./modals/FtpExplorerFileMoveModal";
import {
    DownloadFilesAsZipRequest,
    FileSystemEntry,
    ListFilesResponse,
    OpenFileRequest
} from "../../../../generated-ftp/api";
import Notifications, {notify} from 'react-notify-toast';
import {FtpExplorerAddFileModal} from "./modals/FtpExplorerAddFileModal";
import {FtpExplorerUploadFileModal} from "./modals/FtpExplorerUploadModal";
import {FtpExplorerDownloadFileModal} from "./modals/FtpExplorerDownloadFileModal";
import {FtpExplorerMultipleFileMoveModal} from "./modals/FtpExplorerMultipleFileMoveModal";
import {FtpExplorerRenameFileModal} from "./modals/FtpExplorerRenameFileModal";
import {FtpExplorerMultiFileDeleteModal} from "./modals/FtpExplorerMultiFileDeleteModal";
import * as moment from "moment";
import {CustomDateRangePicker} from "../../../util/dateRangePicker/CustomDateRangePicker";
import {DateRangeStr} from "../transportOrders/TransportOrderFilterComponent";
import {DateRange} from "../transportOrders/DepotStockFilterComponent";
import {getNextWorkingDay} from "../../../../utils";
import {FaFileImport} from "react-icons/fa6";
import {FileWithState, uploadedFiles} from "../../../util/FileUploadMultiple";

const swaggerFtp = require("../../../../../../swagger-ftp.json")


export interface FtpExplorerState {
    //essentials
    response: ListFilesResponse | null,
    isLoading: boolean,

    //Table
    take: number,
    page: number,
    pages: number,
    sortBy: 'DIRECTORY' | 'NAME' | 'SIZE' | 'CREATED' | 'MODIFIED',
    sortDirection: "ASC" | "DESC",

    //open
    openFile: boolean,
    //fileToOpen: null,
    fileToOpenBuffer: ArrayBuffer | null,
    fileToOpenName: string | null,

    //delete
    openFileDeleteView: boolean,
    openMultiFileDeleteView: boolean,
    fileToDelete: FileSystemEntry | null,

    //move
    openFileMoveView: boolean,
    fileToMove: FileSystemEntry | null,

    //add
    showAddFileModal: boolean,

    //upload
    showUploadFileModal: boolean,

    //download
    openFileDownloadModal: boolean,
    fileToDownload: ArrayBuffer | null,
    fileEntryToDownload: FileSystemEntry | null,
    deleteFileAfterwards: boolean

    //download multiple
    openFilesDownloadModal: boolean,

    selectedFiles: string[],
    showMoveMultipleFilesModal: boolean,

    renameFile: FileSystemEntry | null,
    showRenameModal: boolean,

    //search
    queryContent: boolean,

    ftpRegex: string

    // timeRangePicker
    fromDateTime?: Date
    toDateTime?: Date
    dateType: 'CREATED' | 'MODIFIED',
    dateRangeSelection?: DateRangeStr,
}

export class FtpExplorer extends React.Component
    <any, FtpExplorerState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading FtpExplorer")

        this.state = {
            //essentials
            response: null,
            isLoading: true,

            //Table
            take: 25,
            page: 0,
            pages: -1,
            sortBy: "CREATED",
            sortDirection: "DESC",

            //open
            openFile: false,
            //fileToOpen: null,
            fileToOpenBuffer: null,
            fileToOpenName: null,

            //delete
            openFileDeleteView: false,
            openMultiFileDeleteView: false,
            fileToDelete: null,

            //move
            openFileMoveView: false,
            fileToMove: null,

            //add
            showAddFileModal: false,

            //upload
            showUploadFileModal: false,

            //download
            openFileDownloadModal: false,
            fileToDownload: null,
            fileEntryToDownload: null,
            deleteFileAfterwards: false,

            //download multiple
            openFilesDownloadModal: false,

            selectedFiles: [],
            showMoveMultipleFilesModal: false,

            renameFile: null,
            showRenameModal: false,

            //search
            queryContent: false,
            ftpRegex: "",

            // timeRangePicker
            fromDateTime: undefined,
            toDateTime: undefined,
            dateType: 'CREATED',
            dateRangeSelection: "selection",
        }
    }

    async openDir(path: string = this.context.ftpPath, page: number = this.state.page) {
        this.setState({isLoading: true})

        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let p = page
        if (p < 0) {
            p = 0
        }
        if (path == null) {
            path = "/"
        }
        let fromTime = this.state.fromDateTime
        let toTime = this.state.toDateTime
        let useModifiedTime = this.state.dateType == 'MODIFIED'

        let response = (await ftp.ftpApi.listFiles(
                this.context.companyId,
                fromTime,
                p,
                path,
                this.context.ftpRegex,
                this.state.queryContent,
                this.state.sortBy,
                this.state.sortDirection,
                this.state.take,
                toTime,
                useModifiedTime,
                auth
            )
        )

        console.log("setting path to : ", path)
        this.context.setFtpPath(path)
        this.setState({
            response: response,
            isLoading: false,
            selectedFiles: []
        }, () => {
            // to refresh the shown page in ReactTable, when changing the page from the outside (like after search, going to page 1)
            let div = document.getElementsByClassName("-pageJump")[0]
            let child = div?.childNodes[0] as HTMLInputElement
            child?.focus()
            child?.blur()
        })


    }

    buildPathForApi(path: string) {
        return "?path=" + path + "&companyId=" + this.context.companyId
    }

    async componentDidMount() {
        this.setState({ftpRegex: this.context.ftpRegex})
        this.openDir(this.context.ftpPath)
    }

    changePage(pageIndex: number) {
        this.setState({page: pageIndex})
        this.openDir(this.context.ftpPath, pageIndex)
    }

    changePageSize(newSize: number) {
        this.setState({take: newSize, response: null})
        this.openDir(this.context.ftpPath)
    }

    changeSort(sortBy: 'DIRECTORY' | 'NAME' | 'SIZE' | 'CREATED' | 'MODIFIED' | 'NONE') {

        if (sortBy == 'NONE') {
            return
        }
        if (this.state.sortBy == sortBy) {
            //change direction
            let direction = this.state.sortDirection
            if (direction == "ASC") {
                direction = "DESC"
            } else {
                direction = "ASC"
            }
            this.setState({sortDirection: direction})
        } else {
            //change sortBy and reset direction
            this.setState({sortBy: sortBy, sortDirection: "ASC"})
        }

        //refresh
        this.openDir(this.context.ftpPath)
    }

    beautifyPathView() {
        let path = this.context.ftpPath
        if (path.startsWith("//ROOT") || path.startsWith("/ROOT")) {
            return <Label as='a'>
                <Label.Detail>Customer
                    Folder: {path.substring(path.lastIndexOf("ROOT") + 4, path.length)}</Label.Detail>
            </Label>
        } else {
            return <Label as='a'>
                <Label.Detail>{this.context.ftpPath}</Label.Detail>
            </Label>

        }
    }

    selectAllFiles(files) {

        if (this.state.selectedFiles.length != files.length) {
            let allFileNames = files.map((file) => file.name)
            this.setState({selectedFiles: allFileNames})
        } else {
            this.setState({selectedFiles: []})
        }
    }

    moveSelectedFiles() {
        console.log(this.state.selectedFiles)
        this.setState({showMoveMultipleFilesModal: true})
    }

    resetFilters() {
        this.context.setFtpRegex(null)
        this.setState({
            ftpRegex: "",
            dateRangeSelection: "selection",
            fromDateTime: undefined,
            toDateTime: undefined,
            dateType: "CREATED"
        })
        this.openDir()
    }

    onDatesSelected = (startDate?: moment.Moment, endDate?: moment.Moment) => {
        this.setState({
                fromDateTime: startDate?.toDate(),
                toDateTime: endDate?.toDate(),
                dateRangeSelection: "selection"
            }
        )
    }

    onPredefinedDateRangeSelect = (evt: any, data: any) => {
        const dateRange: DateRange = this.calculateDateRange(data.value)

        this.setState({
            fromDateTime: dateRange.start,
            toDateTime: dateRange.end,
            dateRangeSelection: dateRange.selectorString as DateRangeStr,
        })
    }

    getLastWorkingDay() {
        let workDay = moment().subtract(1, "days")
        if (workDay.day() === 0) workDay.subtract(2, "days")
        else if (workDay.day() == 6) workDay.subtract(1, "days")

        return workDay
    }

    calculateDateRange(dateRangeStr?: string): DateRange {
        let dateRange = {} as DateRange

        switch (dateRangeStr) {
            case "selection":
                if (this.state.fromDateTime)
                    dateRange.start = this.state.fromDateTime
                if (this.state.toDateTime)
                    dateRange.end = this.state.toDateTime
                dateRange.selectorString = "selection"
                return dateRange
            case "nextWorkingDay":
                dateRange.start = getNextWorkingDay().startOf("day").toDate()
                dateRange.end = getNextWorkingDay().endOf("day").toDate()
                dateRange.selectorString = "nextWorkingDay"
                break;
            case "tomorrow":
                dateRange.start = moment().add(1, "days").startOf("day").toDate()
                dateRange.end = moment().add(1, "days").endOf("day").toDate()
                dateRange.selectorString = "tomorrow"
                break;
            case "today":
                dateRange.start = moment().startOf("day").toDate()
                dateRange.end = moment().endOf("day").toDate()
                dateRange.selectorString = "today"
                break;
            case "lastWorkingDay":
                dateRange.start = this.getLastWorkingDay().startOf("day").toDate()
                dateRange.end = this.getLastWorkingDay().endOf("day").toDate()
                dateRange.selectorString = "lastWorkingDay"
                break;
            case "yesterday":
                dateRange.start = moment().subtract(1, "days").startOf("day").toDate()
                dateRange.end = moment().subtract(1, "days").endOf("day").toDate()
                dateRange.selectorString = "yesterday"
                break;
            case "nextWeek":
                dateRange.start = moment().add(1, "weeks").startOf("week").toDate()
                dateRange.end = moment().add(1, "weeks").endOf("week").toDate()
                dateRange.selectorString = "nextWeek"
                break;
            case "thisWeek":
                dateRange.start = moment().subtract(0).startOf("week").toDate()
                dateRange.end = moment().subtract(0).endOf("week").toDate()
                dateRange.selectorString = "thisWeek"
                break;
            case "lastWeek":
                dateRange.start = moment().subtract(1, "weeks").startOf("week").toDate()
                dateRange.end = moment().subtract(1, "weeks").endOf("week").toDate()
                dateRange.selectorString = "lastWeek"
                break;
            case "thisMonth":
                dateRange.start = moment().subtract(0).startOf("month").toDate()
                dateRange.end = moment().subtract(0).endOf("month").toDate()
                dateRange.selectorString = "thisMonth"
                break;
            case "lastMonth":
                dateRange.start = moment().subtract(1, "months").startOf("month").toDate()
                dateRange.end = moment().subtract(1, "months").endOf("month").toDate()
                dateRange.selectorString = "lastMonth"
                break;
            case "last7days":
                dateRange.start = moment().subtract(6, "days").startOf("day").toDate()
                dateRange.end = moment().endOf("day").toDate()
                dateRange.selectorString = "last7days"
                break;
            case "last30days":
                dateRange.start = moment().subtract(29, "days").startOf("day").toDate()
                dateRange.end = moment().endOf("day").toDate()
                dateRange.selectorString = "last30days"
                break;
            default:
                dateRange.start = undefined
                dateRange.end = undefined

                return dateRange
        }

        return dateRange
    }

    renderDateRangePickerField() {
        const options = [
            {
                key: 'selection',
                text: 'Selection',
                value: 'selection'
            },
            {
                key: 'nextWorkingDay',
                text: 'NextWorkingDay',
                value: 'nextWorkingDay'
            },
            {
                key: 'tomorrow',
                text: 'Tomorrow',
                value: 'tomorrow'
            },
            {
                key: 'today',
                text: 'Today',
                value: 'today'
            },
            {
                key: 'lastWorkingDay',
                text: 'LastWorkingDay',
                value: 'lastWorkingDay'
            },
            {
                key: 'yesterday',
                text: 'Yesterday',
                value: 'yesterday'
            },
            {
                key: 'nextWeek',
                text: 'NextWeek',
                value: 'nextWeek'
            },
            {
                key: 'thisWeek',
                text: 'ThisWeek',
                value: 'thisWeek'
            },
            {
                key: 'lastWeek',
                text: 'LastWeek',
                value: 'lastWeek'
            },
            {
                key: 'thisMonth',
                text: 'ThisMonth',
                value: 'thisMonth'
            },
            {
                key: 'lastMonth',
                text: 'LastMonth',
                value: 'lastMonth'
            },
            {
                key: 'last7days',
                text: 'Last7Days',
                value: 'last7days'
            },
            {
                key: 'last30days',
                text: 'Last30Days',
                value: 'last30days'
            },
            {key: 'all', text: 'All', value: 'all'}
        ]
        const options2 = [
            {
                key: 'created',
                text: 'Created',
                value: 'CREATED'
            },
            {
                key: 'updated',
                text: 'Updated',
                value: 'MODIFIED'
            }
        ]
        let fromDate = this.state.fromDateTime ? moment(this.state.fromDateTime) : undefined;
        let toDate = this.state.toDateTime ? moment(this.state.toDateTime) : undefined;
        return <Form.Field>
            <div className="ui basic buttons">
                <Dropdown
                    selectOnBlur={false}
                    className="ui left"
                    basic button floating
                    value={this.state.dateType}
                    options={options2}
                    onChange={(evt: any, data: any) => {
                        this.setState({dateType: data.value})
                    }}
                />
                <div
                    className={"ui basic button"}
                    style={{padding: '0px'}}
                >
                    <CustomDateRangePicker
                        id={"FtpExplorerFilter"}
                        startDate={fromDate}
                        endDate={toDate}
                        onDatesSelected={this.onDatesSelected}
                    />
                </div>
                <Dropdown
                    selectOnBlur={false}
                    className="ui" basic button floating
                    value={this.state.dateRangeSelection}
                    options={options}
                    onChange={this.onPredefinedDateRangeSelect}
                />
                <Button icon
                        onClick={() => {
                            this.changePage(1)

                        }}><Icon name='search'/></Button>
                <br/>
            </div>
        </Form.Field>
    }

    renderButtons() {
        return <>
            <ButtonGroup>
                <Button icon disabled={this.context.ftpPath == "/"} onClick={() => this.goUpADir()}>
                    <Icon name='reply'/>
                </Button>
                <Button icon onClick={() => this.openDir(this.context.ftpPath)}><Icon name='refresh'/></Button>
                <Button icon disabled={this.context.ftpPath == "/"}
                        onClick={() => this.setState({showAddFileModal: true})}><Icon name='plus'/></Button>
                <Button icon disabled={this.context.ftpPath == "/"}
                        onClick={() => this.setState({showUploadFileModal: true})}><Icon
                    name='upload'/></Button>
                <Button icon disabled={this.state.selectedFiles.length == 0}
                        onClick={() => this.downloadAsZip()}><Icon name='download'/></Button>
                <Button icon disabled={this.state.selectedFiles.length == 0}
                        onClick={() => this.deleteMultipleFiles()}><Icon name='trash'/></Button>
                <Popup content={"open in importSim"} trigger={
                    <Button
                        icon
                        style={{height: 36}}
                        disabled={this.state.selectedFiles.length == 0}
                        onClick={() => this.openInImportSim()}>
                        <FaFileImport/>
                    </Button>
                }/>
            </ButtonGroup>
            <Input style={{width: 220}} placeholder='term'
                   value={this.state.ftpRegex}
                   onChange={(evt) => {
                       this.setState({ftpRegex: evt.target.value})
                       this.context.setFtpRegex(evt.target.value)
                   }}
            />
            <ButtonGroup>
                <Button icon disabled={
                    this.context.ftpRegex == null
                }
                        onClick={() => {
                            this.changePage(1)

                        }}><Icon name='search'/></Button>
                <Button
                    onClick={() => this.setState({queryContent: false})}
                    positive={!this.state.queryContent}
                >search for name</Button>
                <ButtonOr/>
                <Button
                    onClick={() => this.setState({queryContent: true})}
                    positive={this.state.queryContent}
                >search for content</Button>
            </ButtonGroup>
        </>

    }

    render() {
        const buttonStyle = {} as React.CSSProperties
        buttonStyle.backgroundColor = '#4CAF50'
        buttonStyle.border = 'none'
        buttonStyle.color = 'white'
        buttonStyle.padding = '9px 20px'
        buttonStyle.textAlign = 'center'
        buttonStyle.textDecoration = 'none'
        buttonStyle.display = 'inline-block'
        buttonStyle.fontSize = '15px'
        buttonStyle.borderRadius = '5px'


        console.log("RENDER FTP-EXPLORER")
        if (this.state.response) {
            let files: FileSystemEntry[] = this.state.response.files
            let filesWithoutDirs = files.filter((file) => !file.isDirectory)
            try {

                return <Segment className={"flex3"}>
                    <div>

                        {this.beautifyPathView()}
                        <Divider hidden/>

                        {this.renderButtons()}

                        <br/>
                        {this.renderDateRangePickerField()}


                        <Button
                            disabled={
                                this.context.ftpRegex == null &&
                                this.state.fromDateTime == undefined &&
                                this.state.toDateTime == undefined
                            }
                            onClick={() => this.resetFilters()}
                        >
                            Reset
                        </Button>

                        <Divider hidden/>

                        Select all -
                        <Checkbox
                            readOnly={filesWithoutDirs.length == 0}
                            checked={this.state.selectedFiles.length == filesWithoutDirs.length && filesWithoutDirs.length > 0}
                            onChange={() => this.selectAllFiles(filesWithoutDirs)}
                        />
                        &emsp;
                        <Button icon
                                disabled={this.context.ftpPath == "/" || this.state.selectedFiles.length == 0}
                                onClick={() => this.moveSelectedFiles()}>
                            <Icon name='move'/>
                        </Button>
                        <Divider hidden/>
                    </div>

                    {this.renderTable(files)}
                    {this.renderModals()}
                    <Notifications/>

                </Segment>
            } catch (ex) {
                return <Label color='red'>The data is in bad format: {JSON.stringify(files, null, 4)}</Label>
            }
        } else {
            return <Label color='yellow'>Loading...</Label>
        }


    }

    switchSelectionStateOfItem(name: string) {
        this.setState({isLoading: true})

        var array = [...this.state.selectedFiles]; // make a separate copy of the array
        var index = array.indexOf(name)
        if (index == -1) {
            // name not found, adding Id

            this.setState({selectedFiles: array.concat(name)})
            console.log("name added")


        } else {
            // name found, removing it

            array.splice(index, 1);
            this.setState({selectedFiles: array})
            console.log("name removed")

        }
        this.setState({isLoading: false})
    }

    generateLink(fileName: string) {
        let baseLink = swaggerFtp.info.contact.url + "/ftp/files/"
        let path = this.context.ftpPath.split("\/").join("%2F")
        return baseLink + fileName + "?path=" + path
    }

    openFileInNewTab(fileEntry: FileSystemEntry) {
        let link = this.generateLink(fileEntry.name)
        const newWindow = window.open(link, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    createTableColumns() {


        return [{
            id: 'select',
            Header: 'Select',
            width: 70,
            accessor: (d: any) => {
                if (this.context.ftpPath != "/" && !d.isDirectory) {
                    return <Checkbox
                        checked={this.state.selectedFiles.some(name => name === d.name)}
                        onChange={() => this.switchSelectionStateOfItem(d.name)}
                    />
                } else {
                    return null
                }
            },
            sortField: 'NONE'
        }, {
            id: 'dir',
            Header: 'Dir',
            width: 40,
            accessor: (d: any) => {
                if (d.isDirectory) {
                    return <Icon name='folder'/>
                } else {
                    return <Icon name='file outline'/>
                }
            }, // when changing the Folder-Icon, make sure you also change it in the ReactTable in the click desicion
            sortField: 'DIRECTORY'

        }, {
            id: 'name',
            Header: 'Name',
            width: 350,
            accessor: (d: any) => d.name,
            sortField: 'NAME'

        }, {
            id: 'size',
            Header: 'Size',
            width: 100,
            accessor: (d: any) => {
                if (d.isDirectory) {
                    return <p/>
                } else {
                    return this.getReadableFileSizeString(d.size)
                }
            },
            sortField: 'SIZE'

        }, {
            id: 'created',
            Header: 'Created',
            width: 150,
            accessor: (d: any) => d.created,
            Cell: (d: any) => {
                return <span>{formatDate(d.value)}</span>
            },
            sortField: 'CREATED'

        }, {
            id: 'updated',
            Header: 'Updated',
            width: 150,
            accessor: (d: any) => d.updated,
            Cell: (d: any) => {
                return <span>{formatDate(d.value)}</span>
            },
            sortField: 'MODIFIED'

        },
            {
                id: 'move',
                Header: 'Move',
                width: 70,
                accessor: (d: any) => <Button icon
                                              disabled={this.context.ftpPath == "/"}
                                              onClick={() => this.moveFile(d)}>
                    <Icon name='move'/>
                </Button>,
                sortField: 'NONE'
            },
            {
                id: 'delete',
                Header: 'Delete',
                width: 70,
                accessor: (d: any) => <Button icon
                                              disabled={this.context.ftpPath == "/"}
                                              onClick={() => this.deleteFile(d)}>
                    <Icon name='trash'/>
                </Button>,
                sortField: 'NONE'
            },
            {
                id: 'download',
                Header: 'Download',
                width: 70,
                accessor: (d: any) => <Button icon
                                              disabled={this.context.ftpPath == "/"}
                                              onClick={() => this.downloadFile(d, d.isDirectory)}>
                    <Icon name='download'/>
                </Button>,
                sortField: 'NONE'
            },
            {
                id: 'link',
                Header: 'link',
                width: 70,
                accessor: (d: any) => <Button icon
                                              disabled={this.context.ftpPath == "/" || d.isDirectory}
                                              onClick={() => this.openFileInNewTab(d)}>
                    <Icon name='linkify'/>
                </Button>,
                sortField: 'NONE'
            },
            {
                id: 'rename',
                Header: 'rename',
                width: 70,
                accessor: (d: any) => <Button icon
                                              disabled={d.isDirectory}
                                              onClick={() => this.setState({renameFile: d, showRenameModal: true})}>
                    <Icon name='pencil'/>
                </Button>,
                sortField: 'NONE'
            }]

    }

    renderTable(files) {
        const columns = this.createTableColumns()

        console.log("PAGE: ", this.state.page)
        return <ReactTable
            data={files}
            manual
            page={this.state.page}
            pages={Math.ceil(this.state.response.totalFiles / this.state.take)}
            pageSizeOptions={[25, 50, 100, 250, 500, 1000]}
            columns={columns}
            onSortedChange={(newSorted, column, shiftKey) => {
                this.changeSort(column.sortField)
            }}
            pageSize={this.state.take}
            defaultPageSize={this.state.take}
            className="-striped -highlight"
            loading={this.state.isLoading}
            getTdProps={(state, rowInfo, column, instance) => {
                return {
                    onClick: () => {
                        if (rowInfo) {
                            if (column.id != 'move'
                                && column.id != 'delete'
                                && column.id != 'download'
                                && column.id != 'select'
                                && column.id != 'rename'
                                && column.id != 'pdfView'
                            ) {
                                if (rowInfo.row.dir.props.name == 'folder') {
                                    console.log("Opening Dir: ", rowInfo.row.name)
                                    this.goDownADir(rowInfo.row.name)
                                } else {
                                    console.log("Opening File: ", rowInfo.row.name)
                                    this.openFile(rowInfo.row.name)
                                }
                            }
                        }
                    }
                };
            }}
            style={{cursor: "pointer"}}
            onPageChange={(pageIndex) => this.changePage(pageIndex)}
            onPageSizeChange={(pageSize, pageIndex) => this.changePageSize(pageSize)}
        />
    }

    async downloadFile(fileEntry: FileSystemEntry, deleteFileAfterwards: boolean = false) {

        let file = await (await this.getFile(fileEntry.name)).arrayBuffer()
        if (fileEntry.isDirectory) {
            fileEntry.name = fileEntry.name + ".zip"
        }
        this.setState({
            fileToDownload: file,
            fileEntryToDownload: fileEntry,
            openFileDownloadModal: true,
            deleteFileAfterwards: deleteFileAfterwards
        })
        return file
    }

    async downloadAsZip(path: string = this.context.ftpPath, files: string[] = this.state.selectedFiles) {

        //this.setState({ isLoading: true })
        let downloadFilesRequest = {} as DownloadFilesAsZipRequest
        downloadFilesRequest.fileNames = files


        let auth = backend.withTokenAuthHeader(authentication.token)
        let response = (await ftp.ftpApi.createZip(path, downloadFilesRequest, this.context.companyId, auth))


        return await this.downloadFile(response.file, true)

    }

    deleteFile(file: FileSystemEntry) {
        this.setState({fileToDelete: file, openFileDeleteView: true})
    }

    deleteMultipleFiles() {
        this.setState({openMultiFileDeleteView: true})

    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    goto(url) {
        console.log("GOTO: ", this.context.companyId + url)
        this.props.history.push(this.context.companyId + url)
    }

    async openInImportSim() {

        let files: any[] = []
        for (const f of this.state.selectedFiles) {
            files.push({
                file: await this.readFile(f),
                fileName: f,
                state: "NOT_PROCESSED"
            } as FileWithState)
        }

        uploadedFiles.value = files

        // setting tab to importSim
        this.context.tabSession.converterTab = 1 // 1 = importSim
        this.context.tabSession.importSimTab = 1 // 1 = file

        // moving to convPage
        let url = "/" + this.context.companyId + "/convPage"
        this.props.history.push(url)

    }

    moveFile(file: FileSystemEntry) {
        this.setState({fileToMove: file, openFileMoveView: true})

    }

    goDownADir(newDir: string) {
        console.log("NewDir: ", newDir)

        console.log("state.path: ", this.context.ftpPath)
        this.openDir(this.context.ftpPath + "/" + newDir)
    }

    goUpADir() {

        let path = this.context.ftpPath
        if (path != "/") {
            let newPath = path.substring(0, path.lastIndexOf("/"))
            this.openDir(newPath)
        } else {
            console.log("cannot go up further")
        }
    }

    getReadableFileSizeString(fileSizeInBytes) {
        var i = -1;
        var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
            fileSizeInBytes = fileSizeInBytes / 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    }

    async openFile(fileName: string) {
        console.log("openFile: ", fileName)

        let response = await (await this.getFile(fileName))

        this.setState({openFile: true, fileToOpenBuffer: await response.arrayBuffer(), fileToOpenName: fileName})
    }

    async readFile(fileName: string) {
        let response = await this.getFile(fileName)
        return new Blob([await response.arrayBuffer()], {type: 'application/pdf'})
    }

    async getFile(fileName: string) {
        console.log("getFile: ", fileName)

        let path = this.context.ftpPath
        let request = {} as OpenFileRequest
        request.fileName = fileName

        let auth = backend.withTokenAuthHeader(authentication.token)

        let pathParameter = this.buildPathForApi(path)
        return await this.openFileFetchCall(pathParameter, request, auth)
    }

    async openFileFetchCall(pathParameter: string, request: OpenFileRequest, options?: any) {
        console.log("/ftp/openFile Request: ", request)

        return await fetch('/ftp/openFile' + pathParameter, {
            method: 'POST',
            headers: {
                "Authorization": options.headers.Authorization,
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(request)
        })
    }

    private renderModals() {
        return <>
            <IfBox shouldShow={this.state.openFile}>
                <FtpExplorerFileModal
                    fileBuffer={this.state.fileToOpenBuffer}
                    isOpen={this.state.openFile}
                    onClose={() => {
                        this.setState({fileToOpenBuffer: null, openFile: false})
                    }}
                    fileName={this.state.fileToOpenName}
                />
            </IfBox>
            <IfBox shouldShow={this.state.openFileDeleteView}>
                <FtpExplorerFileDeleteModal
                    file={this.state.fileToDelete}
                    isOpen={this.state.openFileDeleteView}
                    path={this.context.ftpPath}
                    onClose={(success) => {
                        if (success == true) {
                            notify.show('File has been deleted', 'success', 3000, '#28f751');
                        }
                        this.setState({fileToDelete: null, openFileDeleteView: false})
                        this.openDir(this.context.ftpPath)
                    }}
                />
            </IfBox>
            <IfBox shouldShow={this.state.openMultiFileDeleteView}>
                <FtpExplorerMultiFileDeleteModal
                    files={this.state.selectedFiles}
                    isOpen={this.state.openMultiFileDeleteView}
                    path={this.context.ftpPath}
                    onClose={(success) => {
                        if (success == true) {
                            notify.show('File has been deleted', 'success', 3000, '#28f751');
                        }
                        this.setState({selectedFiles: [], openMultiFileDeleteView: false})
                        this.openDir(this.context.ftpPath)
                    }}
                />
            </IfBox>
            <IfBox shouldShow={this.state.openFileMoveView}>
                <FtpExplorerFileMoveModal
                    file={this.state.fileToMove}
                    isOpen={this.state.openFileMoveView}
                    sourcePath={this.context.ftpPath}
                    onClose={
                        (success) => {
                            if (success == true) {
                                notify.show('File has been moved', 'success', 3000, '#28f751');
                            }
                            this.setState({fileToMove: null, openFileMoveView: false})
                            this.openDir(this.context.ftpPath)
                        }
                    }
                    sortBy={this.state.sortBy}
                    sortDirection={this.state.sortDirection}
                />
            </IfBox>
            <IfBox shouldShow={this.state.showAddFileModal}>
                <FtpExplorerAddFileModal
                    isOpen={this.state.showAddFileModal}
                    onClose={(success) => {
                        if (success == true) {
                            notify.show('File has been added', 'success', 3000, '#28f751');
                        }
                        this.setState({showAddFileModal: false}),
                            this.openDir(this.context.ftpPath)
                    }}
                    path={this.context.ftpPath}
                    fileName={""}
                    contentBase64={null}
                    content=""
                    encoding="UTF-8" // maybe make it changable for the user???
                />
            </IfBox>
            <IfBox shouldShow={this.state.showUploadFileModal}>
                <FtpExplorerUploadFileModal
                    isOpen={this.state.showUploadFileModal}
                    onClose={(success) => {
                        if (success == true) {
                            notify.show('File has been added', 'success', 3000, '#28f751');
                        }
                        this.setState({showUploadFileModal: false}),
                            this.openDir(this.context.ftpPath)
                    }}
                    path={this.context.ftpPath}/>
            </IfBox>
            <IfBox shouldShow={this.state.openFileDownloadModal}>
                <FtpExplorerDownloadFileModal
                    isOpen={this.state.openFileDownloadModal}
                    onClose={() => {
                        this.setState({openFileDownloadModal: false}),
                            this.openDir(this.context.ftpPath)
                    }}
                    path={this.context.ftpPath}
                    fileEntry={this.state.fileEntryToDownload}
                    file={this.state.fileToDownload}
                    deleteFileAfterwards={this.state.deleteFileAfterwards}/>
            </IfBox>
            <IfBox shouldShow={this.state.showMoveMultipleFilesModal}>
                <FtpExplorerMultipleFileMoveModal
                    isOpen={this.state.showMoveMultipleFilesModal}
                    sourcePath={this.context.ftpPath}
                    onClose={
                        (success) => {
                            if (success == true) {
                                notify.show('Files has been moved', 'success', 3000, '#28f751');
                            }
                            this.setState({selectedFiles: [], showMoveMultipleFilesModal: false})
                            this.openDir(this.context.ftpPath)
                        }
                    }
                    sortBy={this.state.sortBy}
                    sortDirection={this.state.sortDirection}
                    files={this.state.selectedFiles}/>
            </IfBox>
            <IfBox shouldShow={this.state.showRenameModal}>
                <FtpExplorerRenameFileModal
                    isOpen={this.state.showRenameModal}
                    onClose={
                        (change) => {
                            if (change == true) {
                                notify.show('File has renamed', 'success', 3000, '#28f751');
                            }
                            this.setState({showRenameModal: false})
                            this.openDir(this.context.ftpPath)
                        }
                    }
                    path={this.context.ftpPath}
                    file={this.state.renameFile}/>
            </IfBox>

        </>
    }
}
