import * as React from "react";
import {useEffect} from "react";
import playground from "kotlin-playground"
import {v4 as uuid} from 'uuid';
import {Button} from "semantic-ui-react";
import {updateConfig, workingConfig} from "../../main/pages/configChange/ConfigSignal";
import {ScriptWithPath} from "./KotlinScriptIde";

export interface CustomKotlinPlaygroundProps {
    preBoilerplate?: string
    postBoilerplate?: string
    imports?: string
    scriptWithPath: ScriptWithPath
    directSave?: boolean
}

export function CustomKotlinPlayground(props: React.PropsWithChildren<CustomKotlinPlaygroundProps>) {

    // ################################
    // STATE
    // ################################
    const [currentCodeForManualSave, setCurrentCodeForManualSave] = React.useState(props.scriptWithPath.script)
    const [initiated, setInitiated] = React.useState(false)
    let converted = false


    // ################################
    // USE EFFECT
    // ################################
    useEffect(() => {
        document.addEventListener('DOMContentLoaded', () => {
            playgroundConversion()
        })

        return () => {
            document.removeEventListener('DOMContentLoaded', () => {
                playgroundConversion()
            })
        }

    }, [props.scriptWithPath.path]);

    // Listener for playgrounds that exist from the start (DOMContentLoaded)
    useEffect(() => {
        console.log("loading playgrounds")
        setInitiated(false)
        document.addEventListener('DOMContentLoaded', () => {
            playgroundConversion()
        })
        return () => {
            document.removeEventListener('DOMContentLoaded', () => {
                playgroundConversion()
            })
        }

    }, [])


    // ################################
    // OBSERVER
    // ################################

    // Observer for playgrounds that are added after DOMContentLoaded
    const initObserver = new MutationObserver(mutationCallbackPlaygroundInitialization);

    // Start observing the document body for added nodes
    initObserver.observe(document.body, {
        childList: true,
        subtree: true
    });

    // Callback function for the MutationObserver
    function mutationCallbackPlaygroundInitialization(mutationsList: any) {
        for (let mutation of mutationsList) {

            if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                mutation.addedNodes.forEach((node: {
                    nodeType: number;
                    classList: { contains: (arg0: string) => any; };
                    querySelectorAll: (arg0: string) => any[];
                }) => {
                    if (node.nodeType === 1) { // Element node
                        // Check if the node itself has the specific class
                        if (node.classList.contains('kotlin-code')) {
                            playgroundConversion()
                        }
                        // Check if any of the progeny have the specific class
                        node.querySelectorAll('.kotlin-code').forEach(element => {
                            playgroundConversion()
                        });
                    }
                });
            }
        }
    }

    // to Convert divs with class "kotlin-code" to Kotlin Playground
    function playgroundConversion() {

        if (!converted) {
            converted = true
            const options = {
                //server: 'http://localhost:8080',
                server: 'https://kcs.alpha.logenios.com',
                onChange: onChange,
            };

            playground("code", options)
        }
    }


    // ################################
    // LOGIC
    // ################################

    function saveNewScript(newScript: string | null) {
        let completeScript = newScript
        if (props.imports.length > 0) {
            completeScript = props.imports + "\n" + newScript
        }

        console.log("saveNewScript: ", completeScript)
        const jp = require('jsonpath');
        let config = JSON.parse(workingConfig.value)

        if (completeScript === null && jp.value(config, props.scriptWithPath.path) == null) {
            console.log("saveNewScript: null stays null without potential creation of parent objects")
            return
        }

        jp.value(config, props.scriptWithPath.path, completeScript)
        updateConfig(
            JSON.stringify(config, null, 4),
            true,
            false
        ).then(r => {
        })
    }


    function onChange(value: string) {

        if (initiated) {
            let cleanValue = cleanBoilerplateCode(value)
            console.log("onChange: ", cleanValue)
            if (cleanValue === "t/" || cleanValue === "" || cleanValue === null) {
                //saveNewScript(null)
                return
            }
            if (props.directSave) {
                saveNewScript(cleanValue)
            } else {
                setCurrentCodeForManualSave(cleanValue)
            }
        } else {
            console.log("onChange but not initiated, yet")
            setInitiated(true)
        }
    }

    function cleanBoilerplateCode(code: string) {

        let preBoilerplate = props.preBoilerplate.substring(1, props.preBoilerplate.length)
        console.log("cleanBoilerplateCode: code:", code)
        console.log("cleanBoilerplateCode: preBoilerplate:", preBoilerplate)
        let cleaned = code
            //.substring(1, code.length - 1)
            .replace(preBoilerplate, "")
            .replace(props.postBoilerplate, "")
            .trimStart()


        console.log("cleanBoilerplateCode: cleaned: ", cleaned)
        return cleaned
    }

    // ################################
    // RENDER
    // ################################

    function provideCurrentScript() {
        if (props.scriptWithPath.script) {
            console.log("provideCurrentScript: ", props.scriptWithPath.script)
            if (props.scriptWithPath.script === "t/") {
                console.log("provideCurrentScript: ", "t/")

                return ""
            }
            return " " + props.scriptWithPath.script + " "
        }
        console.log("provideCurrentScript: ", "")
        return ""
    }

    return <div
        id={"kotlin-playground"}
        key={uuid()}
        style={{
            paddingTop: 10,
            border: "1px solid #00a699",
            flex: "1",
        }}
    >
        {!props.directSave && <Button
            icon={"save"}
            onClick={() => {
                saveNewScript(currentCodeForManualSave)
            }}/>
        }
        <div
            className={"kotlin-code"}
        >
            <code
                id={"code"}
                style={{height: "100%"}}
                className={"hljs language-kotlin"}
                data-autocomplete={"true"}
                data-highlight-on-fly={"true"}
                match-brackets={"true"}
                {...{'theme': 'darcula'}}
            >
                {props.preBoilerplate}
                //sampleStart
                {provideCurrentScript()}
                //sampleEnd
                {props.postBoilerplate}
            </code>
        </div>
    </div>
}