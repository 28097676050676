import DownloadLink from "./index";
import * as React from "react";

export interface DownloadButtonProps {
    filename: any;
    exportFile: any;
}

export function DownloadButton(props: DownloadButtonProps) {

    const buttonStyle: React.CSSProperties = {
        backgroundColor: '#4CAF50',
        border: 'none',
        color: 'white',
        padding: '9px 20px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '15px',
        borderRadius: '5px'
    };

    return <DownloadLink
        tagName="button"
        label="Download"
        style={buttonStyle}
        filename={props.filename}
        exportFile={() => props.exportFile}
    />
}