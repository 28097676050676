import * as React from "react";
import {useContext} from "react";
import {ConfigContext} from "../../../../context/ConfigContext";
import {CustomerReportDynamicSection, healthyHighlighter} from "../CustomerReportDynamicSection";

export function CustomerReportQueueStatus() {
    const context = useContext(ConfigContext)
    const columns = [{
        id: 'name',
        Header: 'name',
        width: 300,
        accessor: (d: any) => d.name,
    }, {
        id: 'icon',
        Header: 'queue status',
        width: 100,
        accessor: (d: any) =>
            <div>{healthyHighlighter(d.unprocessedEventsOlderThanFiveMinutes == 0)}</div>,
    }, {
        id: 'unprocessedEventsOlderThanFiveMinutes',
        Header: 'unprocessed older 5 min.',
        width: 170,
        accessor: (d: any) => <div>{d.unprocessedEventsOlderThanFiveMinutes}</div>,
    }, {
        id: 'oldestUnprocessedEventFrom',
        Header: 'oldest From',
        width: 240,
        accessor: (d: any) => <div>{d.oldestUnprocessedEventFrom}</div>,
    },{
        id: 'responsibleManager',
        Header: 'Responsible manager',
        width: 100,
        accessor: (d: any) => d.responsibleManager,
    }
    ]
    return <CustomerReportDynamicSection columns={columns} data={context.customerReport?.queueStatus?.companies}/>

}