import * as React from "react";
import { Modal, Header, Button, Label } from "semantic-ui-react";
import { ConfigContext } from "../../../context/ConfigContext";
import { FtpExplorerShowFileSystemEntry } from "../FtpExplorerShowFileSystemEntry";
import { IfBox } from "../../../../style/if";
import { FileSystemEntry } from "../../../../../generated-ftp/api";
import DownloadLink from "../../../../util/react-download-link"
import { backend } from "../../../../../xconvert-backend";
import { authentication } from "../../../../../authentication";
import { ftp } from "../../../../../xconvert-ftp";

export interface FtpExplorerFileDownloadModalProps {
    isOpen: boolean
    onClose: (success: Boolean) => void
    file: any
    fileEntry: FileSystemEntry
    path: string,
    deleteFileAfterwards: boolean
}

export class FtpExplorerDownloadFileModal extends React.Component<FtpExplorerFileDownloadModalProps, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            response: null
        }
    }

    handleClose = async () => {

        this.props.onClose(false)
    }


    render() {


        const buttonStyle = {} as React.CSSProperties
        buttonStyle.backgroundColor = '#4CAF50'
        buttonStyle.border = 'none'
        buttonStyle.color = 'white'
        buttonStyle.padding = '9px 20px'
        buttonStyle.textAlign = 'center'
        buttonStyle.textDecoration = 'none'
        buttonStyle.display = 'inline-block'
        buttonStyle.fontSize = '15px'
        buttonStyle.borderRadius = '5px'

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='small'>
            <Header icon='browser' content='Download File' />
            <Modal.Content>
                <p>Downloading this file?</p>
                <FtpExplorerShowFileSystemEntry file={this.props.fileEntry} />
                <DownloadLink
                    tagName='button'
                    style={buttonStyle}
                    filename={this.props.fileEntry.name}
                    exportFile={() => { return this.props.file }}
                />
                <Button onClick={() => this.handleClose()}>close</Button>
                <IfBox shouldShow={this.state.response != null}>
                    <Label color='red'>{this.state.response}</Label>
                </IfBox>
            </Modal.Content>
        </Modal>
    }

}