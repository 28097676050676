import * as React from "react";
import {Button, Card, Grid, Header, Icon, Input, Message, Modal} from "semantic-ui-react";
import {
    QueuedEventConversionFileResult,
    TransportOrderConfigTest,
    TransportOrderConfigTestInputImport, TransportOrderConfigTestUpdate, UpdateTransportOrderConfigTestsRequest
} from "../../../../../generated";
import {ConfigContext} from "../../../context/ConfigContext";
import {ExportSimOutput} from "../ExportSim/output/ExportSimOutput";
import AceEditor from 'react-ace';
import DownloadLink from "../../../../util/react-download-link";
import {Base64} from 'js-base64';

import 'brace/mode/json'
import 'brace/theme/monokai'
import 'brace/ext/searchbox'
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {SimpleTextBox} from "../../../../util/SimpleTextBox";
import {DownloadButton} from "../../../../util/react-download-link/DownloadButton";

export interface TestModalProps {
    isOpen: boolean
    onClose: (success: boolean) => void
    test: TransportOrderConfigTest
}

export interface TestModalState {
    test: TransportOrderConfigTest
    input: string | null
    expected: string | null
    exportExpectedList: QueuedEventConversionFileResult[] | null
    fieldsToIgnore: string[]
    responseMessage: string | null
    responseSuccess: boolean
}


export class TestModal extends React.Component<TestModalProps, TestModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            test: null,
            input: null,
            expected: null,
            exportExpectedList: [],
            fieldsToIgnore: [],
            responseMessage: null,
            responseSuccess: false
        }
    }

    componentDidMount() {
        const test = this.props.test;

        if (test != null) {
            let fieldsToIgnore = test.expectedResult.fieldsToIgnore
            if (fieldsToIgnore == null) {
                fieldsToIgnore = []
            }
            if (test.type == "IMPORT") {
                this.setState({
                    test: test,
                    input: atob(test.input.importData.importDoc),
                    expected: JSON.stringify(test.expectedResult.importData, null, 4),
                    fieldsToIgnore: fieldsToIgnore
                })
            } else {
                let data = test.input.exportData.transportOrder
                if (data == null || data == "") {
                    data = test.input.exportData.queuedEvent
                }
                this.setState({
                    test: test,
                    input: JSON.stringify(data, null, 4),
                    exportExpectedList: test.expectedResult.exportDataList,
                    fieldsToIgnore: fieldsToIgnore
                })
            }

        }
    }

    handleClose = () => {
        this.props.onClose(false)
    }

    render() {
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="inverted"
            size='fullscreen'>
            <Header icon='browser' content='Test'/>
            <Modal.Content>

                {this.showResponseInfo()}
                {this.showTest()}


                <Button onClick={(evt) => this.handleClose()}>Close</Button>


            </Modal.Content>
        </Modal>
    }

    showResponseInfo() {
        if (this.state.responseMessage == null) {
            return <p/>
        }
        if (this.state.responseSuccess) {
            return <Message key={"test"}
                            success
                            content={this.state.responseMessage}
            />
        } else {
            return <Message key={"test"}
                            error
                            content={this.state.responseMessage}
            />
        }

    }

    showTest() {
        let test = this.state.test
        if (test != null) {
            return <>

                <Card
                    fluid
                    header={test.name}
                    meta={test.type}
                    description={<div>
                        Active: &emsp; &emsp; &emsp; &emsp; &emsp;{test.active}<br/>
                        Success: &emsp; &emsp; &emsp; &emsp; &emsp;{test.success}<br/>
                        Last Run: &emsp; &emsp; &emsp; &emsp; &emsp;{test.lastRun}<br/>
                        Last Successful Run: &emsp;{test.lastSuccessfulRun}<br/>
                        Created: &emsp; &emsp; &emsp; &emsp; &emsp;{test.created}<br/>
                        Modified: &emsp; &emsp; &emsp; &emsp; &emsp;{test.modified}<br/>
                        Expected Type: &emsp; &emsp; &emsp;{test.expectedResult.type}<br/>
                    </div>
                    }
                />


                {this.drawInputAndOutPut()}

            </>
        } else {
            return <p>{"error: test is null"}</p>
        }
    }

    drawFieldsToIgnore() {
        let index = -1
        return <p>
            <h3>Fields to ignore: </h3>
            {
                this.state.fieldsToIgnore?.map(f => {
                    index++
                    return this.drawSingleFieldToIgnore(f, index)

                })
            }
            <Button icon onClick={() => this.handleSaveFieldsToIgnore()}><Icon name='save'/></Button>
            <Button icon onClick={() => this.handleChangeOnFieldToIgnore("", index + 1)}><Icon name='plus'/></Button>
        </p>
    }

    async handleSaveFieldsToIgnore() {
        console.log("saving changes")
        let auth = (await backend.withTokenAuthHeader(authentication.token))


        let test = this.props.test

        let update = {} as TransportOrderConfigTestUpdate
        update.testId = test._id
        update.testName = test.name
        update.input = test.input
        update.expectedResult = test.expectedResult
        update.expectedResult.fieldsToIgnore = this.state.fieldsToIgnore

        let request = {} as UpdateTransportOrderConfigTestsRequest
        request.updates = []
        request.updates.push(update)


        let response = await backend.internalApi.updateTransportOrderConfigTests(request, auth)
        let message: string
        if (response.failedUpdateTestIds.length == 0) {

            message = 'updated fields to ignore'
        } else {
            message = "ERROR: unable to change fields to ignore. Please check logs."

        }
        this.setState({responseSuccess: response.failedUpdateTestIds.length == 0, responseMessage: message})

    }

    drawSingleFieldToIgnore(field, index) {
        return <p>
            <Input value={field} onChange={evt => this.handleChangeOnFieldToIgnore(evt.target.value, index)}/>
            <Button icon onClick={() => this.deleteFieldToIgnore(index)}><Icon name='trash'/></Button>
        </p>
    }

    deleteFieldToIgnore(index) {
        let fields = this.state.fieldsToIgnore
        fields.splice(index, 1)
        console.log("FIELDS:", fields)
        this.setState({fieldsToIgnore: fields})
    }

    handleChangeOnFieldToIgnore(newValue, index) {
        let fields = this.state.fieldsToIgnore
        fields[index] = newValue
        this.setState({fieldsToIgnore: fields})
    }

    drawInputAndOutPut() {
        let output = this.state.exportExpectedList?.map(it => Base64.decode(it.base64Content)).join("\n")
        if (this.props.test?.type == "IMPORT") {
            output = this.state.expected
        }

        return <div style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            height: '800px',

        }}>
            <div style={{
                flex: "1 1 auto",
                display: 'flex',
                flexDirection: 'column',
            }}>
                <p>Input</p>
                {this.drawInput()}
            </div>
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <p>Fields to ignore</p>
                {this.drawFieldsToIgnore()}
            </div>
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
            }}>
                <p>Expected Result</p>
                <SimpleTextBox
                    value={output}
                    placeholder={'expected result'}
                    readOnly={true}
                    onChange={() => {
                    }}
                />
            </div>

        </div>
    }

    drawInput() {
        let inputImportData = this.state.test.input.importData
        if (inputImportData != null && inputImportData.type == TransportOrderConfigTestInputImport.TypeEnum.FILE) {
            return <p>
                <br/>
                Import data was File and can not be shown here!
                <br/>
                <br/>
                FileName: {this.state.test.input.importData.fileName}
                <br/>
                <br/>
                {this.downloadFile(inputImportData)}
            </p>
        } else {
            return <SimpleTextBox
                value={this.state.input}
                placeholder={'input'}
                readOnly={true}
                onChange={() => {
                }}
            />
        }
    }

    downloadFile(inputImportData: TransportOrderConfigTestInputImport) {

        let raw = atob(inputImportData.importDoc)

        let rawLength = raw.length;
        let array = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }

        return <DownloadButton
            filename={this.state.test.input.importData.fileName}
            exportFile={() => {
                return array
            }}
        />

    }
}