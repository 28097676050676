import * as React from "react";
import {ConfigContext} from "../../../context/ConfigContext";
import {Button, Checkbox, Grid, Header, Icon, Input, Label, List, ListItem, Loader, Modal} from "semantic-ui-react";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {
    BillingRequest,
    BillWorkLogBookEntriesRequest,
    ProjectWorkLogBookEntry,
    UploadInvoiceToZohoRequest, ZohoResponse,
} from "../../../../../generated";
import DownloadLink from "../../../../util/react-download-link";
import {IfBox} from "../../../../style/if";
import ReactTable from "react-table";
import Notifications, {notify} from 'react-notify-toast';
import {formatDateFromStringNoTime} from "../../../../../format";
import {DownloadButton} from "../../../../util/react-download-link/DownloadButton";


export interface BillingToolPageProps {

}

export interface BillingToolPageState {
    isLoading: boolean
    isLoadingCreation: boolean
    isUploadModalOpen: boolean
    exportCurrentCompanyOnly: boolean
    exportCreditNotesOnly: boolean

    isLoadingCreatingInvoices: boolean
    isLoadingUploadCreditNotes: boolean
    maxNumberOfInvoices: number | undefined
    numberUploadedToZoho: number | undefined

    year: number
    month: number

    zipFile: any

    logEntries: ProjectWorkLogBookEntry[]
    allSelected: boolean
    selected: ProjectWorkLogBookEntry[]


    count: number
    page: number
    take: number
    sortBy: 'CREATED_DATE' | 'COMPANY_NAME' | 'SHORT_DESCRIPTION' | 'TIME_SPEND_ON_TASK' | 'BILLING_COST'
    sortDirection: 'ASC' | 'DESC'
}

export class BillingToolPage extends React.Component<BillingToolPageProps, BillingToolPageState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isLoadingCreation: false,
            isUploadModalOpen: false,
            exportCurrentCompanyOnly: false,
            exportCreditNotesOnly: false,

            isLoadingCreatingInvoices: false,
            isLoadingUploadCreditNotes: false,
            numberUploadedToZoho: undefined,
            maxNumberOfInvoices: undefined,

            year: null,
            month: null,

            zipFile: null,

            logEntries: [],
            allSelected: false,
            selected: [],

            count: 0,
            page: 1,
            take: 25,
            sortBy: 'CREATED_DATE',
            sortDirection: 'ASC'
        }
    }

    async componentDidMount() {

        let now = new Date()
        let year = now.getFullYear()
        let month = now.getMonth()

        if (month == 0) {
            year = year - 1
            month = 12
        }

        try {
            let logEntries = await this.fetchLogEntries(year, month)

            this.setState({year: year, month: month, logEntries: logEntries, isLoading: false})

        } catch (e) {
            this.setState({isLoading: false})
        }
    }

    async fetchLogEntries(year = this.state.year, month = this.state.month) {
        this.setState({isLoading: true})
        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let req = {} as BillingRequest
        req.year = year
        req.month = month

        let resp = await backend.internalApi.fetchUnbilled(
            req,
            this.state.take,
            (this.state.page - 1) * this.state.take,
            this.state.sortBy,
            this.state.sortDirection,
            auth)

        return resp.workLogEntries
    }

    async createBill(currentCompanyOnly: boolean) {
        this.setState({isLoadingCreation: true})
        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let req = {} as BillingRequest
        req.month = this.state.month
        req.year = this.state.year
        if (currentCompanyOnly) {
            req.companyIdFilter = [this.context.companyId]
        }

        let response = await fetch('/api/internal/createBilling', {
            method: 'POST',
            headers: {
                "Authorization": auth.headers.Authorization.toString(),
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(req)
        })

        if (response.status != 200) {

            notify.show("ERROR: Backend responded with status:" + response.status + ": " + response.statusText, 'error', 5000, '#fc0303')
            this.setState({isLoadingCreation: false})

        } else {

            let file = new Blob([await response.arrayBuffer()], {type: 'application/zip'})

            this.setState({zipFile: file, isLoadingCreation: false})
        }
    }

    async createInvoicesAndUploadToZoho(currentCompanyOnly: boolean, onlyCreditNotes: boolean) {
        let response
        let auth
        let req = {} as BillingRequest
        let failedReqs: ZohoResponse[] = []

        this.setState({
            isLoadingCreation: true,
            numberUploadedToZoho: 0,
            isLoadingUploadCreditNotes: true,
        })
        auth = (await backend.withTokenAuthHeader(authentication.token))


        req.month = this.state.month
        req.year = this.state.year
        if (currentCompanyOnly) {
            req.companyIdFilter = [this.context.companyId]
        }
        if (!onlyCreditNotes) {
            this.setState({isLoadingCreatingInvoices: true})
            try {
                response = await backend.internalApi.createInvoicesAndSaveToDB(req, auth)
            } catch (e) {
                console.error(e)
            }
            this.setState({isLoadingCreatingInvoices: false})
            if (response.invoiceResponses) {
                let numberUploadedToZoho = 0

                let invoices = response.invoiceResponses
                this.setState({
                    maxNumberOfInvoices: invoices.length,
                    numberUploadedToZoho: numberUploadedToZoho,
                    isLoadingCreatingInvoices: false
                })
                for (const invoice of invoices) {
                    try {
                        let req = {
                            invoiceId: invoice.invoiceId
                        } as UploadInvoiceToZohoRequest
                        let invoiceResponse = await backend.internalApi.uploadInvoiceToZoho(req, auth)
                        failedReqs.push(...invoiceResponse.zohoResponses.filter(resp => resp.statusCode < 200 || resp.statusCode > 201))

                    } catch (e) {
                        console.error(e)
                        let response = {
                            zohoInvoiceId: invoice.zohoId,
                            statusCode: -1,
                            resultBody: "Exception occured, check in Zoho if it worked for " + invoice.companyName
                        } as ZohoResponse
                        failedReqs.push(response)
                    } finally {
                        numberUploadedToZoho = numberUploadedToZoho + 1
                        this.setState({
                            numberUploadedToZoho: numberUploadedToZoho
                        })
                    }
                }
            }
        }
        if (!currentCompanyOnly) {
            try {
                let creditNoteResponse = await backend.internalApi.uploadCreditNotesToZoho(req, auth)
                failedReqs.push(...creditNoteResponse.zohoResponses.filter(resp => resp.statusCode < 200 || resp.statusCode > 201))
                this.setState({isLoadingCreatingInvoices: false})
            } catch (e) {
                console.error(e)
                let response = {
                    statusCode: -1,
                    resultBody: "Exception occured, check in Zoho if creditNotes worked"
                } as ZohoResponse
                failedReqs.push(response)
            }
        }

        if (failedReqs.length > 0) {
            console.log("failed Requests: ", failedReqs)
            notify.show(
                <div>
                    <List>
                        {failedReqs.map(req => <ListItem>
                            InvoiceNumber: {req.zohoReferenceNumber},
                            StatusCode: {req.statusCode},
                            {req.resultBody}
                        </ListItem>)}
                    </List>

                    <button onClick={notify.hide}>close</button>
                </div>, "error", -1, '#fc0303'
            )
            // notify.show("Failed Invoices for company: "+ response.invoices.map(invoice => invoice.company.name).toString(), 'error', 5000, '#fc0303')
            this.setState({isLoadingCreation: false})

        } else {
            this.setState({isLoadingCreation: false})
        }
        this.setState({isLoadingCreation: false, isUploadModalOpen: false, maxNumberOfInvoices: undefined})
    }

    async loadLogEntries() {
        try {
            let logEntries = await this.fetchLogEntries()

            this.setState({logEntries: logEntries, isLoading: false})
        } catch (e) {
            this.setState({isLoading: false})
        }
    }

    selectOrUnselectAll() {
        if (this.state.allSelected) {
            this.setState({
                allSelected: false,
                selected: []
            })
        } else {
            this.setState({
                allSelected: true,
                selected: this.state.logEntries
            })
        }


    }

    render() {

        return <span>
            <Input label={'Year'}
                   placeholder='Year' value={this.state.year}
                   type="number"
                   onChange={evt => this.setState({year: Number(evt.target.value), zipFile: null})}
            />
            <Input label={'Month'}
                   placeholder='Month' value={this.state.month}
                   type="number"
                   onChange={evt => this.setState({month: Number(evt.target.value), zipFile: null})}
            />
            <Button icon loading={this.state.isLoadingCreation} onClick={() => this.createBill(false)}><Icon
                name={'file excel'}/> Create Bill for all</Button>
            <Button icon loading={this.state.isLoadingCreation} onClick={() => this.createBill(true)}><Icon
                name={'file excel'}/> Create Bill for current company</Button>

            <Button icon loading={this.state.isLoadingCreation}
                    onClick={() => this.setState({
                        isUploadModalOpen: true,
                        exportCurrentCompanyOnly: false,
                        exportCreditNotesOnly: false
                    })}><Icon
                name={'file excel'}/> Create all and Upload </Button>
            <Button icon loading={this.state.isLoadingCreation}
                    onClick={() => this.setState({
                        isUploadModalOpen: true,
                        exportCurrentCompanyOnly: true,
                        exportCreditNotesOnly: false
                    })}><Icon
                name={'file excel'}/> Create Invoice for current Company and Upload </Button>
            <Button icon loading={this.state.isLoadingCreation}
                    onClick={() => this.setState({
                        isUploadModalOpen: true,
                        exportCurrentCompanyOnly: false,
                        exportCreditNotesOnly: true
                    })}><Icon
                name={'file excel'}/> Create CreditNotes and Upload </Button>

            <IfBox shouldShow={this.state.zipFile != null}>
                <DownloadButton
                    filename={"billing_" + this.state.year + "-" + this.state.month + ".zip"}
                    exportFile={() => {
                        return this.state.zipFile
                    }}
                />
            </IfBox>
            <br/>

            {this.drawTable()}

            <Notifications/>
            <Modal
                id='ZohoConfirmModal'
                open={this.state.isUploadModalOpen}
                closeOnDimmerClick={false}
                onClose={() => this.setState({isUploadModalOpen: false})}
                dimmer="blurring"
                size='small'>
            <Header icon='send' content='Export to Zoho'/>
            <Modal.Content>
                {this.state.maxNumberOfInvoices == null && this.state.isLoadingCreation == false ?
                    this.state.exportCurrentCompanyOnly ? "Export to Zoho for current company?" : this.state.exportCreditNotesOnly ? "Export only credit notes?" : "Export to Zoho for all companies?"
                    :
                    <div>
                        <div>
                            <IfBox shouldShow={this.state.exportCreditNotesOnly == false}>
                                {this.state.isLoadingCreatingInvoices ? <Icon loading name='spinner'/> :
                                    <Icon color={"green"} name={'checkmark'}/>}
                                {"Create Invoices"}
                            </IfBox>

                        </div>
                        {this.state.exportCreditNotesOnly == false ? <div>
                            {this.state.numberUploadedToZoho != this.state.maxNumberOfInvoices ?
                                <Icon loading name='spinner'/> : <Icon color={"green"} name={'checkmark'}/>}
                            {this.state.numberUploadedToZoho} {" von "} {this.state.maxNumberOfInvoices ?? "?"} {" Invoices uploaded to Zoho"}
                        </div> : <></>}
                        {this.state.exportCurrentCompanyOnly ? <></> : <div>
                            {this.state.isLoadingUploadCreditNotes ? <Icon loading name='spinner'/> :
                                <Icon color={"green"} name={'checkmark'}/>}
                            {"Uploading Credit Notes"}
                        </div>}
                    </div>}

            </Modal.Content>
                <Modal.Actions>
                    <Button icon color={'green'} loading={this.state.isLoadingCreation}
                            disabled={this.state.isLoadingCreation}
                            onClick={() => this.createInvoicesAndUploadToZoho(this.state.exportCurrentCompanyOnly, this.state.exportCreditNotesOnly)}><Icon
                        name={'check'}/> Submit</Button>
                    <Button icon color={'red'} loading={this.state.isLoadingCreation}
                            onClick={() => this.setState({isUploadModalOpen: false})}><Icon
                        name={'close'}/> Close</Button>
                </Modal.Actions>
        </Modal>
        </span>
    }

    async billSelected() {
        this.setState({isLoading: true})

        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let request = {} as BillWorkLogBookEntriesRequest
        request.logEntryIds = this.state.selected.map(s => s._id)
        request.year = this.state.year
        request.month = this.state.month

        let res = await backend.internalApi.billWorkLogBookEntries(request, auth)

        if (res.errors.size > 0) {
            notify.show("ERROR: The following errors appeared: " + JSON.stringify(res.errors, null, 4), 'error', 30000, '#fc0303')

        } else {
            notify.show('successfully billed ' + request.logEntryIds.length + " entries.", 'success', 3000, '#28f751');
        }

        this.loadLogEntries()
    }

    switchSelectionStateOfItem(entry: any) {
        this.setState({isLoading: true})

        let array = [...this.state.selected]; // make a separate copy of the array
        let index = array.indexOf(entry)
        if (index == -1) {
            // entry not found, adding Id

            this.setState({selected: array.concat(entry)})
            console.log("entry added")


        } else {
            // entry found, removing it

            array.splice(index, 1);
            this.setState({selected: array})
            console.log("entry removed")

        }
        this.setState({isLoading: false})
    }

    drawTable() {
        const columns = [{
            id: 'select',
            Header: 'select',
            width: 50,
            accessor: (d: any) => <Checkbox
                checked={this.state.selected.includes(d)}
                onChange={() => this.switchSelectionStateOfItem(d)}
            />
        }, {
            id: 'createdDate',
            Header: 'createdDate',
            width: 120,
            accessor: (d: any) => formatDateFromStringNoTime(d.createdDate),
        }, {
            id: 'companyName',
            Header: 'companyName',
            width: 300,
            accessor: (d: any) => d.companyName,
        }, {
            id: 'shortDescription',
            Header: 'shortDescription',
            width: 300,
            accessor: (d: any) => d.shortDescription,
        }, {
            id: 'timeSpentOnTask',
            Header: 'timeSpentOnTask',
            width: 130,
            accessor: (d: any) => d.timeSpentOnTask,
        }, {
            id: 'billingCost',
            Header: 'billingCost',
            width: 100,
            accessor: (d: any) => d.billingCost,
        }
        ]
        return <span>

            <Button icon loading={this.state.isLoading} onClick={() => this.loadLogEntries()}><Icon name='sync'/> Reload </Button>

            <br/>
            <span style={{paddingRight: 20}}>
                <Checkbox
                    checked={this.state.allSelected}
                    onChange={() => {
                        this.selectOrUnselectAll()
                    }}/> - select all
            </span>
            <Button icon loading={this.state.isLoading} onClick={() => this.billSelected()}><Icon
                name='money bill alternate'/> bill selected </Button>

            <ReactTable
                data={this.state.logEntries}
                pages={Math.ceil(this.state.count / this.state.take)}
                columns={columns}
                sorted={[
                    {
                        id: 'TIMESTAMP',
                        desc: false
                    }
                ]}
                onSortedChange={(newSorted, column) => {
                    this.changeSort(column.sortField)
                }}

                defaultPageSize={this.state.take}
                className="-striped -highlight"
                loading={this.state.isLoading}
                style={{cursor: "pointer"}}
                onPageChange={(pageIndex) => this.changePage(pageIndex)}
                onPageSizeChange={(pageSize) => this.changePageSize(pageSize)}

            />

        </span>
    }

    changeSort(newSortField: 'CREATED_DATE' | 'COMPANY_NAME' | 'SHORT_DESCRIPTION' | 'TIME_SPEND_ON_TASK' | 'BILLING_COST') {
        let sortDirection = this.state.sortDirection
        if (this.state.sortBy == newSortField) {
            if (sortDirection == 'ASC') {
                sortDirection = 'DESC'
            } else {
                sortDirection = 'ASC'
            }
        }
        this.setState({isLoading: true, sortBy: newSortField, sortDirection: sortDirection})
        this.loadLogEntries()
    }

    changePage(newPageIndex: number) {
        this.setState({isLoading: true, page: newPageIndex})
        this.loadLogEntries()
    }

    changePageSize(newPageSize: number) {
        this.setState({isLoading: true, take: newPageSize})
        this.loadLogEntries()
    }
}