import * as React from "react";
import {Button, Header, Modal} from "semantic-ui-react";
import {DisplayFile} from "../DisplayFile";

export interface FtpExplorerFileModalProps {
    isOpen: boolean;
    onClose: () => void;
    fileBuffer: ArrayBuffer;
    fileName: string;
}

export function FtpExplorerFileModal(props: React.PropsWithChildren<FtpExplorerFileModalProps>) {
    const {isOpen, onClose, fileBuffer, fileName} = props;

    const handleClose = () => {
        onClose();
    }

    return <Modal
        open={isOpen}
        onClose={handleClose}
        dimmer="blurring"
        size='fullscreen'>
        <Header icon='browser' content={fileName}/>
        <Modal.Content>
            <DisplayFile
                fileBuffer={fileBuffer}
                fileName={fileName}
                displayName={false}
            />
            <Button onClick={handleClose}>close</Button>
        </Modal.Content>
    </Modal>
}