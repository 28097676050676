import * as moment from 'moment';
import * as React from "react";
import {SyntheticEvent} from "react";
import Hotkeys from 'react-hot-keys';
import {
    Button,
    ButtonGroup,
    Checkbox,
    Dropdown,
    Form,
    Grid,
    GridColumn,
    GridRow,
    Icon,
    Input,
    Popup
} from 'semantic-ui-react';
import {CheckboxProps} from "semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox";
import {v4 as uuid} from 'uuid';
import {authentication} from "../../../../authentication";
import {
    formatFreightStatusEnum,
    formatStopLocationStatusEnum,
    formatTransportOrderStatusEnum
} from '../../../../format';
import {
    Company,
    FetchTransportOrderListFilterRequest,
    FreightStatus,
    PartnerQueryObject,
    PartnerQueryResponse,
    SearchTermObject,
    StopLocationStatus,
    TransportOrderStatus,
    UserFilter,
    UserFilterList
} from "../../../../generated";
import {backend} from "../../../../xconvert-backend";

import {DropdownProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import {DropdownItemProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import {IfBox} from "../../../style/if";
import {RouteComponentProps} from "react-router";
import {DebounceInput, getNextWorkingDay} from "../../../../utils";
import {DateRange} from "./DepotStockFilterComponent";
import {CustomDateRangePicker} from "../../../util/dateRangePicker/CustomDateRangePicker";
import {UserFilterAddModal} from "./modals/UserFilterAddModal";
import SearchForTransportOrderPartEnum = SearchTermObject.SearchForTransportOrderPartEnum;

const noPaddingStyle = {
    padding: '0px'
};


export class FieldsToSearchDropdownEntry {
    fieldsToSearchId: number
    fieldsToSearch?: FieldToSearch[]
    defaultLabel?: string

    constructor(fieldsToSearchId: number, defaultLabel?: string, fieldsToSearch?: FieldToSearch[]) {
        this.defaultLabel = defaultLabel
        this.fieldsToSearchId = fieldsToSearchId
        this.fieldsToSearch = fieldsToSearch
    }
}

export class FieldToSearch {
    transportOrderPart?: SearchForTransportOrderPartEnum
    additionalDataKey?: string


    constructor(transportOrderPart: SearchForTransportOrderPartEnum, additionalDataKeys?: string) {
        this.transportOrderPart = transportOrderPart
        this.additionalDataKey = additionalDataKeys
    }
}

export type DateRangeStr =
    "selection"
    | "nextWorkingDay"
    | "tomorrow"
    | "today"
    | "lastWorkingDay"
    | "yesterday"
    | "nextWeek"
    | "thisWeek"
    | "lastWeek"
    | "thisMonth"
    | "lastMonth"
    | "last7days"
    | "last30days"
    | "all";

export enum InTimeEnum {
    InTime,
    NotInTime,
    All
}

export interface FilterConditions {
    fromDateTime?: Date,
    toDateTime?: Date,
    dateRangeSelection?: DateRangeStr,
    term?: string,
    status?: TransportOrderStatus.StatusCodeEnum,
    partners?: PartnerQueryObject[],
    dateType?: FetchTransportOrderListFilterRequest.DateTimeFieldEnum,
    valid?: "ONLY_VALID" | "ONLY_INVALID",
    inTime: InTimeEnum,
    includeStatus?: FetchTransportOrderListFilterRequest.IncludeStatusEnum[],
    includeStopLocationStatus?: FetchTransportOrderListFilterRequest.IncludeStopLocationStatusEnum[],
    includeFreightStatus?: FetchTransportOrderListFilterRequest.IncludeFreightStatusEnum[]
    hasVehicle?: boolean
    fieldsToSearch?: FieldsToSearchDropdownEntry
}

export type ExcelExportMode = "OneLinePerTransport" | "OneLinePerFreight"

export interface FilterProps extends RouteComponentProps<any> {
    onFilterChange: (fc: FilterConditions, reset?: boolean) => void
    initialFilter: FilterConditions
    onExportExcelButtonClick?: (mode: ExcelExportMode) => Promise<void>
    statusFilterVisible: boolean
    partnerSelectionVisible: boolean
    exportButtonVisible: boolean
    history: any
}

export interface TransportOrderFilterComponentState {
    filterConditions: FilterConditions,
    createdChecked: boolean,
    dispatchedChecked: boolean,
    acceptedChecked: boolean,
    acknowledgingChecked: boolean,
    executingChecked: boolean,
    doneChecked: boolean,
    canceledChecked: boolean,
    rejectedChecked: boolean,
    inputMissingChecked: boolean,
    validChecked: boolean,
    withoutVehicleChecked: boolean,
    isExcelExportRunning: boolean,
    isLoadingPartners: boolean,
    isLoadingFilters: boolean,
    selectedPartners: Company[],
    partners: Company[],
    filters: UserFilterList | undefined,
    selectedFilterId: string | undefined,
    fieldsToSearch: FieldsToSearchDropdownEntry[],
    componentWidth: number
    autoReloadCheck: boolean
    addFilterModalOpen: boolean
    editFilterModalOpen: boolean
    userFilter: UserFilter | undefined
    filterId: string | undefined

}

export const LOGENIOS_TO_FILTER_CONDITIONS = "logenios_to_filter"

export function setParams(filter: any) {
    const searchParams = new URLSearchParams();
    searchParams.set("filter", JSON.stringify(filter, null, 0));
    return searchParams.toString();
}

export function getParams(location: any) {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('filter')

}

export class TransportOrderFilterComponent extends React.Component<FilterProps, TransportOrderFilterComponentState> {

    searchRef = React.createRef<Input>();
    debounceInput = new DebounceInput(500)

    componentRef = React.createRef<HTMLDivElement>()


    constructor(props: FilterProps) {
        super(props)

        this.state = {
            filterConditions: {
                term: this.props.initialFilter.term || "",
                fromDateTime: this.props.initialFilter.fromDateTime,
                toDateTime: this.props.initialFilter.toDateTime,
                dateRangeSelection: this.props.initialFilter.dateRangeSelection,
                status: undefined,
                dateType: this.props.initialFilter.dateType || FetchTransportOrderListFilterRequest.DateTimeFieldEnum.CREATED,
                valid: this.props.initialFilter.valid || undefined,
                inTime: InTimeEnum.All,
                fieldsToSearch: {
                    fieldsToSearchId: 0,
                    defaultLabel: "fullTextSearch"
                }
            },
            createdChecked: false,
            dispatchedChecked: false,
            acceptedChecked: false,
            acknowledgingChecked: false,
            executingChecked: false,
            doneChecked: false,
            canceledChecked: false,
            rejectedChecked: false,
            inputMissingChecked: false,
            validChecked: false,
            withoutVehicleChecked: false,
            isExcelExportRunning: false,
            isLoadingPartners: false,
            isLoadingFilters: false,
            selectedPartners: [],
            partners: [],
            filters: undefined,
            selectedFilterId: '',
            fieldsToSearch: [], //this.getFieldsToSearch(),
            componentWidth: this.componentRef.current?.offsetWidth ?? window.innerWidth,
            autoReloadCheck: false,
            addFilterModalOpen: false,
            editFilterModalOpen:false,
            userFilter: undefined,
            filterId: undefined
        }
        this.fetchPartners();
        this.fetchFilters();
        this.handleResize = this.handleResize.bind(this)
    }

    handleResize() {
        this.setState({componentWidth: this.componentRef.current?.offsetWidth ?? window.innerWidth})
    }

    async fetchFilters(): Promise<void> {
        try {
            if (authentication.token) {
                this.setState({isLoadingFilters: true})
                let auth = backend.withTokenAuthHeader(authentication.token)
                let filters: UserFilterList = await backend.companyApi.fetchFilters(authentication.company?._id, authentication.user?._id, auth)
                this.setState({filters: filters ?? undefined, isLoadingFilters: false})
            } else {
                throw "No auth token"
            }
        } catch (ex) {
            console.error(ex)
        }
    }

    async fetchPartners(): Promise<void> {
        try {
            if (authentication.token) {
                this.setState({isLoadingPartners: true})
                let auth = backend.withTokenAuthHeader(authentication.token)
                let partners: PartnerQueryResponse = await backend.internalApi.fetchTransportOrderPartnerList(auth)
                let partnerCompanyList = partners.partners;
                this.setState({partners: partnerCompanyList ?? [], isLoadingPartners: false})
            } else {
                throw "No auth token"
            }
        } catch (ex) {
            console.error(ex)
        }
    }

    updateURL = (newUrlParameter: any | undefined) => {
        const url = setParams(newUrlParameter);
        //do not forget the "?" !
        this.props.history?.push(`?${url}`);
    };


    filterEmpty() {
        return this.state.filterConditions.fromDateTime == undefined && this.state.filterConditions.dateType == "CREATED" && this.state.filterConditions.toDateTime == undefined
            && this.state.filterConditions.dateRangeSelection == "all" && this.state.filterConditions.status == null
            && this.state.filterConditions.valid == null && this.state.filterConditions.inTime == InTimeEnum.All && !this.isStatusSet() && !this.state.filterConditions.term
            && this.state.selectedPartners?.length == 0
    }

    refreshList() {
        this.setState(prevState => ({autoReloadCheck: !prevState.autoReloadCheck}), () => {
            localStorage.setItem("OrderListAutoRefresh", this.state.autoReloadCheck ? "true" : "false")
            if (this.state.autoReloadCheck) {
                setInterval(() => this.applyFilterChange(true), 60000)
            }
        })

    }

    resetFilter() {
        this.setState({
            filterConditions: {
                fromDateTime: undefined,
                dateType: FetchTransportOrderListFilterRequest.DateTimeFieldEnum.CREATED,
                toDateTime: undefined,
                term: "",
                dateRangeSelection: "all",
                status: undefined,
                valid: undefined,
                inTime: InTimeEnum.All,
                includeStatus: undefined,
                includeFreightStatus: undefined,
                includeStopLocationStatus: undefined,
                hasVehicle: undefined
            },
            selectedFilterId: '',
            createdChecked: false,
            dispatchedChecked: false,
            acceptedChecked: false,
            acknowledgingChecked: false,
            executingChecked: false,
            doneChecked: false,
            canceledChecked: false,
            rejectedChecked: false,
            inputMissingChecked: false,
            validChecked: false,
            withoutVehicleChecked: false,
            selectedPartners: []
        }, () => {
            this.applyFilterChange(true)
        })
    }

    async applyFilterChange(reset = false) {
        this.debounceInput.clearQueries()
        let filter: FilterConditions = this.state.filterConditions
        this.updateURL(filter)
        localStorage.setItem(LOGENIOS_TO_FILTER_CONDITIONS, JSON.stringify(filter))
        this.props.onFilterChange(filter, reset)
    }

    componentDidUpdate(prevProps: any, prevState: any) {

        let filterString = localStorage.getItem(LOGENIOS_TO_FILTER_CONDITIONS)

        let width = this.componentRef.current?.offsetWidth ?? window.innerWidth
        // console.log("componentDidUpdate width: ", width)
        if (width != this.state.componentWidth) {
            this.setState({componentWidth: width})
        }
        if (filterString) {
            let localStorageFilter: FilterConditions = JSON.parse(filterString)
            if (localStorageFilter.term != prevState.term && prevState.term == this.state.filterConditions.term) {
                this.filterChange(localStorageFilter)
            }
        }
    }

    componentDidMount() {
        let filterString = localStorage.getItem(LOGENIOS_TO_FILTER_CONDITIONS)
        let width = this.componentRef.current?.offsetWidth ?? window.innerWidth
        // console.log("componentDidMount width: ", width)

        let autoRefreshStoredValue = localStorage.getItem("OrderListAutoRefresh") == "true"
        if (autoRefreshStoredValue) {
            this.refreshList()
        }

        if (filterString) {
            let filter: FilterConditions = JSON.parse(filterString)
            if (filter) {
                if (filter.fromDateTime) {
                    filter.fromDateTime = filter.fromDateTime ? new Date(filter.fromDateTime) : undefined
                }
                if (filter.toDateTime) {
                    filter.toDateTime = filter.toDateTime ? new Date(filter.toDateTime) : undefined
                }

                this.mutexChecked(filter.status)

                this.setState({
                    filterConditions: filter,
                    validChecked: filter.valid == "ONLY_INVALID",
                    selectedPartners: filter.partners && filter.partners?.length > 0 ? filter.partners : [],
                    componentWidth: this.componentRef.current?.offsetWidth ?? window.innerWidth
                })
                this.props.onFilterChange(filter)
            } else {
                this.props.onFilterChange(this.props.initialFilter)
            }
        } else {
            this.props.onFilterChange(this.props.initialFilter)
        }

        window.addEventListener("resize", this.handleResize)
    }

    componentWillUnMount() {
        window.removeEventListener("resize", this.handleResize)
    }

    filterChange(filter: FilterConditions) {
        if (filter.fromDateTime) {
            filter.fromDateTime = new Date(filter.fromDateTime)
        } else {
            filter.fromDateTime = undefined
        }
        if (filter.toDateTime) {
            filter.toDateTime = new Date(filter.toDateTime)
        } else {
            filter.toDateTime = undefined
        }
        if (filter.valid == "ONLY_INVALID") {
            this.setState({validChecked: true})
        }
        if (filter.inTime) {
            this.setState({filterConditions: {...this.state.filterConditions, inTime: filter.inTime}})
        }

        this.mutexChecked(filter.status)
        const dateRange: DateRange = this.calculateDateRange(filter.dateRangeSelection)

        this.setState({
            filterConditions: {
                ...this.state.filterConditions,
                fromDateTime: filter.fromDateTime,
                dateType: filter.dateType,
                toDateTime: filter.toDateTime,
                term: filter.term,
                dateRangeSelection: filter.dateRangeSelection,
                status: filter.status,
                valid: filter.valid,
                partners: filter.partners
            }
        })
        this.props.onFilterChange(filter)
    }

    onKeyDown = (keyName: any, e: any) => {
        console.log(keyName)
        e.preventDefault()
        if (keyName == "ctrl+f" && this.searchRef.current) {
            this.searchRef.current.focus()
        }
    }

    onSearchTermChange = async (evt: any) => {
        // console.log("entered: ", evt.target.value);
        let inputString = evt.target.value
        this.setState({filterConditions: {...this.state.filterConditions, term: inputString}})
        await this.debounceInput.newInput(inputString)
            .then((value => {
                this.applyFilterChange()
            }))
            .catch(e => {
            })
    }

    onInputKeyDown = (key: any) => {

        if (key.key == "Enter") {
            console.log("onInputKeyDown Enter")
            key.preventDefault()
            this.applyFilterChange()
        }
    }

    onDatesSelected = (startDate?: moment.Moment, endDate?: moment.Moment) => {
        this.setState({
            filterConditions: {
                ...this.state.filterConditions,
                fromDateTime: startDate?.toDate(),
                toDateTime: endDate?.toDate(),
                dateRangeSelection: "selection"
            }
        }, this.applyFilterChange)
    }

    onDateTypeChange = (evt: any, data: any) => {
        this.setState({
            filterConditions: {
                ...this.state.filterConditions,
                dateType: data.value
            }
        }, this.applyFilterChange)
        console.log(this.state.filterConditions.dateType);

    }

    onValidChange = () => {
        this.setState({
            filterConditions: {
                ...this.state.filterConditions,
                valid: this.state.filterConditions.valid ? undefined : "ONLY_INVALID"
            }, validChecked: this.state.filterConditions.valid ? false : true
        }, this.applyFilterChange)
    }

    onPredefinedDateRangeSelect = (evt: any, data: any) => {
        const dateRange: DateRange = this.calculateDateRange(data.value)

        this.setState({
            filterConditions: {
                ...this.state.filterConditions,
                fromDateTime: dateRange.start,
                toDateTime: dateRange.end,
                dateRangeSelection: dateRange.selectorString as DateRangeStr,
            }
        }, this.applyFilterChange)
    }


    getLastWorkingDay() {
        let workDay = moment().subtract(1, "days")
        if (workDay.day() === 0) workDay.subtract(2, "days")
        else if (workDay.day() == 6) workDay.subtract(1, "days")

        return workDay
    }

    calculateDateRange(dateRangeStr?: string): DateRange {
        let dateRange = {} as DateRange

        switch (dateRangeStr) {
            case "selection":
                if (this.state.filterConditions.fromDateTime)
                    dateRange.start = this.state.filterConditions.fromDateTime
                if (this.state.filterConditions.toDateTime)
                    dateRange.end = this.state.filterConditions.toDateTime
                dateRange.selectorString = "selection"
                return dateRange
            case "nextWorkingDay":
                dateRange.start = getNextWorkingDay().startOf("day").toDate()
                dateRange.end = getNextWorkingDay().endOf("day").toDate()
                dateRange.selectorString = "nextWorkingDay"
                break;
            case "tomorrow":
                dateRange.start = moment().add(1, "days").startOf("day").toDate()
                dateRange.end = moment().add(1, "days").endOf("day").toDate()
                dateRange.selectorString = "tomorrow"
                break;
            case "today":
                dateRange.start = moment().startOf("day").toDate()
                dateRange.end = moment().endOf("day").toDate()
                dateRange.selectorString = "today"
                break;
            case "lastWorkingDay":
                dateRange.start = this.getLastWorkingDay().startOf("day").toDate()
                dateRange.end = this.getLastWorkingDay().endOf("day").toDate()
                dateRange.selectorString = "lastWorkingDay"
                break;
            case "yesterday":
                dateRange.start = moment().subtract(1, "days").startOf("day").toDate()
                dateRange.end = moment().subtract(1, "days").endOf("day").toDate()
                dateRange.selectorString = "yesterday"
                break;
            case "nextWeek":
                dateRange.start = moment().add(1, "weeks").startOf("week").toDate()
                dateRange.end = moment().add(1, "weeks").endOf("week").toDate()
                dateRange.selectorString = "nextWeek"
                break;
            case "thisWeek":
                dateRange.start = moment().subtract(0).startOf("week").toDate()
                dateRange.end = moment().subtract(0).endOf("week").toDate()
                dateRange.selectorString = "thisWeek"
                break;
            case "lastWeek":
                dateRange.start = moment().subtract(1, "weeks").startOf("week").toDate()
                dateRange.end = moment().subtract(1, "weeks").endOf("week").toDate()
                dateRange.selectorString = "lastWeek"
                break;
            case "thisMonth":
                dateRange.start = moment().subtract(0).startOf("month").toDate()
                dateRange.end = moment().subtract(0).endOf("month").toDate()
                dateRange.selectorString = "thisMonth"
                break;
            case "lastMonth":
                dateRange.start = moment().subtract(1, "months").startOf("month").toDate()
                dateRange.end = moment().subtract(1, "months").endOf("month").toDate()
                dateRange.selectorString = "lastMonth"
                break;
            case "last7days":
                dateRange.start = moment().subtract(6, "days").startOf("day").toDate()
                dateRange.end = moment().endOf("day").toDate()
                dateRange.selectorString = "last7days"
                break;
            case "last30days":
                dateRange.start = moment().subtract(29, "days").startOf("day").toDate()
                dateRange.end = moment().endOf("day").toDate()
                dateRange.selectorString = "last30days"
                break;
            default:
                dateRange.start = undefined
                dateRange.end = undefined

                return dateRange
        }

        return dateRange
    }

    onInTimeChange(status: Number) {
        let value = status;
        if ((status === InTimeEnum.InTime && this.state.filterConditions.inTime === InTimeEnum.InTime) || (status === InTimeEnum.NotInTime && this.state.filterConditions.inTime === InTimeEnum.NotInTime)) {
            value = InTimeEnum.All
        }
        this.setState({
            filterConditions: {
                ...this.state.filterConditions,
                inTime: value as InTimeEnum
            }
        }, this.applyFilterChange)
    }

    onWithoutVehicle(data: CheckboxProps) {
        this.setState({
            filterConditions: {
                ...this.state.filterConditions,
                hasVehicle: data.checked ? false : undefined
            }, withoutVehicleChecked: data.checked ? true : false
        }, this.applyFilterChange)
    }

    isStatusSet() {

        let isSet = (this.state.filterConditions.includeStatus && this.state.filterConditions.includeStatus?.length > 0)
            || this.state.validChecked
            || this.state.filterConditions.inTime == InTimeEnum.NotInTime
            || this.state.filterConditions.inTime == InTimeEnum.InTime
            || this.state.withoutVehicleChecked
            || (this.state.filterConditions.includeStopLocationStatus && this.state.filterConditions.includeStopLocationStatus.length > 0)
            || (this.state.filterConditions.includeFreightStatus && this.state.filterConditions.includeFreightStatus.length > 0)

        return isSet
    }

    async exportExcelList(mode: ExcelExportMode) {
        this.setState({isExcelExportRunning: true})
        try {
            if (this.props.onExportExcelButtonClick != null)
                await this.props.onExportExcelButtonClick(mode)
        } catch (e) {
            console.log("Failed to export excel file")
            console.log(e)
        }
        this.setState({isExcelExportRunning: false})
    }

    getBaseFieldsToSearch(): FieldsToSearchDropdownEntry[] {
        return [
            {
                fieldsToSearchId: 0,
                defaultLabel: "fullTextSearch"
            },
            {
                fieldsToSearchId: 1,
                fieldsToSearch: [{transportOrderPart: SearchForTransportOrderPartEnum.TransportOrderId}],
                defaultLabel: 'Id'
            },
            {
                fieldsToSearchId: 2,
                fieldsToSearch: [{transportOrderPart: SearchForTransportOrderPartEnum.ExternalTargetTransportOrderId}],
                defaultLabel: "ExternalOrderNo"
            },
            {
                fieldsToSearchId: 3,
                fieldsToSearch: [{transportOrderPart: SearchForTransportOrderPartEnum.LoadingLocationNameOrCity}],
                defaultLabel: 'LoadingLocation'
            },
            {
                fieldsToSearchId: 4,
                fieldsToSearch: [{transportOrderPart: SearchForTransportOrderPartEnum.UnloadingLocationNameOrCity}],
                defaultLabel: 'UnloadingLocation'
            },
            {
                fieldsToSearchId: 5,
                fieldsToSearch: [{transportOrderPart: SearchForTransportOrderPartEnum.TargetCompanyName}],
                defaultLabel: 'Contractor'
            },
            {
                fieldsToSearchId: 6,
                fieldsToSearch: [{transportOrderPart: SearchForTransportOrderPartEnum.SourceCompanyName}],
                defaultLabel: 'Client'
            },
            {
                fieldsToSearchId: 7,
                fieldsToSearch: [{transportOrderPart: SearchForTransportOrderPartEnum.AssignedVehicleNumberPlate}, {transportOrderPart: SearchForTransportOrderPartEnum.AssignedVehicleExternalVehicleId}],
                defaultLabel: 'Vehicle'
            },
            {
                fieldsToSearchId: 8,
                fieldsToSearch: [{transportOrderPart: SearchForTransportOrderPartEnum.AssignedTrailerNumberPlate}, {transportOrderPart: SearchForTransportOrderPartEnum.AssignedTrailerExternalVehicleId}],
                defaultLabel: 'Trailer'
            },
            {
                fieldsToSearchId: 9,
                fieldsToSearch: [{transportOrderPart: SearchForTransportOrderPartEnum.FreightVerboseName}],
                defaultLabel: 'FreightVerboseName'
            }
        ]
    }

    getFieldsToSearch(): FieldsToSearchDropdownEntry[] {
        //const cfg = authentication.uiViewConfig?.transportOrderListPage

        let fieldsToSearch: FieldsToSearchDropdownEntry[] = this.getBaseFieldsToSearch()
/*
        cfg?.additionalColums?.map((column: AdditionalTableColumn) => {
            let part: SearchForTransportOrderPartEnum

            switch (column.level) {
                case LevelEnum.TRANSPORTORDER:
                    part = SearchForTransportOrderPartEnum.AdditionalData
                    break
                case LevelEnum.TRANSPORTORDERSTATUS:
                    part = SearchForTransportOrderPartEnum.StatusAdditionalData
                    break
                case LevelEnum.FREIGHT:
                    part = SearchForTransportOrderPartEnum.FreightAdditionalData
                    break
                case LevelEnum.STOPLOCATION:
                    part = SearchForTransportOrderPartEnum.StopsAdditionalData
                    break
                case LevelEnum.TRANSPORT:
                    part = SearchForTransportOrderPartEnum.TransportsAdditionalData
                    break
                default:
                    part = SearchForTransportOrderPartEnum.TransportsAdditionalData
                    break
            }
            let field: FieldsToSearchDropdownEntry = {
                fieldsToSearchId: fieldsToSearch.length,
                fieldsToSearch: [{transportOrderPart: part, additionalDataKey: column.additionalDataKey}],
                defaultLabel: column.defaultLabel
            }
            fieldsToSearch.push(field)
        })
        */

        return fieldsToSearch;
    }

    getSearchDropdownFields() {
        let options: DropdownItemProps[] = []

        this.getFieldsToSearch().map((field: FieldsToSearchDropdownEntry) => {

            let dropdownEntry: DropdownItemProps = {
                key: field.fieldsToSearchId,
                value: field.fieldsToSearchId,
                text: field.defaultLabel
            }
            options.push(dropdownEntry)
        })

        return options
    }

    render() {
        return <div id={"transportOrderFilters"} style={{margin: "0 0 5px"}} ref={this.componentRef}>
            <Form>
                <IfBox shouldShow={this.state.componentWidth > 1196}>
                    <Form.Group inline>
                        {this.renderSearchField()}
                        {this.renderDateRangePickerField()}
                        <ButtonGroup>
                            <IfBox shouldShow={this.props.statusFilterVisible}>
                                {this.renderStatusFilterField()}
                            </IfBox>
                            <IfBox shouldShow={this.props.partnerSelectionVisible}>
                                {this.renderPartnerSelectionField()}
                            </IfBox>
                            {this.renderFilterSelectionField()}
                            {this.state.addFilterModalOpen && <UserFilterAddModal open={this.state.addFilterModalOpen}
                                                                                  onSave={this.onSave}
                                                                                  onModalClose={() => this.setState({addFilterModalOpen: false})}
                                                                                  userFilter={this.state.userFilter}
                                                                                  filterId={undefined}
                                                                                  addFilter={true}
                                                                                  editFilter={false}/>}
                            {this.state.editFilterModalOpen && <UserFilterAddModal open={this.state.editFilterModalOpen}
                                                                                   onSave={this.onSave}
                                                                                   onModalClose={() => this.setState({editFilterModalOpen: false})}
                                                                                   userFilter={undefined}
                                                                                   filterId={this.state.filterId}
                                                                                   addFilter={false}
                                                                                   editFilter={true}/>}
                            <IfBox shouldShow={this.props.exportButtonVisible}>
                                {this.renderExportField()}
                            </IfBox>
                            {this.renderResetField()}
                            {this.renderRefreshSwitch()}
                        </ButtonGroup>
                    </Form.Group>
                </IfBox>
                <IfBox shouldShow={this.state.componentWidth <= 1196 && this.state.componentWidth > 875}>
                    <Form.Group inline>
                        {this.renderSearchField()}
                        {this.renderDateRangePickerField()}
                    </Form.Group>
                    <Form.Group inline>
                        <ButtonGroup>
                            <IfBox shouldShow={this.props.statusFilterVisible}>
                                {this.renderStatusFilterField()}
                            </IfBox>
                            <IfBox shouldShow={this.props.partnerSelectionVisible}>
                                {this.renderPartnerSelectionField()}
                            </IfBox>
                            {this.renderFilterSelectionField()}
                            {this.state.addFilterModalOpen && <UserFilterAddModal open={this.state.addFilterModalOpen}
                                                                                  onSave={this.onSave}
                                                                                  onModalClose={() => this.setState({addFilterModalOpen: false})}
                                                                                  userFilter={this.state.userFilter}
                                                                                  filterId={undefined}
                                                                                  addFilter={true}
                                                                                  editFilter={false}/>}
                            {this.state.editFilterModalOpen && <UserFilterAddModal open={this.state.editFilterModalOpen}
                                                                                   onSave={this.onSave}
                                                                                   onModalClose={() => this.setState({editFilterModalOpen: false})}
                                                                                   userFilter={undefined}
                                                                                   filterId={this.state.filterId}
                                                                                   addFilter={false}
                                                                                   editFilter={true}/>}
                            <IfBox shouldShow={this.props.exportButtonVisible}>
                                {this.renderExportField()}
                            </IfBox>
                            {this.renderResetField()}
                            {this.renderRefreshSwitch()}
                        </ButtonGroup>
                    </Form.Group>
                </IfBox>
                <IfBox shouldShow={this.state.componentWidth <= 875 && this.state.componentWidth > 575}>
                    {this.renderSearchField()}
                    {this.renderDateRangePickerField()}
                    <Form.Group inline>
                        <ButtonGroup>
                            <IfBox shouldShow={this.props.statusFilterVisible}>
                                {this.renderStatusFilterField()}
                            </IfBox>
                            <IfBox shouldShow={this.props.partnerSelectionVisible}>
                                {this.renderPartnerSelectionField()}
                            </IfBox>
                            {this.renderFilterSelectionField()}
                            {this.state.addFilterModalOpen && <UserFilterAddModal open={this.state.addFilterModalOpen}
                                                                                  onSave={this.onSave}
                                                                                  onModalClose={() => this.setState({addFilterModalOpen: false})}
                                                                                  userFilter={this.state.userFilter}
                                                                                  filterId={undefined}
                                                                                  addFilter={true}
                                                                                  editFilter={false}/>}
                            {this.state.editFilterModalOpen && <UserFilterAddModal open={this.state.editFilterModalOpen}
                                                                                   onSave={this.onSave}
                                                                                   onModalClose={() => this.setState({editFilterModalOpen: false})}
                                                                                   userFilter={undefined}
                                                                                   filterId={this.state.filterId}
                                                                                   addFilter={false}
                                                                                   editFilter={true}/>}
                            <IfBox shouldShow={this.props.exportButtonVisible}>
                                {this.renderExportField()}
                            </IfBox>
                            {this.renderResetField()}
                            {this.renderRefreshSwitch()}
                        </ButtonGroup>
                    </Form.Group>
                </IfBox>
                <IfBox shouldShow={this.state.componentWidth <= 575}>
                    {this.renderSearchField()}
                    {this.renderDateRangePickerField()}
                    <Form.Group inline>
                        <ButtonGroup>
                            <IfBox shouldShow={this.props.statusFilterVisible}>
                                {this.renderStatusFilterField()}
                            </IfBox>
                            <IfBox shouldShow={this.props.partnerSelectionVisible}>
                                {this.renderPartnerSelectionField()}
                            </IfBox>
                            {this.renderFilterSelectionField()}
                            {this.state.addFilterModalOpen && <UserFilterAddModal open={this.state.addFilterModalOpen}
                                                                                  onSave={this.onSave}
                                                                                  onModalClose={() => this.setState({addFilterModalOpen: false})}
                                                                                  userFilter={this.state.userFilter}
                                                                                  filterId={undefined} addFilter={true}
                                                                                  editFilter={false}/>}
                            {this.state.editFilterModalOpen && <UserFilterAddModal open={this.state.editFilterModalOpen}
                                                                                   onSave={this.onSave}
                                                                                   onModalClose={() => this.setState({editFilterModalOpen: false})}
                                                                                   userFilter={undefined}
                                                                                   filterId={this.state.filterId}
                                                                                   addFilter={false}
                                                                                   editFilter={true}/>}
                            <IfBox shouldShow={this.props.exportButtonVisible}>
                                {this.renderExportField()}
                            </IfBox>
                            {this.renderResetField()}
                            {this.renderRefreshSwitch()}
                        </ButtonGroup>

                    </Form.Group>
                </IfBox>
            </Form>
        </div>
    }

    renderSearchField() {
        return <Form.Field>
            <Hotkeys
                keyName="ctrl+f,enter"
                onKeyDown={this.onKeyDown}
            >
                <Input
                    placeholder={'Ctrl + F to search'}
                    ref={this.searchRef}
                    value={this.state.filterConditions.term}
                    onChange={this.onSearchTermChange}
                    onKeyDown={this.onInputKeyDown}
                    label={<Dropdown
                        selectOnBlur={false}
                        value={this.state.filterConditions.fieldsToSearch?.fieldsToSearchId}
                        options={this.getSearchDropdownFields()}
                        onChange={(event, data) => {
                            let field = this.state.fieldsToSearch.find(field => field.fieldsToSearchId == data.value)
                            this.setState({
                                filterConditions: {
                                    ...this.state.filterConditions, fieldsToSearch: field
                                }
                            }, () => {
                                if (this.state.filterConditions.term) this.applyFilterChange
                                else {
                                    this.updateURL(this.state.filterConditions)
                                    localStorage.setItem(LOGENIOS_TO_FILTER_CONDITIONS, JSON.stringify(this.state.filterConditions))
                                }
                            })
                        }
                        }
                    />
                    }
                    labelPosition='right'
                />
            </Hotkeys>
        </Form.Field>
    }

    renderDateRangePickerField() {
        const options = [
            {
                key: 'selection',
                text: 'Selection',
                value: 'selection'
            },
            {
                key: 'nextWorkingDay',
                text: 'NextWorkingDay',
                value: 'nextWorkingDay'
            },
            {
                key: 'tomorrow',
                text: 'Tomorrow',
                value: 'tomorrow'
            },
            {
                key: 'today',
                text: 'Today',
                value: 'today'
            },
            {
                key: 'lastWorkingDay',
                text: 'LastWorkingDay',
                value: 'lastWorkingDay'
            },
            {
                key: 'yesterday',
                text: 'Yesterday',
                value: 'yesterday'
            },
            {
                key: 'nextWeek',
                text: 'NextWeek',
                value: 'nextWeek'
            },
            {
                key: 'thisWeek',
                text: 'ThisWeek',
                value: 'thisWeek'
            },
            {
                key: 'lastWeek',
                text: 'LastWeek',
                value: 'lastWeek'
            },
            {
                key: 'thisMonth',
                text: 'ThisMonth',
                value: 'thisMonth'
            },
            {
                key: 'lastMonth',
                text: 'LastMonth',
                value: 'lastMonth'
            },
            {
                key: 'last7days',
                text: 'Last7Days',
                value: 'last7days'
            },
            {
                key: 'last30days',
                text: 'Last30Days',
                value: 'last30days'
            },
            {key: 'all', text: 'All', value: 'all'}
        ]
        const options2 = [
            {
                key: 'created',
                text: 'Created',
                value: 'CREATED'
            },
            {
                key: 'planBegin',
                text: 'PlanBegin',
                value: 'PLAN_BEGIN'
            },
            {
                key: 'planEnd',
                text: 'PlanEnd',
                value: 'PLAN_END'
            },
            {
                key: 'within',
                text: 'Within',
                value: 'WITHIN'
            },
        ]
        let fromDate = this.state.filterConditions.fromDateTime ? moment(this.state.filterConditions.fromDateTime) : undefined;
        let toDate = this.state.filterConditions.toDateTime ? moment(this.state.filterConditions.toDateTime) : undefined;
        return <Form.Field>
            <div className="ui basic buttons">
                <Dropdown
                    selectOnBlur={false}
                    className="ui left"
                    // style={"border-top-right-radius: 0px"}
                    basic button floating value={this.state.filterConditions.dateType}
                    placeholder={'Created'}
                    options={options2} onChange={this.onDateTypeChange}/>
                <div className={"ui basic button"} style={noPaddingStyle}>
                    <CustomDateRangePicker
                        id={"transportOrderListfilter"}
                        startDate={fromDate}
                        endDate={toDate}
                        onDatesSelected={this.onDatesSelected}
                    />
                </div>
                <Dropdown selectOnBlur={false}
                          className="ui" basic button floating
                          value={this.state.filterConditions.dateRangeSelection} options={options}
                          onChange={this.onPredefinedDateRangeSelect}/>
            </div>
        </Form.Field>
    }

    renderStatusFilterField() {
        let inTime = false
        let notInTime = false
        let all = false
        if (this.state.filterConditions.inTime == InTimeEnum.NotInTime) {
            notInTime = true

        } else if (this.state.filterConditions.inTime == InTimeEnum.InTime) {
            inTime = true
        } else if (this.state.filterConditions.inTime == InTimeEnum.All) {
            all = true
        }
        return <Popup
            trigger={<Button className={"ui button transportOrderFilterComponentFilterButtonGroupButton"}
                             primary={this.isStatusSet()}>
                <Icon name={'question circle outline'} style={{margin: '0'}}/>
            </Button>}
            on='click'
            hideOnScroll
            position='bottom center'
        >

            <h5>Select Status</h5>
            <Form.Field>
                <Checkbox
                    label={formatTransportOrderStatusEnum(TransportOrderStatus.StatusCodeEnum.CREATED)}
                    checked={this.state.filterConditions.includeStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeStatusEnum.CREATED) != undefined}
                    onChange={(evt, data) => {
                        console.log("onIncludeStatusChange: ", data)
                        this.onIncludeStatusChange(FetchTransportOrderListFilterRequest.IncludeStatusEnum.CREATED, data)
                    }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label={formatTransportOrderStatusEnum(TransportOrderStatus.StatusCodeEnum.DISPATCHING)}
                    checked={this.state.filterConditions.includeStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeStatusEnum.DISPATCHING) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeStatusChange(FetchTransportOrderListFilterRequest.IncludeStatusEnum.DISPATCHING, data)
                    }}/>
            </Form.Field>
            <Form.Field>

                <Checkbox
                    label={formatTransportOrderStatusEnum(TransportOrderStatus.StatusCodeEnum.ACCEPTED)}
                    checked={this.state.filterConditions.includeStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeStatusEnum.ACCEPTED) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeStatusChange(FetchTransportOrderListFilterRequest.IncludeStatusEnum.ACCEPTED, data)
                    }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    name={'StatusAcknowledgingItem'}
                    label={formatTransportOrderStatusEnum(TransportOrderStatus.StatusCodeEnum.ACKNOWLEDGING)}
                    checked={this.state.filterConditions.includeStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeStatusEnum.ACKNOWLEDGING) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeStatusChange(FetchTransportOrderListFilterRequest.IncludeStatusEnum.ACKNOWLEDGING, data)
                    }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label={formatTransportOrderStatusEnum(TransportOrderStatus.StatusCodeEnum.EXECUTING)}
                    checked={this.state.filterConditions.includeStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeStatusEnum.EXECUTING) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeStatusChange(FetchTransportOrderListFilterRequest.IncludeStatusEnum.EXECUTING, data)
                    }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox label={formatTransportOrderStatusEnum(TransportOrderStatus.StatusCodeEnum.DONE)}
                          checked={this.state.filterConditions.includeStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeStatusEnum.DONE) != undefined}
                          onChange={(evt, data) => {
                              this.onIncludeStatusChange(FetchTransportOrderListFilterRequest.IncludeStatusEnum.DONE, data)
                          }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label={formatTransportOrderStatusEnum(TransportOrderStatus.StatusCodeEnum.CANCELED)}
                    checked={this.state.filterConditions.includeStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeStatusEnum.CANCELED) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeStatusChange(FetchTransportOrderListFilterRequest.IncludeStatusEnum.CANCELED, data)
                    }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label={formatTransportOrderStatusEnum(TransportOrderStatus.StatusCodeEnum.REJECTED)}
                    checked={this.state.filterConditions.includeStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeStatusEnum.REJECTED) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeStatusChange(FetchTransportOrderListFilterRequest.IncludeStatusEnum.REJECTED, data)
                    }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label={formatTransportOrderStatusEnum(TransportOrderStatus.StatusCodeEnum.INPUTMISSING)}
                    checked={this.state.filterConditions.includeStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeStatusEnum.INPUTMISSING) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeStatusChange(FetchTransportOrderListFilterRequest.IncludeStatusEnum.INPUTMISSING, data)
                    }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label={"StatusMessageMissing"}
                    checked={this.state.validChecked} onChange={this.onValidChange}

                />
            </Form.Field>
            <Form.Field>
                <Checkbox label={"InTime"}
                          checked={inTime} onChange={(evt) => {
                    this.onInTimeChange(InTimeEnum.InTime)
                }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label={"NotInTime"}
                    checked={notInTime} onChange={(evt) => {
                    this.onInTimeChange(InTimeEnum.NotInTime)
                }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label={"withoutVehicle"}
                    checked={this.state.withoutVehicleChecked} onChange={(evt, data) => {
                    this.onWithoutVehicle(data)
                }}/>
            </Form.Field>
            <h5>Stop Status</h5>

            <Form.Field>
                <Checkbox
                    label={formatStopLocationStatusEnum(StopLocationStatus.StatusCodeEnum.PLANNED)}
                    checked={this.state.filterConditions.includeStopLocationStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeStopLocationStatusEnum.PLANNED) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeStopLocationStatusChange(FetchTransportOrderListFilterRequest.IncludeStopLocationStatusEnum.PLANNED, data)
                    }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label={formatStopLocationStatusEnum(StopLocationStatus.StatusCodeEnum.APPROACHING)}
                    checked={this.state.filterConditions.includeStopLocationStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeStopLocationStatusEnum.APPROACHING) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeStopLocationStatusChange(FetchTransportOrderListFilterRequest.IncludeStopLocationStatusEnum.APPROACHING, data)
                    }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label={formatStopLocationStatusEnum(StopLocationStatus.StatusCodeEnum.ARRIVED)}
                    checked={this.state.filterConditions.includeStopLocationStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeStopLocationStatusEnum.ARRIVED) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeStopLocationStatusChange(FetchTransportOrderListFilterRequest.IncludeStopLocationStatusEnum.ARRIVED, data)
                    }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label={formatStopLocationStatusEnum(StopLocationStatus.StatusCodeEnum.DEPARTED)}
                    checked={this.state.filterConditions.includeStopLocationStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeStopLocationStatusEnum.DEPARTED) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeStopLocationStatusChange(FetchTransportOrderListFilterRequest.IncludeStopLocationStatusEnum.DEPARTED, data)
                    }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label={formatStopLocationStatusEnum(StopLocationStatus.StatusCodeEnum.CANCELED)}
                    checked={this.state.filterConditions.includeStopLocationStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeStopLocationStatusEnum.CANCELED) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeStopLocationStatusChange(FetchTransportOrderListFilterRequest.IncludeStopLocationStatusEnum.CANCELED, data)
                    }}/>
            </Form.Field>


            <h5>Freight status</h5>
            <Form.Field>
                <Checkbox
                    label={formatFreightStatusEnum(FreightStatus.StatusCodeEnum.PLANNED)}
                    checked={this.state.filterConditions.includeFreightStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeFreightStatusEnum.PLANNED) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeFreightStatusChange(FetchTransportOrderListFilterRequest.IncludeFreightStatusEnum.PLANNED, data)
                    }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label={formatFreightStatusEnum(FreightStatus.StatusCodeEnum.LOADING)}
                    checked={this.state.filterConditions.includeFreightStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeFreightStatusEnum.LOADING) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeFreightStatusChange(FetchTransportOrderListFilterRequest.IncludeFreightStatusEnum.LOADING, data)
                    }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label={formatFreightStatusEnum(FreightStatus.StatusCodeEnum.LOADED)}
                    checked={this.state.filterConditions.includeFreightStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeFreightStatusEnum.LOADED) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeFreightStatusChange(FetchTransportOrderListFilterRequest.IncludeFreightStatusEnum.LOADED, data)
                    }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label={formatFreightStatusEnum(FreightStatus.StatusCodeEnum.UNLOADING)}
                    checked={this.state.filterConditions.includeFreightStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeFreightStatusEnum.UNLOADING) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeFreightStatusChange(FetchTransportOrderListFilterRequest.IncludeFreightStatusEnum.UNLOADING, data)
                    }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label={formatFreightStatusEnum(FreightStatus.StatusCodeEnum.DELIVERED)}
                    checked={this.state.filterConditions.includeFreightStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeFreightStatusEnum.DELIVERED) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeFreightStatusChange(FetchTransportOrderListFilterRequest.IncludeFreightStatusEnum.DELIVERED, data)
                    }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label={formatFreightStatusEnum(FreightStatus.StatusCodeEnum.REJECTED)}
                    checked={this.state.filterConditions.includeFreightStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeFreightStatusEnum.REJECTED) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeFreightStatusChange(FetchTransportOrderListFilterRequest.IncludeFreightStatusEnum.REJECTED, data)
                    }}/>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label={formatFreightStatusEnum(FreightStatus.StatusCodeEnum.NOTLOADED)}
                    checked={this.state.filterConditions.includeFreightStatus?.find(s => s == FetchTransportOrderListFilterRequest.IncludeFreightStatusEnum.NOTLOADED) != undefined}
                    onChange={(evt, data) => {
                        this.onIncludeFreightStatusChange(FetchTransportOrderListFilterRequest.IncludeFreightStatusEnum.NOTLOADED, data)
                    }}/>
            </Form.Field>

        </Popup>
    }

    renderPartnerSelectionField() {
        return <Popup trigger={<Button className={"ui button transportOrderFilterComponentFilterButtonGroupButton"}
                                       primary={this.state.selectedPartners?.length > 0}>
            <Icon name={'users'} style={{margin: '0'}}/>
        </Button>} on='click' hideOnScroll position='bottom center'>
            <h5>Select Partner</h5>
            {this.renderPartnerOptions()}
        </Popup>
    }

    onSave = async (fl: UserFilterList) => {
        this.setState({filters: fl})
    }
    createUserFilter() {
        let userFilter: UserFilter = {
            filterId: uuid(),
            companyId: authentication.company?._id,
            userId: authentication.user?._id,
            searchText: this.state.filterConditions.term,
            searchTextType: this.state.filterConditions.fieldsToSearch?.fieldsToSearchId,
            dateType: this.state.filterConditions.dateType,
            genericDateRange: this.state.filterConditions.dateRangeSelection,
            dateFrom: this.calculateDateRange(this.state.filterConditions.dateRangeSelection).start,
            dateTo: this.calculateDateRange(this.state.filterConditions.dateRangeSelection).end,
            transportOrderStatus: this.state.filterConditions.includeStatus,
            includeFreightStatus: this.state.filterConditions.includeFreightStatus,
            includeStopLocationStatus: this.state.filterConditions.includeStopLocationStatus,
            partnerIds: this.state.filterConditions.partners?.map(p => p.companyId)
        } as unknown as UserFilter
        return userFilter
    }

    renderFilterSelectionField() {

        return <><IfBox
            shouldShow={this.state.filters?.userFilterList && this.state.filters?.userFilterList?.length > 0}>
            <Popup content={"Select Filters"} trigger={<Dropdown
                icon={'filter'}
                style={{padding: '13', borderRadius: '0'}}
                className='button e-caret-hide iconInDropDown transportOrderFilterComponentFilterButtonGroupButton'
                primary={this.state.selectedPartners?.length > 0}
                forceSelection={false}
                selectOnBlur={false}
            >
                <Dropdown.Menu>
                    <Button className={'small primary'} onClick={() => this.setState({
                        addFilterModalOpen: true,
                        userFilter: this.createUserFilter()
                    })} style={{margin: '5px', borderRadius: '10px', textAlign: 'center'}}>Add new
                        filter</Button>
                    <Dropdown.Divider style={{margin: '0'}}/>
                    {this.state.filters?.userFilterList?.map((f: UserFilter) => {
                        return <Dropdown.Item onClick={(event, data) => {
                            this.setState({selectedFilterId: data.value?.toString()});
                            console.log("selected filter", data.value?.toString());
                            let filter = this.state.filters?.userFilterList?.find(f => f.filterId == data.value?.toString());
                            let filterSearchTextType = filter?.searchTextType ? filter?.searchTextType : 0;
                            let fieldsToSearch = this.getFieldsToSearch().find((field: FieldsToSearchDropdownEntry) => field.fieldsToSearchId == filterSearchTextType
                            );
                            let selectedPartner = this.state.partners.filter((p: PartnerQueryObject) => p?.companyId == null ? false : filter?.partnerIds?.includes(p.companyId));

                            let filterCondition = {
                                term: filter?.searchText,
                                fieldsToSearch: fieldsToSearch,
                                dateType: filter?.dateType,
                                dateRangeSelection: filter?.genericDateRange,
                                fromDateTime: filter?.dateFrom,
                                toDateTime: filter?.dateTo,
                                includeStatus: filter?.transportOrderStatus,
                                includeFreightStatus: filter?.includeFreightStatus,
                                includeStopLocationStatus: filter?.includeStopLocationStatus,
                                partners: selectedPartner,
                                inTime: InTimeEnum.All
                            } as unknown as FilterConditions;
                            this.setState({
                                filterConditions: filterCondition,
                                selectedPartners: selectedPartner
                            }, () => this.applyFilterChange(false));
                            console.log("filter conditions", filterCondition);

                        }} value={f.filterId} text={<Grid>
                            <GridRow columns={2}>
                                <Popup content={f.name} trigger={<GridColumn width={13} style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {f.name}
                                </GridColumn>}/>
                                <GridColumn className={'pad0'} textAlign={"right"} width={3}>
                                    {/*<Icon name='edit' color={'teal'} onClick={() => this.setState({*/}
                                    {/*    editFilterModalOpen: true,*/}
                                    {/*    filterId: f.filterId*/}
                                    {/*})}/>*/}
                                    <Icon name='delete' color={'red'} onClick={async () => {
                                        let authHeader = backend.withTokenAuthHeader(authentication.token);
                                        let latestList = await backend.companyApi.deleteFilter(f.filterId, authentication.user?._id, authHeader);
                                        this.setState({filters: latestList});
                                        this.resetFilter();
                                    }}/>
                                </GridColumn>
                            </GridRow>
                        </Grid>} key={f.filterId}/>;
                    })}
                </Dropdown.Menu>
            </Dropdown>}/>
        </IfBox><IfBox
            shouldShow={this.state.filters?.userFilterList?.length == 0}>
            <Popup content={"Add Filter"} trigger={<Button
                onClick={() => this.setState({
                    addFilterModalOpen: true,
                    userFilter: this.createUserFilter()
                })}
                className={'ui icon button transportOrderFilterComponentFilterButtonGroupButton'}
            ><Icon name={'plus'}/></Button>}/>
        </IfBox></>
    }

    renderExportField() {
        return <Popup content={"ExportReportButton"} trigger={<Dropdown
            icon={'table'}
            className='button e-caret-hide iconInDropDown transportOrderFilterComponentFilterButtonGroupButton'
            forceSelection={false}
            selectOnBlur={false}
            loading={this.state.isExcelExportRunning}
            // onChange={(evt, data) => this.onReportRequest(evt, data)}
        >
            <Dropdown.Menu>
                <Dropdown.Item onClick={async () => await this.exportExcelList("OneLinePerFreight")}
                               value={"OneLinePerFreight"} key={"freight"}
                               text={"Freight"}/>

                <Dropdown.Item onClick={async () => await this.exportExcelList("OneLinePerTransport")}
                               value={"OneLinePerTransport"} key={"delivery"}
                               text={"Delivery"}/>

            </Dropdown.Menu>
        </Dropdown>}/>
    }

    renderResetField() {
        return <Form.Field>
            <Popup content={"Reset Filters"} trigger={
                <Button className={'transportOrderFilterComponentFilterButtonGroupButton'} id={"resetButton"} primary={!this.filterEmpty()}
                        onClick={(evt) => {
                    this.resetFilter()
                }}>
                    <Icon name={'undo'} style={{margin: '0'}}/>
                </Button>}/>

        </Form.Field>
    }

    renderRefreshSwitch() {
        return (
            <Popup content={"AutoRefresh"} trigger={
                <Button  className={'transportOrderFilterComponentFilterButtonGroupButton'} toggle active={this.state.autoReloadCheck}
                        onClick={() => {
                            this.refreshList()
                        }}>
                    <Icon name={'sync'} style={{margin: '0'}}/>
                </Button>}/>


        )
    }

    async onReportRequest(evt: SyntheticEvent<HTMLElement>, data: DropdownProps) {
        // @ts-ignore
        await this.exportExcelList(data.value)
    }

    mutexChecked(statuscode?: TransportOrderStatus.StatusCodeEnum) {
        if (statuscode == TransportOrderStatus.StatusCodeEnum.CREATED) {
            this.setState({
                createdChecked: true, dispatchedChecked: false, acceptedChecked: false, acknowledgingChecked: false,
                executingChecked: false, doneChecked: false, canceledChecked: false, rejectedChecked: false,
                inputMissingChecked: false
            })
        } else if (statuscode == TransportOrderStatus.StatusCodeEnum.DISPATCHING) {
            this.setState({
                createdChecked: false, dispatchedChecked: true, acceptedChecked: false, acknowledgingChecked: false,
                executingChecked: false, doneChecked: false, canceledChecked: false, rejectedChecked: false,
                inputMissingChecked: false
            })
        } else if (statuscode == TransportOrderStatus.StatusCodeEnum.ACCEPTED) {
            this.setState({
                createdChecked: false, dispatchedChecked: false, acceptedChecked: true, acknowledgingChecked: false,
                executingChecked: false, doneChecked: false, canceledChecked: false, rejectedChecked: false,
                inputMissingChecked: false
            })
        } else if (statuscode == TransportOrderStatus.StatusCodeEnum.ACKNOWLEDGING) {
            this.setState({
                createdChecked: false, dispatchedChecked: false, acceptedChecked: false, acknowledgingChecked: true,
                executingChecked: false, doneChecked: false, canceledChecked: false, rejectedChecked: false,
                inputMissingChecked: false
            })
        } else if (statuscode == TransportOrderStatus.StatusCodeEnum.EXECUTING) {
            this.setState({
                createdChecked: false, dispatchedChecked: false, acceptedChecked: false, acknowledgingChecked: false,
                executingChecked: true, doneChecked: false, canceledChecked: false, rejectedChecked: false,
                inputMissingChecked: false
            })
        } else if (statuscode == TransportOrderStatus.StatusCodeEnum.DONE) {
            this.setState({
                createdChecked: false, dispatchedChecked: false, acceptedChecked: false, acknowledgingChecked: false,
                executingChecked: false, doneChecked: true, canceledChecked: false, rejectedChecked: false,
                inputMissingChecked: false
            })
        } else if (statuscode == TransportOrderStatus.StatusCodeEnum.CANCELED) {
            this.setState({
                createdChecked: false, dispatchedChecked: false, acceptedChecked: false, acknowledgingChecked: false,
                executingChecked: false, doneChecked: false, canceledChecked: true, rejectedChecked: false,
                inputMissingChecked: false
            })
        } else if (statuscode == TransportOrderStatus.StatusCodeEnum.REJECTED) {
            this.setState({
                createdChecked: false, dispatchedChecked: false, acceptedChecked: false, acknowledgingChecked: false,
                executingChecked: false, doneChecked: false, canceledChecked: false, rejectedChecked: true,
                inputMissingChecked: false
            })
        } else if (statuscode == TransportOrderStatus.StatusCodeEnum.INPUTMISSING) {
            this.setState({
                createdChecked: false, dispatchedChecked: false, acceptedChecked: false, acknowledgingChecked: false,
                executingChecked: false, doneChecked: false, canceledChecked: false, rejectedChecked: false,
                inputMissingChecked: true
            })
        } else {
            this.setState({
                createdChecked: false, dispatchedChecked: false, acceptedChecked: false, acknowledgingChecked: false,
                executingChecked: false, doneChecked: false, canceledChecked: false, rejectedChecked: false,
                inputMissingChecked: false
            })
            console.log("No valid statuscode to set.");
        }
    }

    onIncludeStatusChange(statusCode: FetchTransportOrderListFilterRequest.IncludeStatusEnum, data: any) {
        let includeStatus: FetchTransportOrderListFilterRequest.IncludeStatusEnum[] | undefined = this.state.filterConditions.includeStatus ?? []
        if (data.checked) {
            includeStatus?.push(statusCode)
        } else {
            includeStatus = includeStatus?.filter(status => status !== statusCode)
            if (includeStatus.length < 1) {
                includeStatus = undefined
            }
        }
        this.setState({
            filterConditions: {
                ...this.state.filterConditions,
                includeStatus: includeStatus
            }
        }, this.applyFilterChange)
    }

    onIncludeStopLocationStatusChange(statusCode: FetchTransportOrderListFilterRequest.IncludeStopLocationStatusEnum, data: any) {
        let includeStatus: FetchTransportOrderListFilterRequest.IncludeStopLocationStatusEnum[] | undefined = this.state.filterConditions.includeStopLocationStatus ?? []
        if (data.checked) {
            includeStatus?.push(statusCode)
        } else {
            includeStatus = includeStatus?.filter(status => status !== statusCode)
            if (includeStatus.length < 1) {
                includeStatus = undefined
            }
        }
        this.setState({
            filterConditions: {
                ...this.state.filterConditions,
                includeStopLocationStatus: includeStatus
            }
        }, this.applyFilterChange)
    }

    onIncludeFreightStatusChange(statusCode: FetchTransportOrderListFilterRequest.IncludeFreightStatusEnum, data: any) {
        let includeStatus: FetchTransportOrderListFilterRequest.IncludeFreightStatusEnum[] | undefined = this.state.filterConditions.includeFreightStatus ?? []
        if (data.checked) {
            includeStatus?.push(statusCode)
        } else {
            includeStatus = includeStatus?.filter(status => status !== statusCode)
            if (includeStatus.length < 1) {
                includeStatus = undefined
            }
        }
        this.setState({
            filterConditions: {
                ...this.state.filterConditions,
                includeFreightStatus: includeStatus
            }
        }, this.applyFilterChange)
    }

    onPartnerChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        const selectedPartners: Company[] = this.state.selectedPartners;
        if (data.checked == true) {
            const selectedPartner: Company | undefined = this.state.partners.find((p: PartnerQueryObject) => p.name + "-" + p.companyId === data.value)
            if (selectedPartner) {
                selectedPartners.push(selectedPartner)
                this.setState({
                    filterConditions: {
                        ...this.state.filterConditions,
                        partners: selectedPartners
                    }
                }, this.applyFilterChange)
            }
        } else {
            const removePartnerIndex: number = this.state.selectedPartners.findIndex((p: PartnerQueryObject) => p.name + "-" + p.companyId === data.value)
            if (removePartnerIndex != undefined || removePartnerIndex != null) {
                selectedPartners.splice(removePartnerIndex, 1)
                this.setState({
                    filterConditions: {
                        ...this.state.filterConditions,
                        partners: selectedPartners
                    }
                }, this.applyFilterChange)
            }
        }
    }

    private renderPartnerOptions() {
        return <div>
            {this.state.partners.map((p: PartnerQueryObject) => {
                    return p.companyId && p.companyId !== "" && p.companyId != authentication.company?._id &&
                        <Form.Field key={uuid()}>
                            <Checkbox label={p.name || p.companyId} name='checkboxPartnerGroup'
                                      value={p.name + "-" + p.companyId} key={uuid()}
                                      checked={this.state.selectedPartners.findIndex((partner: PartnerQueryObject) => (partner.name === p.name || partner.companyId === p.companyId)) > -1}
                                      onChange={this.onPartnerChange}/>
                        </Form.Field>
                }
            )}
        </div>
    }
}
