import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Checkbox} from "semantic-ui-react";
import {IfBox} from "../../../../style/if";
import AceEditor, {ICommand} from "react-ace";
import {ChatGptHelpModal} from "../ScriptConverterPlayground/ChatGptHelpModal";
import {grayLogApi} from "../../../../util/grayLog/grayLogApi";
import {currentSelectedXRequestId} from "../ScriptConverterPlayground/ScriptConverterPlayground";
import {FieldDescriptionView} from "./rightSide/FieldDescriptionView";
import {KotlinScriptIde} from "../../../../util/kotlinPlayground/KotlinScriptIde";

export interface ScriptModifierProps {
    script: string | null
    dispatcher: (script: string) => void
    path: string
    readOnly: boolean
    fieldDescriptionEnabled: boolean
    defaultDescription: string | null
    saveKeyListener: (e: any) => void
    directSave?: boolean
}

export function ScriptModifier(props: React.PropsWithChildren<ScriptModifierProps>) {

    const [script, _setScript] = useState<string>(props.script)
    const [classicScriptEditor, setClassicScriptEditor] = useState(true)
    const [sidebarCollapsed, setSidebarCollapsed] = useState(true)
    const [showAiModal, setShowAiModal] = useState(false)
    const [errorLogs, setErrorLogs] = useState("")


    function onKeyDown(e) {
        if (e.keyCode === 83 && e.ctrlKey) {
            e.preventDefault();
            handleSave(script)
        }
    }

    function setScript(data) {
        console.log("[ScriptModifier] - setting script: " + data)
        _setScript(data)
        /*if (data == null || data == "null") {
            console.log("[ScriptModifier] - NOT setting script to null")
            return
        }
*/
        if (props.directSave) {
            handleSave(data)
        }
    }

    useEffect(() => {
        console.log("[ScriptModifier] - mounting")
        console.log("[ScriptModifier] - script: " + script)
        console.log("[ScriptModifier] - props.script: " + props.script)

        if (props.script != script) {
            setScript(props.script)
        }
    }, [props.script])

    useEffect(() => {


        document.removeEventListener('keydown', props.saveKeyListener);
        window.addEventListener('keydown', onKeyDown);

        return (): void => {
            console.log("[ScriptModifier] - unmounting")
            window.removeEventListener('keydown', onKeyDown);
            document.addEventListener('keydown', props.saveKeyListener);
        };
    }, []);

    // stop ctrl+D to delete all changes
    let commands = [] as ICommand[]
    commands.push({
        name: "stop_ctrl+D",
        bindKey: {win: "Ctrl-D", mac: "Command-D"},
        exec: function (editor) {
        }
    } as ICommand)


    function handleSave(currentScript ) {
        console.log("[ScriptModifier] - saving script: " + currentScript)
        props.dispatcher(currentScript)
    }

    function handleDiscard() {
        console.log("[ScriptModifier] - discard script: " + script)
        props.dispatcher(props.script)
    }


    function drawLeftSide() {
        return <div
            key={"ScriptModifierDrawLeftSide"}
            id={"ScriptModifierDrawLeftSide"}
            style={{
                maxWidth: "100%",
                display: "flex",
                flexDirection: "column",
                flex: "1",
            }}>
            <div style={{
                display: "flex",
            }}>
                <Checkbox
                    toggle={true}
                    checked={classicScriptEditor}
                    size="mini"
                    onClick={() => {
                        setClassicScriptEditor(!classicScriptEditor)
                    }}
                    floated="left"
                    label={"classic"}
                />
                <div style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    paddingLeft: 100
                }}>
                    <span style={{fontWeight: "bold", paddingRight: 10}}>ScriptPath: </span>

                    <span style={{
                        flexWrap: "wrap",
                        overflowWrap: "anywhere",
                        overflow: "visible"
                    }}> {props.path}</span>
                </div>
            </div>
            {/* <IfBox shouldShow={false}>

                <Icon name='warning sign' color='red' size={"large"}/>
                <span style={{color: 'red'}}>WARNING: TypeScript is not supported by the converter yet! </span>
                <CustomTypeScriptEditor
                    value={script}
                    onChange={(e) => setScript(e)}
                    width="100%"
                    height="900px"
                />
            </IfBox>
            */}

            <IfBox shouldShow={!classicScriptEditor}>
                <KotlinScriptIde
                    key={props.path}
                    scriptWithPath={{script: props.script, path: props.path}}
                />
            </IfBox>

            <IfBox shouldShow={classicScriptEditor}>
                <AceEditor
                    name='scriptAce'
                    style={{
                        flex: "1",
                        display: "flex",
                    }}
                    theme="monokai"
                    mode="groovy"
                    value={script}
                    width="100%"
                    onChange={(e) => {
                        setScript(e)
                    }}
                    readOnly={props.readOnly}
                    commands={commands}
                />
            </IfBox>

            <IfBox shouldShow={!props.readOnly && !props.directSave}>
                <div style={{flexDirection: "row"}}>
                    <Button id='SaveScriptButton' onClick={() => handleSave(script)}>Save</Button>
                    <Button id='DiscardScriptButton' onClick={() => handleDiscard()}>Discard</Button>
                    <Button id='DeleteScriptButton' onClick={() => setScript(null)}>Delete</Button>
                </div>
            </IfBox>
        </div>

    }

    async function searchLogs() {
        console.log("searchLogs")
        let query = '"' + currentSelectedXRequestId.value + '"'

        let response = await grayLogApi.search(300, query)

        return response.messages.reverse()

    }

    async function buildErrorLogs() {

        let baseLogs = await searchLogs()
        console.log("retrieved baseLogs from graylog: ", baseLogs?.length)


        setErrorLogs(baseLogs?.filter((log) => {
                return log.toLowerCase().includes("error")
            }).join("\n")
        )
    }

    function drawRightSide() {
        return <div style={{paddingTop: "21px"}}>
            <IfBox shouldShow={props.fieldDescriptionEnabled}>
                <IfBox shouldShow={!sidebarCollapsed}>
                    <div style={{width: "300px"}}>
                        <Button icon='angle double right' onClick={() => setSidebarCollapsed(true)}/>
                        <FieldDescriptionView/>
                    </div>
                </IfBox>
                <IfBox shouldShow={sidebarCollapsed}>
                    <Button icon='angle double left' onClick={() => setSidebarCollapsed(false)}/>
                    <br/>
                    <Button
                        icon='magic'
                        onClick={() => {
                            buildErrorLogs().then((logs) => {
                                setShowAiModal(true)
                            })
                        }}
                    />
                    {showAiModal && <ChatGptHelpModal
                        isOpen={showAiModal}
                        onClose={(update, newScript) => {
                            setShowAiModal(false)
                            if (update) {
                                setScript(newScript)
                            }
                        }}
                        script={script}
                        errorLogs={errorLogs}
                    />}
                </IfBox>
            </IfBox>
        </div>
    }


    if (props.path == "" || props.path == null) return <div
        id={"ScriptModifier"}
    >
        <h3>No Script Path given!</h3>
    </div>

    return <div id={"ScriptModifier"} style={{
        height: '100%',
        padding: "1px",
        flexDirection: "row-reverse",
        //flexWrap: "wrap",
        display: "flex",
    }}>
        <div>
            {drawRightSide()}
        </div>
        <div style={{
            flex: "available",
            flexGrow: 1,
            display: "flex",
            flexWrap: "wrap",
            overflowWrap: "anywhere",
            overflow: "scroll"
        }}>
            {drawLeftSide()}
        </div>
    </div>
}