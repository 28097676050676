import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Button, ButtonGroup, Dropdown, DropdownItem, DropdownMenu, Icon, Label, Menu, Popup} from "semantic-ui-react";
import {IfBox} from "../style/if";
import {TriggerTransportOrderConfigTestsRequest, UpdateCompanyConfigRequestWrapper} from "../../generated";
import {backend} from "../../xconvert-backend";
import {checkIfConfigChanged} from "../style/page_with_sidebar";
import {ConfigContext} from "../main/context/ConfigContext";
import {authentication} from "../../authentication";
import {DiscardModal} from "../main/pages/configChange/configEdit/modals/DiscardModal";
import Notifications, {notify} from 'react-notify-toast';
import {UpAndDownloadModal} from "../main/pages/configChange/configEdit/modals/UpAndDownloadModal";
import {TestConfigModal} from "../main/pages/configChange/configEdit/modals/TestConfigModal";
import {ChangeConfigModal} from "../main/pages/configChange/configEdit/modals/ChangeConfigModal";
import {SaveAsDraftModal} from "../main/pages/configChange/configEdit/modals/SaveAsDraftModal";
import {ShowDefaultLisConfigModal} from "../main/pages/configChange/configEdit/modals/DefaultLisConfigModal";
import {ExtractToSpecificConfigModal} from "../main/pages/configChange/configEdit/modals/ExtractToSpecificConfigModal";
import {ConflictModal} from "../main/pages/configChange/configEdit/modals/ConflictModal";
import {configStorage} from "../../ConfigStorage";
import {loadWorkingConfig, updateConfig, workingConfig} from "../main/pages/configChange/ConfigSignal";


const boilerplateStartMarker = "\n//####################### Script begins here (do NOT delete this line)\n"
const boilerplateEndMarker = "\n//####################### Script ends here (do NOT delete this line)\n"

export interface ConfigMenuButtonsProps {
    configOutOfSync: boolean
}

export function ConfigMenuButtons(props: React.PropsWithChildren<ConfigMenuButtonsProps>) {
    const context = useContext(ConfigContext)
    const [showUpAndDownloadModal, setShowUpAndDownloadModal] = useState(false);
    const [showDefaultLisConfig, setShowDefaultLisConfig] = useState(false);
    const [extractToSpecificConfig, setExtractToSpecificConfig] = useState(false);
    const [showConflictModal, setShowConflictModal] = useState(false);
    const [showChanges, setShowChanges] = useState(false);
    const [showSaveAsDraft, setShowSaveAsDraft] = useState(false);
    const [validJson, setValidJson] = useState(true);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isLoadingSaveAsDraft, setIsLoadingSaveAsDraft] = useState(false);
    const [showDiscardModal, setShowDiscardModal] = useState(false);
    const [testConfig, setTestConfig] = useState(false);
    const [reloadPage, setReloadPage] = useState(false);
    const [verifyResponse, setVerifyResponse] = useState(null);
    const [failedTests, setFailedTests] = useState([]);
    const [auth, setAuth] = useState(null);
    const [currentCompanyConfig, setCurrentCompanyConfig] = useState(null);

    const [dispatchFunction] = useState(() => async (validationObject) => {
        let valid = validationObject.validation.isValid
        if (valid) {
            await configStorage.deleteCompany(context.companyId)
            let newConfig = await loadWorkingConfig(context.companyId)
            console.log("dispatchFunction: loaded working config")
            setCurrentCompanyConfig(newConfig)

        }
        setTestConfig(true);
        setReloadPage(valid);
    })

    const [updateSwitch, setUpdateSwitch] = useState(false);
    const [rerender] = useState(() => () => {
        console.log("triggered onCloseCompareModal");
        setUpdateSwitch(prevState => !prevState);
    });

    useEffect(() => {
        setAuth(backend.withTokenAuthHeader(authentication.token));

        const keydownHandler = (e) => {
            if (e.keyCode === 83 && e.ctrlKey) {
                console.log("SAVE & CLOSE MODAL!!!");
                e.preventDefault();
                handleSave();
            }
        };

        document.addEventListener('keydown', keydownHandler);

        return () => {
            document.removeEventListener('keydown', keydownHandler);
        };

    }, []);

    useEffect(() => {
        if (showChanges || showSaveAsDraft) {
            reloadCurrentConfigFromStorage()
        }
    }, [showChanges, showSaveAsDraft]);

    function reloadCurrentConfigFromStorage() {
        configStorage.getElement(context.companyId).then((element) => {
            setCurrentCompanyConfig(element.unchangedConfig)
        })
    }

    function isSaveEnabled() {
        if (!workingConfig.value) {
            if (!validJson) {
                return true
            }
        }
        return false
    }

    function handleChange(value: string) {
        try {
            JSON.parse(value)
            setValidJson(true)
        } catch (e) {
            setValidJson(false)
        }
    }

    async function handleDiscardClosed(discard: boolean) {
        if (discard) {
            try {
                const freshConfig = await backend.internalApi.fetchCompanyConfiguration(context.companyId, auth)
                const configAsString = JSON.stringify(freshConfig, null, 4);
                handleChange(configAsString);
                await updateConfig(configAsString, true, true);
                window.location.reload();
            } catch (error) {
                console.error('Error handling discard:', error);
            }
        }
        setShowDiscardModal(false);
    }

    async function formatConfig() {
        try {
            setIsLoadingSave(true);
            const request = {} as UpdateCompanyConfigRequestWrapper
            request.configAsJson = workingConfig.value

            const response = await backend.internalApi.formatConfig(request, auth)

            if (response.errorMessage == null) {
                await updateConfig(JSON.stringify(response.config, null, 4), true, false)
            } else {
                notify.show("ERROR: " + response.errorMessage, 'error', 5000, '#fc0303')
            }
        } catch (error) {
            console.error('Error formatting config:', error);
        } finally {
            setIsLoadingSave(false);
        }
    }

    async function handleValidate() {
        await validateConfig()
        setTestConfig(true);
    }

    async function validateConfig(): Promise<boolean> {
        console.log("validate config in backend")
        setVerifyResponse(null);
        const request = {} as UpdateCompanyConfigRequestWrapper
        request.configAsJson = workingConfig.value
        return backend.internalApi.validateCompanyConfiguration(context.companyId, request, auth).then((response) => {
            setVerifyResponse(response);
            return response.verifyResponse.isValid
        }).catch((error) => {
            setVerifyResponse(error)
            return false
        })
    }

    async function handleSave() {
        try {
            setIsLoadingSave(true);
            let conflict = await checkIfConfigChanged(context.companyId)
            if (conflict) {
                setShowConflictModal(true);
            } else {
                let valid = await validateConfig()
                if (!valid) {
                    setTestConfig(true);
                } else {
                    await triggerActiveTest()
                    setShowChanges(true);
                }
            }
        } catch (error) {
            console.error('Error handling save:', error);
        } finally {
            setIsLoadingSave(false);
        }
    }

    function handleSaveAsDraft() {
        setIsLoadingSaveAsDraft(true);
        setShowSaveAsDraft(true);
    }

    async function triggerActiveTest() {
        // trigger all active transportOrderConfigTests

        // get all tests for current company

        const auth = backend.withTokenAuthHeader(authentication.token)
        const tests = (await backend.internalApi.listTransportOrderConfigTests(context.companyId, null, null, null, null, null, auth)).tests

        // make a list with the ids of the active tests
        const activeTests = tests.filter(test => test.active);
        const ids = activeTests.map(test => test._id);

        // trigger the active tests
        const request: TriggerTransportOrderConfigTestsRequest = {
            config: JSON.parse(workingConfig.value),
            companyId: context.companyId,
            testIds: ids
        }

        try {
            let result = (await backend.internalApi.triggerTransportOrderTests(request, auth))
            // check if there are any results not positiv
            const failedTest = []
            Object.values(result.results).map(res => {
                if (!res.success) {
                    console.log(`Test: ${res.transportOrderConfigTestId} failed!`);
                    failedTest.push({
                        "test": activeTests.find(test => {
                            return test._id == res.transportOrderConfigTestId
                        }), "result": res
                    })
                } else {
                    console.log("Test: " + res.transportOrderConfigTestId + " success!")
                }
            })
            console.log("tests failed: " + failedTest.length)
            setFailedTests(failedTest);
            return failedTest.length == 0
        } catch (e) {
            console.log("tests failed catastrophically: " + e)
            const artificiallyFailedTest = {
                test: {
                    name: "Running the tests resulted in a critical error! Please check the logs for more information!",
                    result: {
                        expected: "This should not have happened! Please check the logs for more information!",
                        fullResult: e.message
                    }
                }
            }
            setFailedTests([artificiallyFailedTest]);
            return false;
        }
    }

    async function loadDirectory(currentDirHandle: any) {

        let object = {}
        //const currentFileHandler = await currentDirHandle.getFileHandle();
        let fileIterator = currentDirHandle.keys()
        console.log("fileIterator -> ", fileIterator)
        let hasNext = true
        while (hasNext) {
            let next = await fileIterator.next()
            hasNext = !next.done
            if (hasNext) {
                let fileName = next.value
                console.log("DIRECTORY fileName -> ", fileName)

                if (fileName.includes(".json")) {
                    // object
                    let currentFileHandler = await currentDirHandle.getFileHandle(fileName);
                    let file = await currentFileHandler.getFile()

                    try {
                        let content = JSON.parse(await file.text())
                        Object.keys(content).map(keyName => {
                            object[keyName] = content[keyName]
                        })
                    } catch (e) {
                        console.log("Exception on " + fileName + " : " + e)
                    }
                } else {
                    if (fileName.endsWith(".kt")) {
                        console.log(fileName + " is a kotlin script!")
                        let currentFileHandler = await currentDirHandle.getFileHandle(fileName);

                        let file = await currentFileHandler.getFile()
                        let content = await file.text()

                        let cleanFileName = fileName.substring(0, fileName.length - 3)

                        object[cleanFileName] = removeBoilerplateCode(content)
                        console.log("object[cleanFileName] - " + object[cleanFileName])
                        console.log("current object - " + JSON.stringify(object, null, 4))


                    } else {
                        if (fileName.endsWith(".groovy")) {
                            console.log(fileName + " is a groovy script!")
                            let currentFileHandler = await currentDirHandle.getFileHandle(fileName);

                            let file = await currentFileHandler.getFile()
                            let content = await file.text()
                            console.log("groovy script content: " + content)

                            let cleanFileName = fileName.substring(0, fileName.length - 7)
                            console.log("groovy script cleanFileName: " + cleanFileName)

                            object[cleanFileName] = removeBoilerplateCode(content)

                        } else {
                            // Directory
                            object[fileName] = await loadDirectory(await currentDirHandle.getDirectoryHandle(fileName))
                        }
                    }
                }
            }
        }
        console.log("current object - " + JSON.stringify(object, null, 4))
        return object
    }

    function checkForMissingKeys(beforeObject: any, afterObject: any) {
        let newObject = beforeObject
        Object.keys(beforeObject)?.map(key => {
            console.log("checkForMissingKeys : key -> ", key)
            console.log("checkForMissingKeys : beforeObject[key] -> ", beforeObject[key])
            console.log("checkForMissingKeys : typeof beforeObject[key] -> ", typeof beforeObject[key])

            if (beforeObject[key] && typeof beforeObject[key] === "object") {
                console.log("checkForMissingKeys rec: beforeObject[key] -> ", beforeObject[key])
                newObject[key] = checkForMissingKeys(beforeObject[key], afterObject[key])
            } else {
                // check if the new object doesn't have the old key in it
                if (!Object.keys(afterObject).includes(key)) {
                    newObject[key] = beforeObject[key]
                } else {
                    newObject[key] = afterObject[key]
                }
            }


        })
        return newObject

    }

    function formatArrays(object: any, path: string) {
        let newObject = {}
        let emptyList = true
        if (typeof object === "string" ||
            typeof object === "number" ||
            typeof object === "boolean" ||
            typeof object === "bigint") {
            return object
        }
        Object.keys(object).map(key => {
            emptyList = false
            console.log(key + " = " + typeof object[key])
            if (typeof object[key] === "object") {

                if (Object.keys(object[key])[0] == "0") {

                    console.log("LIST => " + key + "(" + object[key].toString() + ")")

                    let empty = true
                    let newArray = []


                    Object.keys(object[key]).map(subKey => {
                        empty = false
                        newArray.push(formatArrays(object[key][subKey], path + "." + key + "." + subKey))
                    })


                    if (empty) {
                        // check if it was null or empty before
                        let jp = require('jsonpath')
                        // let jsonPath = "$."
                        let previousValue = jp.value(JSON.parse(workingConfig.value), path)
                        console.log("PREV VALUE: jsonPath: ", path)
                        console.log("PREV VALUE: value: ", previousValue)
                        newObject[key] = previousValue
                    } else {
                        newObject[key] = newArray
                    }
                } else {

                    console.log("OBJECT => ", key)
                    newObject[key] = formatArrays(object[key], path + "." + key)
                }

            } else {
                console.log("PRIMITIVE => ", key)

                newObject[key] = object[key]
            }


        })
        if (emptyList) {
            // check if it was null or empty before
            let jp = require('jsonpath')
            // let jsonPath = "$."
            console.log("PREV VALUE: jsonPath: ", path)
            console.log("PREV VALUE: object: ", object)
            let previousValue = jp.value(JSON.parse(workingConfig.value), path)

            console.log("PREV VALUE: value: ", previousValue)
            return previousValue
        }
        return newObject
    }

    async function uploadConfigFromFolderStructure() {
        setIsLoadingSave(true);
        // @ts-ignore
        let currentDirHandle = await window.showDirectoryPicker();
        const dirName = context.companyId
        const companyDir = await currentDirHandle.getDirectoryHandle(dirName, {create: true});

        let uploadedConfig = await loadDirectory(companyDir)
        console.log("UPLOADED CONFIG => ", JSON.stringify(uploadedConfig, null, 4))

        let formattedConfig = checkForMissingKeys(
            JSON.parse(workingConfig.value),
            formatArrays(uploadedConfig, "$")
        )

        console.log("FORMATTED UPLOADED CONFIG => ", JSON.stringify(formattedConfig, null, 4))
        await updateConfig(JSON.stringify(formattedConfig, null, 4), false, false)

        await formatConfig()

        setUpdateSwitch(!updateSwitch);
        setIsLoadingSave(false);

    }

    function splitSubObjects(object: any) {
        let newObject = {}
        let noChildrenFound = true
        Object.keys(object).map(async name => {
            if (typeof object[name] != "object") {
                noChildrenFound = false
                newObject[name] = object[name]
            }
        })
        if (noChildrenFound) {
            return null
        }
        return newObject
    }

    function removeBoilerplateCode(code: string) {

        let startMarkerIndex = code.indexOf(boilerplateStartMarker) + boilerplateStartMarker.length
        let endMarkerIndex = code.indexOf(boilerplateEndMarker)
        let coreCode = code.substring(startMarkerIndex, endMarkerIndex)
        if (coreCode.startsWith("/*")) {
            coreCode = coreCode.substring(2, coreCode.length - 3)
        }

        let importStatements = code.split("\n")
            .filter(line => line.startsWith("import "))
            .filter(line => line !== "import testutils.test_data.defaultTransportOrder" && line !== "import transportorder.TransportOrderSpec")
            .join("\n")


        return importStatements + coreCode
    }

    function addBoilerplateCodeGroovy(code: string, path: String, params: any[]) {

        console.log("PATH: ", path)
        let pathArray = path.split(".")
        let name = pathArray.pop() + "_" + context.companyId
        let uniqueClassName = ""
        let cleanPath = ""
        pathArray.map((pathElem) => {
            if (pathElem != "") {
                console.log("PATH ELEM: " + pathElem + " MATCH: " + pathElem.match("[0-9]*"))

                if (pathElem.match("[0-9]*").toString() == pathElem) {
                    cleanPath = cleanPath + "." + "`" + pathElem + "`"
                } else {
                    cleanPath = cleanPath + "." + pathElem
                }
                uniqueClassName = uniqueClassName + "_" + pathElem
            }
        })

        let classParamDeclaration = ""
        let classParamInit = ""
        let importStatements = []

        Object.keys(params).forEach(key => {
            classParamInit = classParamInit + "                k." + key + " = " + key + "\n"
        })

        Object.keys(params).forEach(key => {
            if (params[key].split(".").length > 1) {
                let importLine = "import " + params[key]
                if (!importStatements.includes(importLine)) {
                    importStatements.push(importLine)
                }
            }
            let simpleClassName = params[key].split(".").pop()
            classParamDeclaration = classParamDeclaration + "    " + simpleClassName + " " + key + "\n"
        })

        // extract existing importStatements from code
        let codeLines = code.split("\n")
        let cleanCodeLines = []
        codeLines.map(line => {
            if (line.startsWith("import ")) {
                if (!importStatements.includes(line)) {
                    importStatements.push(line)
                }
            } else {
                cleanCodeLines.push(line)
            }
        })
        code = cleanCodeLines.join("\n")


        uniqueClassName = uniqueClassName.substring(1, uniqueClassName.length) + "_" + name
        let preScriptCode = "\n" +
            importStatements.join("\n") +
            "\n" +
            "class " + uniqueClassName + "_TestScript {\n" +
            "\n" +
            "    static void main(String [] args) {\n" +
            "            def order = null\n" +
            "            def k = new " + uniqueClassName + "_Class()\n" +
            "/*\n" +
            classParamInit +
            "*/\n" +
            "            k.run()\n" +
            "    }\n" +
            "}\n" +
            "\n" +
            "class " + uniqueClassName + "_Class {\n" +
            classParamDeclaration +
            "\n" +
            "   static run() {"

        let postScriptCode = "}\n" +
            "}"


        return preScriptCode + boilerplateStartMarker + "/*\n" + code + "\n*/" + boilerplateEndMarker + postScriptCode
    }

    function addBoilerplateCodeKotlin(code: string, path: String, params: any[]) {

        console.log("PATH: ", path)
        console.log("------------ params: ", params)
        let pathArray = path.split(".")
        let name = pathArray.pop()
        let uniqueClassName = ""
        let cleanPath = ""
        pathArray.map((pathElem) => {
            if (pathElem != "") {
                console.log("PATH ELEM: " + pathElem + " MATCH: " + pathElem.match("[0-9]*"))

                if (pathElem.match("[0-9]*").toString() == pathElem) {
                    cleanPath = cleanPath + "." + "`" + pathElem + "`"
                } else {
                    cleanPath = cleanPath + "." + pathElem
                }
                uniqueClassName = uniqueClassName + "_" + pathElem
            }
        })
        uniqueClassName = uniqueClassName.substring(1, uniqueClassName.length) + "_" + name + "_TestScript"

        let classParamDeclaration = ""
        let classParamInit = ""
        let importStatements = ["import testutils.test_data.defaultTransportOrder", "import transportorder.TransportOrderSpec"]

        Object.keys(params).forEach(key => {
            classParamInit = classParamInit + "                " + key + " = " + key + ",\n"
        })

        Object.keys(params).forEach(key => {
            if (params[key].split(".").length > 1) {
                let importLine = "import " + params[key]
                if (!importStatements.includes(importLine)) {
                    importStatements.push(importLine)
                }
            }
            let simpleClassName = params[key].split(".").pop()
            classParamDeclaration = classParamDeclaration + "    val " + key + ": " + simpleClassName + "?,\n"
        })

        // extract existing importStatements from code
        let codeLines = code.split("\n")
        let cleanCodeLines = []
        codeLines.map(line => {
            if (line.startsWith("import ")) {
                if (!importStatements.includes(line)) {
                    importStatements.push(line)
                }
            } else {
                cleanCodeLines.push(line)
            }
        })
        code = cleanCodeLines.join("\n")

        console.log("classParamInit: ", classParamInit)
        let preScriptCode = "package cm_local_config_scripts.`" + context.companyId + "`" + cleanPath + "\n" +
            "\n" +
            importStatements.join("\n") +
            "\n" +
            "class " + uniqueClassName + " : TransportOrderSpec() {\n" +
            "    init {\n" +
            "       \"" + uniqueClassName + "\" {\n" +
            "            val order = defaultTransportOrder()\n" +
            "/*\n" +
            "            val k = " + name + "_Class(\n" +
            classParamInit +
            "            )\n" +
            "            k.run()\n" +
            "*/\n" +
            "       }\n" +
            "    }\n" +
            "}\n" +
            "\n" +
            "class " + name + "_Class(\n" +
            classParamDeclaration +
            ") {\n" +
            "    fun run() {"

        let postScriptCode = "    }\n" +
            "}"


        return preScriptCode + boilerplateStartMarker + "/*\n" + code + "\n*/" + boilerplateEndMarker + postScriptCode
    }

    function getScriptInfo(fullPath: string) {
        let regex = /\.[0-9]+/ig
        return Object.keys(context.scriptInfo).find(i => {

            let normalizedPath = (fullPath).replace(regex, ".*")
            normalizedPath = normalizedPath.substring(1, normalizedPath.length) // deleting leading dot
            return i == normalizedPath
        })
    }

    function splitScripts(object: any, path: string) {
        let newObject = {}
        console.log("FULL PATH: " + path + " OBJECT -> ", object)

        Object.keys(object).map(async name => {

            let fullPath = path + "." + name
            console.log("FULL PATH: ", fullPath)
            let foundScriptInfoKey = getScriptInfo(path + "." + name)
            if (foundScriptInfoKey == null) {

                newObject[name] = object[name]
            } else {
                console.log("FULL PATH: " + fullPath + " - FOUND!!!!!")
            }

        })
        console.log("FULL PATH: " + path + " NEW OBJECT -> ", newObject)

        return newObject
    }

    async function writeFile(fileHandle, contents) {
        // Create a FileSystemWritableFileStream to write to.
        const writable = await fileHandle.createWritable();

        // Write the contents of the file to the stream.
        await writable.write(contents);

        // Close the file and write the contents to disk.
        await writable.close();
    }

    async function downloadConfigAsFolderStructure() {
        setIsLoadingSave(true);
        // @ts-ignore
        let currentDirHandle = await window.showDirectoryPicker();
        //console.log("currentDirHandle: ", currentDirHandle)

        const dirName = context.companyId

        const companyDir = await currentDirHandle.getDirectoryHandle(dirName, {create: true});

        let config = JSON.parse(workingConfig.value)
        let filteredObject = splitScripts(splitSubObjects(config), "")
        if (filteredObject !== null) {
            //console.log("TOP LAYER filteredObject = ", filteredObject)
            let currentFileHandle = await companyDir.getFileHandle(dirName + ".json", {create: true})
            await writeFile(currentFileHandle, JSON.stringify(filteredObject, null, 4))
        }
        createSubFolder(config, companyDir, "")

        setIsLoadingSave(true);
    }

    function createSubFolder(object: any, currentDirHandle: any, path: string) {


        Object.keys(object).map(async name => {


            if (object[name] != null) {
                if (typeof object[name] == "object") {
                    //console.log("##### " + name + " -> " + typeof object[name])
                    const currentDir = await currentDirHandle.getDirectoryHandle(name, {create: true});

                    let filteredObject = splitSubObjects(object[name])
                    let filteredObjectWithoutScripts = splitSubObjects(splitScripts(object[name], path + "." + name))
                    if (filteredObjectWithoutScripts !== null) {
                        let currentFileHandle = await currentDir.getFileHandle(name + ".json", {create: true})
                        await writeFile(currentFileHandle, JSON.stringify(filteredObjectWithoutScripts, null, 4))
                    }

                    if (filteredObject !== null) {
                        Object.keys(filteredObject).map(async fieldName => {
                            let foundScriptInfoKey = getScriptInfo(path + "." + name + "." + fieldName)

                            if (foundScriptInfoKey != null) {
                                console.log("foundScriptInfo: ", foundScriptInfoKey)
                                //console.log("Object.values: ", Object.values(context.scriptInfo))
                                let scriptInfo = context.scriptInfo[foundScriptInfoKey]
                                console.log("scriptInfo: ", scriptInfo)

                                let fullPath = path + "." + name + "." + fieldName

                                if (scriptInfo.isKotlinScript) {
                                    // kotlin
                                    let currentFileHandle = await currentDir.getFileHandle(fieldName + ".kt", {create: true})
                                    let contents = addBoilerplateCodeKotlin(filteredObject[fieldName], fullPath, scriptInfo.parameter)
                                    await writeFile(currentFileHandle, contents)
                                }

                                if (scriptInfo.isGroovyScript) {
                                    // Groovy
                                    let currentFileHandle = await currentDir.getFileHandle(fieldName + ".groovy", {create: true})
                                    let contents = addBoilerplateCodeGroovy(filteredObject[fieldName], fullPath, scriptInfo.parameter)
                                    await writeFile(currentFileHandle, contents)
                                }

                            }

                        })
                    }
                    createSubFolder(object[name], currentDir, path + "." + name)
                }
            }

        })
    }


    return <>

        <IfBox shouldShow={authentication.isSuperUser && props.configOutOfSync}>
            <Menu.Item fitted>
                <Popup
                    content={'The current config differs from the one in the DB. Meanwhile the config has been changed by someone else.'}
                    trigger={
                        <Button color={"yellow"} icon
                                onClick={() => setShowConflictModal(true)}>
                            <Icon name={'unlink'} color={'red'} size={'large'}/>
                        </Button>
                    }/>
            </Menu.Item>
        </IfBox>

        <ButtonGroup size='small' floated='right'>
            <IfBox shouldShow={!validJson}>
                <Label size={'large'} color='red'>INVALID JSON</Label>
            </IfBox>

            <Popup content='Saves the Config' trigger={
                <Button id='SaveConfigButtonTree' disabled={isSaveEnabled()} icon
                        onClick={() => handleSave()} loading={isLoadingSave}
                ><Icon name={'save'} size={'large'}/></Button>
            }/>
            <Popup content='Saves config as draft' trigger={
                <Button id='Save config as draft' disabled={isSaveEnabled()} icon
                        onClick={() => handleSaveAsDraft()} loading={isLoadingSaveAsDraft}
                ><Icon name={'beer'} size={'large'}/></Button>
            }/>
            <Popup content='Discards the Config' trigger={
                <Button id='DiscardConfigButtonTree' disabled={!workingConfig.value} icon
                        onClick={() => setShowDiscardModal(true)}
                ><Icon name={'undo'} size={'large'}/></Button>
            }/>
            <Popup content='Formats the Config' trigger={
                <Button id='FormatConfigButtonTree' disabled={!workingConfig.value} icon
                        onClick={() => formatConfig()}
                ><Icon name={'band aid'} size={'large'}/></Button>
            }/>


            <Button style={{padding: "0", width: "25px"}}>
                <Dropdown style={{width: "100%", height: "100%", padding: "12px 0"}}>
                    <DropdownMenu>
                        <Popup content='Shows default Lis-Config' trigger={
                            <DropdownItem icon='list' id='ShowDefaultLisConfigButtonRaw'
                                          onClick={() => setShowDefaultLisConfig(true)}/>
                        }/>
                        <Popup content='Extract to specific config' trigger={
                            <DropdownItem icon='sign-out' id='ExtractToSpecificConfigButton'
                                          onClick={() => setExtractToSpecificConfig(true)}/>
                        }/>
                        <DropdownItem icon={'retweet'} onClick={() => setShowUpAndDownloadModal(true)}/>

                        <Popup content='Validates the Config' trigger={
                            <DropdownItem id='ValidateConfigButtonTree' icon={'clipboard check'}
                                          onClick={() => handleValidate()}
                            />
                        }/>
                    </DropdownMenu>
                </Dropdown>
            </Button>
        </ButtonGroup>

        <DiscardModal
            isOpen={showDiscardModal}
            onClose={(discard) => handleDiscardClosed(discard)}
            dispatch={() => {
            }}
        />


        <IfBox shouldShow={showUpAndDownloadModal}>
            <UpAndDownloadModal
                isOpen={showUpAndDownloadModal}
                onClose={async (upload, download) => {
                    setShowUpAndDownloadModal(false);
                    if (download) {
                        await downloadConfigAsFolderStructure()
                    } else {
                        if (upload) {
                            await uploadConfigFromFolderStructure()
                        }
                    }
                }}
            />
        </IfBox>

        <IfBox shouldShow={testConfig}>
            <TestConfigModal
                isOpen={testConfig}
                onClose={() => {
                    setTestConfig(false)
                    setReloadPage(false)
                }}
                validationResponse={verifyResponse}
                reloadPage={reloadPage}
            />
        </IfBox>
        <IfBox shouldShow={showChanges}>
            <ChangeConfigModal
                isOpen={showChanges}
                onClose={() => {
                    setShowChanges(false)
                }}
                oldConfig={JSON.stringify(currentCompanyConfig, null, 4)}
                newConfig={workingConfig.value}
                dispatch={dispatchFunction}
                failedTests={failedTests}
                validationWarnings={verifyResponse?.warnings}
            />
        </IfBox>
        <IfBox shouldShow={showSaveAsDraft}>
            <SaveAsDraftModal
                isOpen={showSaveAsDraft}
                onClose={() => {
                    setShowSaveAsDraft(false)
                    setIsLoadingSaveAsDraft(false)
                    rerender()
                }}
                oldConfig={JSON.stringify(currentCompanyConfig, null, 4)}
                draft={workingConfig.value}
                dispatch={() => {
                }}
            />
        </IfBox>
        <IfBox shouldShow={showDefaultLisConfig}>
            <ShowDefaultLisConfigModal
                isOpen={showDefaultLisConfig}
                onClose={() => setShowDefaultLisConfig(false)}
            />
        </IfBox>
        <IfBox shouldShow={extractToSpecificConfig}>
            <ExtractToSpecificConfigModal
                isOpen={extractToSpecificConfig}
                onClose={() => setExtractToSpecificConfig(false)}/>
        </IfBox>
        <IfBox shouldShow={showConflictModal}>
            <ConflictModal
                isOpen={showConflictModal}
                onClose={() => setShowConflictModal(false)}
                saveAsDraft={() => setShowSaveAsDraft(true)}
                discardConfig={() => setShowDiscardModal(true)}
                formatConfig={() => formatConfig()}
            />
        </IfBox>


        <Notifications/>
    </>
}