import * as React from "react";
import {CSSProperties, useState} from "react";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {Button, Icon, Loader, Table} from "semantic-ui-react";
import {Pipeline, PipelineTestDetail, PipelineTestDetailsByCommit} from "./PipelineStatusModel";
import {formatDate} from "../../../../../format";
import AceEditor from "react-ace";
import {Splitter} from "../../../../util/table-splitter-ui/src/components/Splitters/Splitter";

export function PipelineStatusPage() {

    const [groupedByCommit, setGroupedByCommit] = React.useState<PipelineTestDetailsByCommit[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [expandedRows, setExpandedRows] = useState<any[]>([])

    const [displayText, setDisplayText] = React.useState("")
    const [displayObject, setDisplayObject] = React.useState<any>("")

    const [selectedCommit, setSelectedCommit] = React.useState<number | null>(null)
    const [selectedTest, setSelectedTest] = React.useState<string | null>(null)
    const [selectedType, setSelectedType] = React.useState<"Commit" | "Test" | null>(null)
    const [stackTraceMode, setStackTraceMode] = React.useState(false)

    const [latestFinishedPipeline, setLatestFinishedPipeline] = React.useState<Pipeline>(null)

    React.useEffect(() => {
        callPipelineTestDetails().then(r => {
        });
    }, []);

    React.useEffect(() => {
        setStackTraceMode(false)
    }, [displayObject]);

    async function callPipelineTestDetails() {
        let auth = backend.withTokenAuthHeader(authentication.token)
        let response = await fetch('/internalMonitoring/getPipelineTestDetails', {
            method: 'GET',
            headers: {
                "Authorization": auth.headers.Authorization.toString(),
                "Content-Type": "application/json; charset=utf-8"
            }
        })

        if (response.status == 200) {
            let tests = await response.json();
            console.log("[PipelineStatusPage:callPipelineTestDetails] tests: ", tests)
            let grouped = groupByCommit(tests.failedTestsWithCausingCommits);
            console.log("[PipelineStatusPage:callPipelineTestDetails] grouped: ", grouped)

            setGroupedByCommit(grouped)
            setLatestFinishedPipeline(tests.currentPipeline)
        }

        setLoading(false)

    }

    function cleanTestSuiteName(testSuiteName: string) {
        return testSuiteName.replace("test:", "");
    }

    function groupByCommit(tests: PipelineTestDetail[]): PipelineTestDetailsByCommit[] {

        let grouped: PipelineTestDetailsByCommit[] = []

        tests.forEach((test: PipelineTestDetail) => {

            let found = grouped.find(group =>
                group.causingCommitId === test.causingCommit?.id
            );
            if (!found) {

                let newGroup = {
                    causingCommitId: test.causingCommit?.id,
                    causingCommit: test.causingCommit,
                    tests: [test]
                } as PipelineTestDetailsByCommit
                grouped.push(newGroup)
            } else {
                found.tests.push(test)
            }
        })

        console.log("[PipelineStatusPage:groupByCommit] grouped: ", grouped)
        return grouped;


    }

    function handleRowClick(rowId) {

        setLoading(true)
        const isRowCurrentlyExpanded = expandedRows.includes(rowId);

        const newExpandedRows = isRowCurrentlyExpanded
            ? expandedRows.filter(id => id !== rowId)
            : expandedRows.concat(rowId);

        setExpandedRows(newExpandedRows);
        setDisplayObject(groupedByCommit[rowId])
        setDisplayText(JSON.stringify(groupedByCommit[rowId], null, 4))
        setSelectedCommit(rowId)
        setSelectedType("Commit")
        setLoading(false)
    }

    enum CellStyleCategory {
        HEADER,
        SUBHEADER,
        NORMAL,
        SUBNORMAL
    }

    function getStyle(category: CellStyleCategory) {
        switch (category) {
            case CellStyleCategory.HEADER: {
                return {fontWeight: "bold", color: "black", fontSize: 18} as CSSProperties
            }
            case CellStyleCategory.SUBHEADER: {
                return {fontWeight: "bold", color: "black"} as CSSProperties
            }
            case CellStyleCategory.NORMAL: {
                return {color: "black", fontSize: 14} as CSSProperties
            }
            case CellStyleCategory.SUBNORMAL: {
                return {color: "black"} as CSSProperties
            }
        }
    }

    function cell(category: CellStyleCategory, content: any) {
        return <Table.Cell><a style={getStyle(category)}>{content}</a></Table.Cell>
    }

    function tableView() {

        let allItemRows = []
        let selectedRowStyle = {backgroundColor: "rgba(0,128,128,0.2)"} as CSSProperties
        groupedByCommit.forEach((d, index) => {
            const clickCallback = () => handleRowClick(index);
            let date = "unknown"
            if (d.causingCommit != null) {
                date = formatDate(new Date(d.causingCommit.committed_date))
            }
            const itemRows = [
                <Table.Row
                    onClick={clickCallback}
                    key={"row-data-" + index}
                    style={selectedCommit === index ? selectedRowStyle : {}}
                >
                    {cell(CellStyleCategory.NORMAL, d.tests.length)}
                    {cell(CellStyleCategory.NORMAL, d.causingCommit?.author_name)}
                    {cell(CellStyleCategory.NORMAL, date)}
                    {cell(CellStyleCategory.NORMAL, d.causingCommit?.last_pipeline?.id)}
                    {cell(CellStyleCategory.NORMAL, d.causingCommit?.message)}
                </Table.Row>
            ];
            if (expandedRows.includes(index)) {
                itemRows.push(
                    <Table.Row key={"row-expanded-header-" + index}>
                        {cell(CellStyleCategory.SUBHEADER, "")}
                        {cell(CellStyleCategory.SUBHEADER, "Module")}
                        {cell(CellStyleCategory.SUBHEADER, "execution time")}
                        {cell(CellStyleCategory.SUBHEADER, "class name")}
                        {cell(CellStyleCategory.SUBHEADER, "Test Name")}
                    </Table.Row>
                )
                d.tests.forEach(test => {
                    itemRows.push(
                        <Table.Row
                            key={"row-expanded-" + index + "_" + test.testName}
                            style={selectedTest === test.testName ? selectedRowStyle : {}}
                            onClick={() => {
                                setDisplayObject(test.testCase)
                                setDisplayText(JSON.stringify(test.testCase, null, 4))
                                setSelectedTest(test.testName)
                                setSelectedType("Test")
                            }}
                        >
                            {cell(CellStyleCategory.SUBNORMAL, "")}
                            {cell(CellStyleCategory.SUBNORMAL, cleanTestSuiteName(test.testSuite.name))}
                            {cell(CellStyleCategory.SUBNORMAL, test.testCase.execution_time)}
                            {cell(CellStyleCategory.SUBNORMAL, test.testCase.classname)}
                            {cell(CellStyleCategory.SUBNORMAL, test.testName)}
                        </Table.Row>
                    )
                })
            }
            allItemRows.push(itemRows)
        })
        return <Table selectable striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell><a style={getStyle(CellStyleCategory.HEADER)}> failing
                        tests</a></Table.HeaderCell>
                    <Table.HeaderCell><a style={getStyle(CellStyleCategory.HEADER)}> commited by</a></Table.HeaderCell>
                    <Table.HeaderCell><a style={getStyle(CellStyleCategory.HEADER)}> commited at</a></Table.HeaderCell>
                    <Table.HeaderCell><a style={getStyle(CellStyleCategory.HEADER)}> Pipeline</a></Table.HeaderCell>
                    <Table.HeaderCell><a style={getStyle(CellStyleCategory.HEADER)}> message</a></Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>{allItemRows}</Table.Body>
        </Table>

    }

    function gitlabButton(url: string) {
        return <Button
            width={50}
            color={"orange"}
            onClick={() => window.open(url, "_blank")}
            icon={"gitlab"}
        />
    }

    function buildCommitButtons() {
        return <div style={{
            display: "flex",
            flexDirection: "row",
        }}>
            <h1>Selected Commit:</h1>
            {gitlabButton(displayObject.causingCommit.web_url)}
        </div>
    }

    function buildTestButtons() {
        return <div style={{
            display: "flex",
            flexDirection: "row",
        }}>
            <h1>Selected Test:</h1>
            {!stackTraceMode && <Button
                color={"red"}
                icon={"error"}
                onClick={() => {
                    setDisplayText(displayObject.system_output)
                    setStackTraceMode(true)}
                }
                >Show Stack-Trace
            </Button>}
            {stackTraceMode && <Button
                color={"green"}
                icon={"check"}
                onClick={() => {
                    setDisplayText(JSON.stringify(displayObject, null, 4))
                    setStackTraceMode(false)
                }
                }>Show complete Object
            </Button>
            }

        </div>
    }

    function displayTextArea() {

        return <>
            {selectedType === "Commit" && buildCommitButtons()}
            {selectedType === "Test" && buildTestButtons()}
            <AceEditor
                theme={"monokai"}
                style={{flex: 1}}
                mode={"json"}
                value={displayText}
                width={"100%"}
            />
        </>
    }

    return <>
        <h1>Failing Pipeline Tests: {latestFinishedPipeline &&
            <a href={latestFinishedPipeline?.webUrl}><Icon name={"gitlab"} />{latestFinishedPipeline?.id}</a>}
        </h1>

        <Loader active={loading}/>
        <Splitter
            name={"PipelineStatusPage"}
            left={tableView()}
            right={displayTextArea()}
        />
    </>
}