import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import {Accordion, Icon, Label} from "semantic-ui-react";
import {customerReport, fetchCustomerReport} from "../../../../style/page_with_sidebar";
import {ConfigContext} from "../../../context/ConfigContext";
import {CustomerReportApiGatewayStatus} from "./sections/CustomerReportApiGatewayStatus";
import {CustomerReportSftpStatus} from "./sections/CustomerReportSftpStatus";
import {CustomerReportFileHealthStatus} from "./sections/CustomerReportFileHealthStatus";
import {CustomerReportQueueStatus} from "./sections/CustomerReportQueueStatus";
import {CustomerReportStatusJsonReport} from "./sections/CustomerReportStatusJsonReport";
import {
    CustomerReportNeedsVerificationFilesCountStatus
} from "./sections/CustomerReportNeedsVerificationFileCountStatus";
import {CustomerReportEtaCalculatorStatus} from "./sections/CustomerReportEtaCalculatorStatus";
import {CustomerReportErrorFilesCountStatus} from "./sections/CustomerReportErrorFileCountStatus";
import {CustomerReportMessageLogReport} from "./sections/CustomerReportMessageLogReport";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";

export interface CustomerReportPageProps {

}

export function CustomerReportPage(props: CustomerReportPageProps) {
    const context = useContext(ConfigContext);
    const history = useHistory();

    const reFetchReportData = async (): Promise<void> => {
        console.log("reFetchReportData function called"); // Log when the function is called

        setIsLoading(true);
        console.log("Set loading to true"); // Log when loading state is set to true

        try {
            await fetchCustomerReport(context,true);
            console.log("fetchCustomerReport successful", ); // Log after fetching the report
        } catch (error) {
            console.error("Error fetching customer report:", error); // Log any error that occurs during fetch
        }

        setIsLoading(false);
        console.log("Set loading to false"); // Log when loading state is set to false
    };

    const [isLoading, setIsLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState([]);

    useEffect(() => {
        // when the url has a report specified, we want to open the corresponding accordion
        const report = getUrlReport();
        console.log("[CustomerReportPage] report from url", report)
        if (report) {
            let list = report.split(",").map(Number);
            setActiveIndex(list);
        }
    }, []);

    useEffect(() => {
        if (customerReport.value == null) {
            fetchCustomerReport(context).then(() => {
                setIsLoading(false);
            })
        } else {
            setIsLoading(false);
        }
    }, [customerReport.value]);

    const filterNonIgnoredCompanies = (reportCompanies: any[]) => {
        return reportCompanies?.filter((company: any) => !company.shouldBeIgnored);
    };

    // URL handling
    function setUrlReport(reportIndex: string | number) {
        history.push({
            pathname: history.location.pathname,
            search: `?tab=2&report=${reportIndex}`
        });
    }

    function getUrlReport() {
        const url = new URL(window.location.href);
        return url.searchParams.get("report");
    }

    function getColorForStatusJsonReportSummary(amount: number | undefined) {
        if (amount === undefined) {
            return "grey"
        }
        if (amount > 0) {
            if (amount > 5) {
                return "red"
            } else {
                return "orange"
            }
        } else {
            return "green"
        }

    }

    function drawStatusJsonReportSummary() {
        const report = customerReport.value?.statusJsonReport;
        if (!report) return null;

        const nonIgnoredCompanies = filterNonIgnoredCompanies(report.companies);
        const ignoredCompaniesCount = report.companies.length - nonIgnoredCompanies.length;

        return (
            <>
                <Label color={getColorForStatusJsonReportSummary(nonIgnoredCompanies.filter(c => !c.healthy).length)}>
                    Unhealthy companies: {nonIgnoredCompanies.filter(c => !c.healthy).length ?? "n/a"}
                </Label>

                <Label>Total companies checked (including ignored): {report.companies.length}</Label>

                <Label>
                    Ignored companies: {ignoredCompaniesCount}
                </Label>

                <Label color={getColorForStatusJsonReportSummary(nonIgnoredCompanies.filter(c => c.statusJsonExists === false).length)}>
                    Companies missing statusJsonFile: {nonIgnoredCompanies?.filter(c => c.statusJsonExists === false).length ?? "n/a"}
                </Label>
            </>
        );
    }
    function drawNeedVerificationFileCountReportSummary() {
        let report = customerReport.value?.needsVerificationFilesCount;
        if (!report) return null;

        const nonIgnoredCompanies = filterNonIgnoredCompanies(report.companies);
        const ignoredCompaniesCount = report.companies.length - nonIgnoredCompanies.length;

        const healthStatus = report?.healthy?.toString();
        const filesOlderThanFiveMinutes = nonIgnoredCompanies.filter(company => company.filesOlderThanFiveMinutes > 0).length;

        return (
            <>
                <Label color={getColorForStatusJsonReportSummary(filesOlderThanFiveMinutes)}>
                    Healthy: {healthStatus?.charAt(0).toUpperCase() + healthStatus?.slice(1)}
                </Label>

                <Label>Total companies checked (including ignored): {report?.companies.length}</Label>

                <Label>Ignored companies: {ignoredCompaniesCount}</Label>

                <Label
                    color={getColorForStatusJsonReportSummary(filesOlderThanFiveMinutes)}>
                    Files older than five minutes (non-ignored): {filesOlderThanFiveMinutes ?? "n/a"}
                </Label>
            </>
        );
    }


    function drawErrorFileCountReportSummary() {
        let report = customerReport.value?.errorFilesCount;
        if (!report) return null;

        const nonIgnoredCompanies = filterNonIgnoredCompanies(report.companies);
        const ignoredCompaniesCount = report.companies.length - nonIgnoredCompanies.length;

        const healthStatus = report?.healthy?.toString();
        const filesOlderThanFiveMinutes = nonIgnoredCompanies.filter(company => company.filesOlderThanFiveMinutes > 0).length;

        return (
            <>
                <Label color={getColorForStatusJsonReportSummary(filesOlderThanFiveMinutes)}>
                    Healthy: {healthStatus?.charAt(0).toUpperCase() + healthStatus?.slice(1)}
                </Label>

                <Label>Total companies checked (including ignored): {report?.companies.length}</Label>

                <Label>Ignored companies: {ignoredCompaniesCount}</Label>

                <Label
                    color={getColorForStatusJsonReportSummary(filesOlderThanFiveMinutes)}>
                    Files older than five minutes (non-ignored): {filesOlderThanFiveMinutes ?? "n/a"}
                </Label>
            </>
        );
    }


    function drawSftpStatusReportSummary() {
        let report = customerReport.value?.fileSystemWorkerStatus?.sftpSyncResults ?? [];
        if (!report) return null;

        const nonIgnoredCompanies = filterNonIgnoredCompanies(report);
        const ignoredCompaniesCount = report.length - nonIgnoredCompanies.length;

        const icingaNotSuccess = nonIgnoredCompanies.filter(company => company.icingaState !== 'SUCCESS').length;
        const statusNotSuccess = nonIgnoredCompanies.filter(company => company.status !== 'SUCCESS').length;

        return (
            <>
                <Label color={getColorForStatusJsonReportSummary(icingaNotSuccess + statusNotSuccess)}>
                    Healthy: {(icingaNotSuccess === 0 && statusNotSuccess === 0) ? "True" : "False"}
                </Label>

                <Label>Total companies checked (including ignored): {report.length}</Label>

                <Label>Ignored companies: {ignoredCompaniesCount}</Label>

                <Label color={getColorForStatusJsonReportSummary(icingaNotSuccess)}>
                    Failed Icinga instances: {icingaNotSuccess ?? "n/a"}
                </Label>

                <Label color={getColorForStatusJsonReportSummary(statusNotSuccess)}>
                    Failed Status instances: {statusNotSuccess ?? "n/a"}
                </Label>
            </>
        );
    }


    function drawFileStatusReportSummary() {
        let report = customerReport.value?.fileSystemWorkerStatus?.customerOldFiles ?? [];
        if (!report) return null;

        const nonIgnoredCompanies = filterNonIgnoredCompanies(report);
        const ignoredCompaniesCount = report.length - nonIgnoredCompanies.length;

        const totalCompanies = report.length;
        const oldFileAmount = nonIgnoredCompanies.filter(company => company?.oldFileAmount > 0).length;
        const healthyStatus = nonIgnoredCompanies.filter(company => company?.healthy !== true).length;

        return (
            <>
                <Label
                    color={getColorForStatusJsonReportSummary(healthyStatus)}>
                    Healthy: {(healthyStatus > 0) ? "False" : "True"}
                </Label>

                <Label>Total companies checked (including ignored): {totalCompanies}</Label>

                <Label>Ignored companies: {ignoredCompaniesCount}</Label>

                <Label
                    color={getColorForStatusJsonReportSummary(oldFileAmount)}>
                    Old File Amount: {oldFileAmount ?? "n/a"}
                </Label>
            </>
        );
    }


    function deepCopy(obj: any): any {
        return JSON.parse(JSON.stringify(obj));
    }

    function handleAccordionClick(e: any, titleProps: { index: any; }) {
        console.log("handleAccordionClick", titleProps)
        const {index} = titleProps
        let newIndex = deepCopy(activeIndex)
        if (newIndex.includes(index)) {
            newIndex = activeIndex.filter(i => i != index)
        } else {
            newIndex.push(index)
        }

        setUrlReport(newIndex)
        setActiveIndex(newIndex)
    }

    function addAccordionSection(title: string, index: number, content: any) {
        let shortReport: JSX.Element;
        switch (index) {
            // todo add these summaries
            // case 0:
            //     shortReport = drawApiGatewayStatusReportSummary()
            //     break;
            // case 3:
            //     shortReport = drawQueueStatusReportSummary()
            //     break;
            // case 7:
            //     shortReport = drawEtaCalculatorStatusReportSummary()
            //     break;
            // case 8:
            //     shortReport = drawMessageLogReportSummary()
            //     break;
            case 1:
                shortReport = drawSftpStatusReportSummary()
                break;
            case 2:
                shortReport = drawFileStatusReportSummary()
                break;
            case 4:
                shortReport = drawStatusJsonReportSummary()
                break;
            case 5:
                shortReport = drawNeedVerificationFileCountReportSummary()
                break;
            case 6:
                shortReport = drawErrorFileCountReportSummary()
                break;
        }
        return (
            <>
                <Accordion.Title
                    active={activeIndex.includes(index)}
                    index={index}
                    onClick={handleAccordionClick}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }} // Add flex layout to space items
                >
                <span>
                    <Icon name='dropdown'/>
                    {title}
                </span>
                    {shortReport && (
                        <div style={{marginLeft: 'auto'}}> {/* Add margin to the left to push content to the right */}
                            {shortReport}
                        </div>
                    )}
                </Accordion.Title>
                <Accordion.Content active={activeIndex.includes(index)}>
                    {content}
                </Accordion.Content>
            </>
        );
    }

    if (isLoading) {
        return <div>Loading customer report...</div>
    } else {
        console.log("CustomerReportPage", customerReport.value)
        return <Accordion styled fluid>
            {addAccordionSection("ApiGateway Status", 0, <CustomerReportApiGatewayStatus reFetchReportData={reFetchReportData}/>)}
            {addAccordionSection("Sftp Status", 1, <CustomerReportSftpStatus reFetchReportData={reFetchReportData}/>)}
            {addAccordionSection("File Status", 2, <CustomerReportFileHealthStatus reFetchReportData={reFetchReportData}/>)}
            {addAccordionSection("Queue Status", 3, <CustomerReportQueueStatus reFetchReportData={reFetchReportData}/>)}
            {addAccordionSection("Status.json Report", 4, <CustomerReportStatusJsonReport reFetchReportData={reFetchReportData}/>)}
            {addAccordionSection("Needs Verification Files Count", 5,
                <CustomerReportNeedsVerificationFilesCountStatus reFetchReportData={reFetchReportData}/>)}
            {addAccordionSection("Error Files Count", 6, <CustomerReportErrorFilesCountStatus reFetchReportData={reFetchReportData}/>)}
            {addAccordionSection("Eta Calculator", 7, <CustomerReportEtaCalculatorStatus reFetchReportData={reFetchReportData}/>)}
            {addAccordionSection("MessageLog Report", 8, <CustomerReportMessageLogReport reFetchReportData={reFetchReportData}/>)}
        </Accordion>
    }


}