import * as React from "react";
import {useContext} from "react";
import {CustomerReportDynamicSection, statusHighlighter} from "../CustomerReportDynamicSection";
import {ConfigContext} from "../../../../context/ConfigContext";

export function CustomerReportApiGatewayStatus() {

    const context = useContext(ConfigContext)

    const columns = [{
        id: 'companyName',
        Header: 'companyName',
        width: 300,
        accessor: (d: any) => d.companyName,
    }, {
        id: 'icon',
        Header: 'api Status',
        width: 100,
        accessor: (d: any) => <div>{statusHighlighter(d.status)} {d.status}</div>,
    },{
        id: 'responsibleManager',
        Header: 'Responsible manager',
        width: 100,
        accessor: (d: any) => d.responsibleManager,
    },]
    return <CustomerReportDynamicSection columns={columns} data={context.customerReport?.apiGatewayStatus?.companyApiStatus}/>

}